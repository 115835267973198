// GET_CATEGORY_MAIN_LIST
export const GET_CATEGORY_MAIN_LIST_REQUEST = 'GET_CATEGORY_MAIN_LIST_REQUEST';
export const GET_CATEGORY_MAIN_LIST_SUCCESS = 'GET_CATEGORY_MAIN_LIST_SUCCESS';
export const GET_CATEGORY_MAIN_LIST_FAILURE = 'GET_CATEGORY_MAIN_LIST_FAILURE';

// TOGGLE_THEME
export const TOGGLE_THEME = 'TOGGLE_THEME';
export const TOGGLE_THEME_SUCCESS = 'TOGGLE_THEME_SUCCESS';
export const TOGGLE_THEME_FAILURE = 'TOGGLE_THEME_FAILURE';

// OPEN_MODAL
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

// GET_HOME_SLIDER
export const GET_POST_CATEGRY_REQUEST = 'GET_POST_CATEGRY_REQUEST';
export const GET_POST_CATEGRY_SUCCESS = 'GET_POST_CATEGRY_SUCCESS';
export const GET_POST_CATEGRY_FAILURE = 'GET_POST_CATEGRY_FAILURE';

//Guest ID
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';

//Guest ID
export const GUEST_ID = 'GUEST_ID';

// Logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


// SIGN_IN
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
// export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';

// MODERATOR_SIGN_UP
export const MODERATOR_SIGN_UP_REQUEST = 'MODERATOR_SIGN_UP_REQUEST';
export const MODERATOR_SIGN_UP_SUCCESS = 'MODERATOR_SIGN_UP_SUCCESS';
export const MODERATOR_SIGN_UP_FAILURE = 'MODERATOR_SIGN_UP_FAILURE';

// SIGN_UP
export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

// GET_USER_PROFILE
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

// GET_USER_PROFILE
export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';


// GET_FROFILE_UPDATE
export const GET_FROFILE_UPDATE_REQUEST = 'GET_FROFILE_UPDATE_REQUEST';
export const GET_FROFILE_UPDATE_SUCCESS = 'GET_FROFILE_UPDATE_SUCCESS';
export const GET_FROFILE_UPDATE_FAILURE = 'GET_FROFILE_UPDATE_FAILURE';

// GET_INTREST
export const GET_INTREST_REQUEST = 'GET_INTREST_REQUEST';
export const GET_INTREST_SUCCESS = 'GET_INTREST_SUCCESS';
export const GET_INTREST_FAILURE = 'GET_INTREST_FAILURE';

// GET_INTREST_PASS
export const GET_INTREST_ADD_REQUEST = 'GET_INTREST_ADD_REQUEST';
export const GET_INTREST_ADD_SUCCESS = 'GET_INTREST_ADD_SUCCESS';
export const GET_INTREST_ADD_FAILURE = 'GET_INTREST_ADD_FAILURE';

// GET_TEG_LIST
export const GET_TEG_LIST_REQUEST = 'GET_TEG_LIST_REQUEST';
export const GET_TEG_LIST_SUCCESS = 'GET_TEG_LIST_SUCCESS';
export const GET_TEG_LIST_FAILURE = 'GET_TEG_LIST_FAILURE';

// GET_SUB_CATEGRORY
export const GET_SUB_CATEGRORY_REQUEST = 'GET_SUB_CATEGRORY_REQUEST';
export const GET_SUB_CATEGRORY_SUCCESS = 'GET_SUB_CATEGRORY_SUCCESS';
export const GET_SUB_CATEGRORY_FAILURE = 'GET_SUB_CATEGRORY_FAILURE';

// GET_LIBRARY
export const GET_LIBRARY_REQUEST = 'GET_LIBRARY_REQUEST';
export const GET_LIBRARY_SUCCESS = 'GET_LIBRARY_SUCCESS';
export const GET_LIBRARY_FAILURE = 'GET_LIBRARY_FAILURE';

// GET_BLOG_LIST
export const GET_BLOG_LIST_REQUEST = 'GET_BLOG_LIST_REQUEST';
export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAILURE = 'GET_BLOG_LIST_FAILURE';

// GET_BLOG_DETAILS
export const GET_BLOG_DETAILS_REQUEST = 'GET_BLOG_DETAILS_REQUEST';
export const GET_BLOG_DETAILS_SUCCESS = 'GET_BLOG_DETAILS_SUCCESS';
export const GET_BLOG_DETAILS_FAILURE = 'GET_BLOG_DETAILS_FAILURE';

// GET_RECENTLY_LINKED
export const GET_RECENTLY_LINKED_REQUEST = 'GET_RECENTLY_LINKED_REQUEST';
export const GET_RECENTLY_LINKED_SUCCESS = 'GET_RECENTLY_LINKED_SUCCESS';
export const GET_RECENTLY_LINKED_FAILURE = 'GET_RECENTLY_LINKED_FAILURE';

// GET_RECENTLY_VIEWED
export const GET_RECENTLY_VIEWED_REQUEST = 'GET_RECENTLY_VIEWED_REQUEST';
export const GET_RECENTLY_VIEWED_SUCCESS = 'GET_RECENTLY_VIEWED_SUCCESS';
export const GET_RECENTLY_VIEWED_FAILURE = 'GET_RECENTLY_VIEWED_FAILURE';

// GET_RECENTLY_LINKED_CATEGORY
export const GET_RECENTLY_LINKED_CATEGORY_REQUEST = 'GET_RECENTLY_LINKED_CATEGORY_REQUEST';
export const GET_RECENTLY_LINKED_CATEGORY_SUCCESS = 'GET_RECENTLY_LINKED_CATEGORY_SUCCESS';
export const GET_RECENTLY_LINKED_CATEGORY_FAILURE = 'GET_RECENTLY_LINKED_CATEGORY_FAILURE';

// GET_POST_UPDATE
export const GET_POST_UPDATE_REQUEST = 'GET_POST_UPDATE_REQUEST';
export const GET_POST_UPDATE_SUCCESS = 'GET_POST_UPDATE_SUCCESS';
export const GET_POST_UPDATE_FAILURE = 'GET_POST_UPDATE_FAILURE';

// GET_DIALCODE
export const GET_DIALCODE_REQUEST = 'GET_DIALCODE_REQUEST';
export const GET_DIALCODE_SUCCESS = 'GET_DIALCODE_SUCCESS';
export const GET_DIALCODE_FAILURE = 'GET_DIALCODE_FAILURE';

// GET_POST_SAVE_TOGGED
export const GET_POST_SAVE_TOGGED_REQUEST = 'GET_POST_SAVE_TOGGED_REQUEST';
export const GET_POST_SAVE_TOGGED_SUCCESS = 'GET_POST_SAVE_TOGGED_SUCCESS';
export const GET_POST_SAVE_TOGGED_FAILURE = 'GET_POST_SAVE_TOGGED_FAILURE';

// GET_ALL_POST_DATE
export const GET_ALL_POST_DATE_REQUEST = 'GET_ALL_POST_DATE_REQUEST';
export const GET_ALL_POST_DATE_SUCCESS = 'GET_ALL_POST_DATE_SUCCESS';
export const GET_ALL_POST_DATE_FAILURE = 'GET_ALL_POST_DATE_FAILURE';

// GET_NOTIFICATION
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

// GET_FOLLOW_USER
export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE';

export const UNFOLLOW_USER_REQUEST = 'UNFOLLOW_USER_REQUEST';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE';

export const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS';
export const UPDATE_ANALYTICS_FALIURE = 'UPDATE_ANALYTICS_FALIURE';

// CHANGE_PASSWORD_SUCCESS
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';

// LANGUAGE
export const LANGUAGE_SUCCESS = 'LANGUAGE_SUCCESS';
export const LANGUAGE_FAILURE = 'LANGUAGE_FAILURE';
export const GET_LANGUAGE_REQUEST = 'GET_LANGUAGE_REQUEST';

// LANGUAGE
export const GET_BLOG_COMMENT_LIST_SUCCESS = 'GET_BLOG_COMMENT_LIST_SUCCESS';
export const GET_BLOG_COMMENT_LIST_FAILURE = 'GET_BLOG_COMMENT_LIST_FAILURE';
export const GET_BLOG_COMMENT_LIST_REQUEST = 'GET_BLOG_COMMENT_LIST_REQUEST';

// LANGUAGE
export const GET_BLOG_COMMENT_REPLY_LIST_SUCCESS = 'GET_BLOG_COMMENT_REPLY_LIST_SUCCESS';
export const GET_BLOG_COMMENT_REPLY_LIST_FAILURE = 'GET_BLOG_COMMENT_REPLY_LIST_FAILURE';
export const GET_BLOG_COMMENT_REPLY_LIST_REQUEST = 'GET_BLOG_COMMENT_REPLY_LIST_REQUEST';

// CATEGORY
export const GET_BLOG_CATEGORY_REQUEST = 'GET_BLOG_CATEGORY_REQUEST';
export const GET_BLOG_CATEGORY_FAILURE = 'GET_BLOG_CATEGORY_FAILURE';
export const GET_BLOG_CATEGORY_SUCCESS = 'GET_BLOG_CATEGORY_SUCCESS';

// CATEGORY DETAIL
export const GET_CATEGORY_DETAILS_REQUEST = 'GET_CATEGORY_DETAILS_REQUEST';
export const GET_CATEGORY_DETAILS_SUCCESS = 'GET_CATEGORY_DETAILS_SUCCESS';
export const GET_CATEGORY_DETAILS_FAILURE = 'GET_CATEGORY_DETAILS_FAILURE';

// OTP VERIFICATION
export const OTP_VERIFICATION_SUCCESS = 'OTP_VERIFICATION_SUCCESS';
export const OTP_VERIFICATION_REQUEST = 'OTP_VERIFICATION_REQUEST';
export const OTP_VERIFICATION_FAILURE = 'OTP_VERIFICATION_FAILURE';

// SEARCH_POSTS
export const SEARCH_POSTS_REQUEST = 'SEARCH_POSTS_REQUEST';
export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS';
export const SEARCH_POSTS_FAILURE = 'SEARCH_POSTS_FAILURE';

// SUB_CATEGRY
export const GET_SUB_CATEGRY_REQUEST = 'GET_SUB_CATEGRY_REQUEST';
export const GET_SUB_CATEGRY_SUCCESS = 'GET_SUB_CATEGRY_SUCCESS';
export const GET_SUB_CATEGRY_FAILURE = 'GET_SUB_CATEGRY_FAILURE';

// GET_FOLLOWER_FOLLOWING_LIST
export const GET_FOLLOWER_FOLLOWING_LIST_REQUEST = 'GET_FOLLOWER_FOLLOWING_LIST_REQUEST';
export const GET_FOLLOWER_FOLLOWING_LIST_SUCCESS = 'GET_FOLLOWER_FOLLOWING_LIST_SUCCESS';
export const GET_FOLLOWER_FOLLOWING_LIST_FAILURE = 'GET_FOLLOWER_FOLLOWING_LIST_FAILURE';

export const SET_PLAYER_ID = 'SET_PLAYER_ID';

// USER_POST_BY_USE_ID
export const GET_USER_POST_BY_USE_ID_REQUEST = 'GET_USER_POST_BY_USE_ID_REQUEST';
export const GET_USER_POST_BY_USE_ID_SUCCESS = 'GET_USER_POST_BY_USE_ID_SUCCESS';
export const GET_USER_POST_BY_USE_ID_FAILURE = 'GET_USER_POST_BY_USE_ID_FAILURE';