// Searchoption.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Searchoption = ({ searchQuery, handleSearchInputChange,  _class='' }) => {


    return (
        <div className='search_box'>
            <div>
                <Link to='/Search'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="movesvg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M9.1665 0.207031C4.67919 0.207031 1.0415 3.84472 1.0415 8.33203C1.0415 12.8193 4.67919 16.457 9.1665 16.457C13.6538 16.457 17.2915 12.8193 17.2915 8.33203C17.2915 3.84472 13.6538 0.207031 9.1665 0.207031ZM2.2915 8.33203C2.2915 4.53507 5.36955 1.45703 9.1665 1.45703C12.9635 1.45703 16.0415 4.53507 16.0415 8.33203C16.0415 12.129 12.9635 15.207 9.1665 15.207C5.36955 15.207 2.2915 12.129 2.2915 8.33203Z"
                            fill="#000000" />
                        <path
                            d="M16.2752 14.5568C16.0311 14.3128 15.6354 14.3128 15.3913 14.5568C15.1472 14.8009 15.1472 15.1966 15.3913 15.4407L18.7245 18.774C18.9686 19.0181 19.3643 19.0181 19.6084 18.774C19.8525 18.5299 19.8525 18.1342 19.6084 17.8901L16.2752 14.5568Z"
                            fill="#000000" />
                    </svg>
                </Link>
                <Link to='/Search'>
                    <input
                    autoFocus ={_class?true:false}
                        className={_class}
                        type='text'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                </Link>
            </div>
        </div>
    );
};

export default Searchoption;


// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { searchPosts } from '../../redux/actions/action-creator';
// import SearchResult from './SearchResult';
// import { Link } from 'react-router-dom';

// const Searchoption = () => {
//     const { searchResult = [] } = useSelector(state => state.common);
//     const dispatch = useDispatch();
//     const [isLoading, setIsLoading] = useState(false);
//     const [searchTerm, setSearchTerm] = useState('');

//     useEffect(() => {
//         API_Search();
//     }, []);

//     const API_Search = () => {
//         dispatch(searchPosts())
//             .then(response => {
//                 if (response?.success) {
//                 } else {
//                 }
//             })
//             .catch(error => {
//             });
//     };

//     const handleSearchInputChange = (event) => {
//         const value = event.target.value;
//         setSearchTerm(value);
//         const formData = new FormData();
//         formData.append('keyword', value || '');
//         dispatch(searchPosts(formData))
//             .then(response => {
//                 if (response?.success) {
//                 } else {
//                 }
//             })
//             .catch(error => {
//             });
//     };

//     // console.log('Search api list check', searchResult);

//     return (
//         <div className='search_box'>
//             <div>
//                 <h1>aaaaaaaa</h1>
//                 <Link to='/Search'>
//                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path fillRule="evenodd" clipRule="evenodd"
//                             d="M9.1665 0.207031C4.67919 0.207031 1.0415 3.84472 1.0415 8.33203C1.0415 12.8193 4.67919 16.457 9.1665 16.457C13.6538 16.457 17.2915 12.8193 17.2915 8.33203C17.2915 3.84472 13.6538 0.207031 9.1665 0.207031ZM2.2915 8.33203C2.2915 4.53507 5.36955 1.45703 9.1665 1.45703C12.9635 1.45703 16.0415 4.53507 16.0415 8.33203C16.0415 12.129 12.9635 15.207 9.1665 15.207C5.36955 15.207 2.2915 12.129 2.2915 8.33203Z"
//                             fill="#000000" />
//                         <path
//                             d="M16.2752 14.5568C16.0311 14.3128 15.6354 14.3128 15.3913 14.5568C15.1472 14.8009 15.1472 15.1966 15.3913 15.4407L18.7245 18.774C18.9686 19.0181 19.3643 19.0181 19.6084 18.774C19.8525 18.5299 19.8525 18.1342 19.6084 17.8901L16.2752 14.5568Z"
//                             fill="#000000" />
//                     </svg>
//                 </Link>
//                 <Link to='/Search'>
//                     <input
//                         type='text'
//                         placeholder='Search...'
//                         value={searchTerm}
//                         onChange={handleSearchInputChange}
//                     />
//                 </Link>
//             </div>
//             {/* <img src={Filter} alt='' className='hear_Filter' /> */}
//             {searchTerm && <SearchResult searchResult={searchResult} />}
//         </div>
//     );
// };

// export default Searchoption;

