
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDialcode, signUp } from "../redux/actions/action-creator";
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHECK } from "../constants";
import { Assets } from "../assets";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useNavigate, } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Signup = ({ notificationToken }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const { dialcodelist = [] } = useSelector(state => state.common)
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // dailcode select  =====>
    const [dailcodeselect, setDailcodeselect] = useState(false);
    const [dailcodeoption, setDailcodeoption] = useState(null);
    const [searchDailcode, setSearchDailcode] = useState('');
    const DialcodeToggle = () => {
        setDailcodeselect(!dailcodeselect);
    };
    const handleDailcode = (dialCode) => {
        setDailcodeoption(dialCode);
        setDailcodeselect(false);
        console.log('Selected Dial Code:', dialCode);
    };

    useEffect(() => {
        API_Function();
    }, []);

    const API_Function = () => {
        dispatch(getDialcode())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const [signupForm, setSignForm] = useState({
        name: '',
        email: '',
        password: '',
        phone: '',
        agree_terms_condition: '',
    });

    const validate_Function = () => {
        const { name = '', email = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

        if (name) {
            if (EMAIL_CHECK.test(email)) {
                if (password) {
                    if (phone) {
                        if (agree_terms_condition) {
                            signup_Function();
                        } else {
                            toast.error('Accept terms & condition')
                        }
                    } else {
                        toast.error('Enter phone number')
                    }
                } else {
                    toast.error('Enter password')
                }
            } else {
                toast.error('Enter email')
            }
        } else {
            toast.error('Enter name')
        }
    }

    const signup_Function = () => {
        setIsLoading(true);
        const { name = '', email = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

        let formData = new FormData();
        formData.append('name', name || "");
        formData.append('email', email || "");
        formData.append('password', password || "");
        formData.append('phone', phone || "");
        formData.append('dial_code', dailcodeoption?.code || "");
        formData.append('device_token', deviceToken || '');
        formData.append('deviceType', deviceType || '');
        formData.append('guest_id', GuestId || '');
        formData.append('device_key', notificationToken || '');

        dispatch(signUp(formData)).then(response => {
            if (response?.success) {
                response.data.password = password;
                setSignForm({
                    name: '', email: '', password: '', phone: '', agree_terms_condition: '',
                });
                toast.success(response?.message || 'success')
                setIsLoading(false);
                navigate('/Interest');
            } else {
                setIsLoading(false);
                toast.error(response?.response?.data?.message || 'Retry again')
            }
        }).catch(error => {
            console.log("error signUp  ===>", error);
            setIsLoading(false);
            toast.error(error?.response?.data?.message || 'Signup failed')
        })

    }

    // console.log('============= sign===========', signupForm)

    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Sign up</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>Name</span>
                            <input
                                type="text"
                                placeholder='Enter name'
                                value={signupForm?.name}
                                onChange={({ target }) => setSignForm({ ...signupForm, name: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="email"
                                value={signupForm?.email}
                                onChange={({ target }) => setSignForm({ ...signupForm, email: target.value })}
                                placeholder='Enter email'
                                error={'Enter first name'}
                            />
                        </div>

                        <div className="logn_input">
                            <span>Mobile Number</span>
                            <div className="cund_dial">
                                <div className="custom-dropdown">
                                    <div className="selected-option" onClick={DialcodeToggle}>
                                        <span>{dailcodeoption ? `${dailcodeoption.code}` : '+91'}</span>
                                        <span>{dailcodeselect ? <FaAngleUp /> : <FaAngleDown />}</span>
                                    </div>
                                    {dailcodeselect && (
                                        <div className="Nationalityselect_man_box">
                                            <input
                                                type="search"
                                                placeholder="Search code"
                                                value={searchDailcode}
                                                onChange={(e) => setSearchDailcode(e.target.value)}
                                            />
                                            <ul className="Nationalityselect">
                                                {dialcodelist?.dial_codes
                                                    .filter(item => item.code.toLowerCase().includes(searchDailcode.toLowerCase()))
                                                    .map((item, index) => (
                                                        <li key={index} onClick={() => handleDailcode(item)}>
                                                            <span>{item.code}</span>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="number"
                                    placeholder='Enter phone number'
                                    value={signupForm?.phone}
                                    onChange={({ target }) => {
                                        const newValue = target.value.slice(0, 10);
                                        setSignForm({ ...signupForm, phone: newValue });
                                    }}
                                    maxLength={10}
                                />
                            </div>
                        </div>
                        <div className="logn_input ">
                            <span>Password</span>
                            <div style={{ position: 'relative' }} >
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    value={signupForm.password}
                                    onChange={({ target }) => setSignForm({ ...signupForm, password: target.value })}
                                />
                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                    {signupForm.password ? (
                                        showPassword ? (
                                            < FaEye />
                                        ) : (
                                            <FaEyeSlash />
                                        )
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className="singUp_check">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={signupForm?.agree_terms_condition}
                                    onChange={({ target }) => setSignForm({ ...signupForm, agree_terms_condition: target.checked })}
                                />
                                Agree terms and conditions
                            </label>
                        </div>
                    </div>
                    <button className='logi_in_button' onClick={() => validate_Function()} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Sign up'}
                    </button>
                    <p className='Login_to_singUp_button'>already sign up <NavLink to='/Login'>Login</NavLink></p>
                </div>

            </div>
        </div>
    )
}

export default Signup
