
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDialcode, moderatorSignUp, signUp } from "../redux/actions/action-creator";
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHECK } from "../constants";
import { Assets } from "../assets";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { useParams } from 'react-router-dom';

const Moderator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { userData = null, } = useSelector(state => state.auth);
    const { cityList = [], dialcodelist = [] } = useSelector(state => state.common)
    const [isLoading, setIsLoading] = useState(false);
    const [username, setName] = useState('');
    const [useremail, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    

    // Gneder Select =====>
    const [genderselect, setGenderselect] = useState(false);
    const [genderoption, setGenderoption] = useState(null);
    const GenderToggle = () => {
        setGenderselect(!genderselect);
    };
    const handleTypUrl = (selectedGender) => {
        setGenderoption(selectedGender);
        setGenderselect(false);
    };
    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
    ];

    useEffect(() => {
        API_Function();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paramName = queryParams.get('name');
        const paramEmail = queryParams.get('email');

        // Check if both name and email parameters are present
        if (paramName && paramEmail) {
            setName(paramName);
            setEmail(decodeURIComponent(paramEmail));
        } else {
            // Handle the case where one or both parameters are missing
            // You can redirect the user to an error page or show a message
            console.error('Name and email parameters are required.');
        }
    }, [location.search]);

    const API_Function = () => {
        dispatch(getDialcode())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };
    // Nationalityselect Select =====>
    const [Nationalityselect, setNationalityselect] = useState(false);
    const [Nationalityselectoption, setNationalityselectoption] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const NationalilToggle = () => {
        setNationalityselect(!Nationalityselect);
    };

    const NationalilName = (nationality) => {
        setNationalityselectoption(nationality);
        setNationalityselect(false);
        console.log('Selected Nationality:', Nationalityselectoption);
    };

    const { name, email } = useParams();

    const [signupForm, setSignForm] = useState({
        name: username || '',
        email: useremail || '',
        password: '',
        gender: '',
        age: '',
        nationality: '',
        phone: '',
        agree_terms_condition: '',
    });
    useEffect(() => {
        setSignForm(prevState => ({
            ...prevState,
            name: username || prevState.name,
            email: useremail || prevState.email
        }));
    }, [username, useremail]);

    const validate_Function = () => {
        const { name = '', email = '', gender = '', age = '', Nationality = '', Number = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

        if (name) {
            if (EMAIL_CHECK.test(email)) {
                if (password) {
                    if (phone) {
                        if (agree_terms_condition) {
                            signup_Function();
                        } else {
                            toast.error('Accept terms & condition')
                        }
                    } else {
                        toast.error('enter phone number')
                    }
                } else {
                    toast.error('Enter password')
                }
            } else {
                toast.error('Enter email')
            }
        } else {
            toast.error('Enter name')
        }
    }

    const signup_Function = () => {
        setIsLoading(true);
        const { name = '', email = '', password = '', phone = '', age = '', } = signupForm

        let formData = new FormData();
        formData.append('name', name || "");
        formData.append('email', email || "");
        formData.append('password', password || "");
        formData.append('gender', genderoption?.value || "");
        formData.append('age', age || "");
        formData.append('nationality', Nationalityselectoption || "");
        formData.append('phone', phone || "");
        formData.append('dial_code', dailcodeoption?.code || "");
        dispatch(moderatorSignUp(formData)).then(response => {
            if (response?.success) {
                response.data.password = password;

                setSignForm({
                    name: '', email: '', password: '', phone: '', age: '', agree_terms_condition: '',
                });
                toast.success(response?.message || 'Success')
                setIsLoading(false);
                navigate('/Interest');
            } else {
                setIsLoading(false);
                toast.error(response?.response?.data?.message || 'Retry again')
            }
        }).catch(error => {
            console.log("error signUp  ===>", error);
            setIsLoading(false);
            toast.error(error?.response?.data?.message || 'Signup failed')
        });
    }

    // dailcode select  =====>
    const [dailcodeselect, setDailcodeselect] = useState(false);
    const [dailcodeoption, setDailcodeoption] = useState(null);
    const [searchDailcode, setSearchDailcode] = useState('');
    const DialcodeToggle = () => {
        setDailcodeselect(!dailcodeselect);
    };
    const handleDailcode = (dialCode) => {
        setDailcodeoption(dialCode);
        setDailcodeselect(false);
        console.log('Selected Dial Code:', dialCode);
    };


    return (
        <div className='Login_screen'>
            <style>
                {`
                 .sitebar_main {
                     display: none;
                 }
                `}
            </style>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Complete Sign up as Moderator</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>Name</span>
                            <input
                                type="text"
                                placeholder='Enter name'
                                value={signupForm?.name}
                                onChange={({ target }) => setSignForm({ ...signupForm, name: target.value })}
                                readOnly
                            />
                        </div>
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="email"
                                value={signupForm?.email}
                                onChange={({ target }) => setSignForm({ ...signupForm, email: target.value })}
                                placeholder='Enter email'
                                error={'Enter first name'}
                                readOnly
                            />
                        </div>
                        <div className="logn_input logn_input_box">
                            <div className="logn_input">
                                <span>Gender</span>
                                <div className="custom-dropdown">
                                    <div className="selected-option" onClick={GenderToggle}>
                                        <span>{genderoption ? genderoption.label : 'Select gender'}</span>
                                        <span>{genderselect ? <FaAngleUp /> : <FaAngleDown />}</span>
                                    </div>
                                    {genderselect && (
                                        <ul className="options-list">
                                            {genderOptions.map((item, typIndex) => (
                                                <li key={typIndex} onClick={() => handleTypUrl(item)}>{item.label}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <div className="logn_input">
                                <span>Age</span>
                                <input
                                    type="number"
                                    placeholder='Enter age'
                                    value={signupForm?.age}
                                    onChange={({ target }) => setSignForm({ ...signupForm, age: target.value })}
                                />
                            </div>
                        </div>

                        <div className="logn_input logn_input_box">
                            <div className="logn_input">
                                <span>Nationality</span>
                                <div className="custom-dropdown">
                                    <div className="selected-option" onClick={NationalilToggle}>
                                        <span>{Nationalityselectoption ? Nationalityselectoption : 'Select nationality'}</span>
                                        <span>{Nationalityselect ? <FaAngleUp /> : <FaAngleDown />}</span>
                                    </div>
                                    {Nationalityselect && (
                                        <div className="Nationalityselect_man_box">
                                            <input
                                                type="search"
                                                placeholder="Search nationality"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <ul className="Nationalityselect">
                                                {dialcodelist?.nationalities && dialcodelist.nationalities
                                                    .filter(item => item.toLowerCase().includes(searchQuery.toLowerCase()))
                                                    .map((item, typIndex) => (
                                                        <li key={typIndex} onClick={() => NationalilName(item)}>
                                                            {item}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="logn_input">
                                <span>Mobile Number</span>
                                <div className="cund_dial">
                                    <div className="custom-dropdown">
                                        <div className="selected-option" onClick={DialcodeToggle}>
                                            <span>{dailcodeoption ? `${dailcodeoption.code}` : '+91'}</span>
                                            <span>{dailcodeselect ? <FaAngleUp /> : <FaAngleDown />}</span>
                                        </div>
                                        {dailcodeselect && (
                                            <div className="Nationalityselect_man_box">
                                                <input
                                                    type="search"
                                                    placeholder="Search code"
                                                    value={searchDailcode}
                                                    onChange={(e) => setSearchDailcode(e.target.value)}
                                                />
                                                <ul className="Nationalityselect">
                                                    {dialcodelist?.dial_codes
                                                        .filter(item => item.code.toLowerCase().includes(searchDailcode.toLowerCase()))
                                                        .map((item, index) => (
                                                            <li key={index} onClick={() => handleDailcode(item)}>
                                                                <span>{item.code}</span>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <input
                                        type="number"
                                        placeholder='Enter phone number'
                                        value={signupForm?.phone}
                                        onChange={({ target }) => {
                                            const newValue = target.value.slice(0, 10);
                                            setSignForm({ ...signupForm, phone: newValue });
                                        }}
                                        maxLength={10}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="logn_input ">
                            <span>Password</span>
                            <div style={{ position: 'relative' }} >
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    value={signupForm.password}
                                    onChange={({ target }) => setSignForm({ ...signupForm, password: target.value })}
                                />
                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                    {signupForm.password ? (
                                        showPassword ? (
                                            < FaEye />
                                        ) : (
                                            <FaEyeSlash />
                                        )
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className="singUp_check">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={signupForm?.agree_terms_condition}
                                    onChange={({ target }) => setSignForm({ ...signupForm, agree_terms_condition: target.checked })}
                                />
                                Agree terms and conditions
                            </label>
                        </div>
                    </div>
                    <button className='logi_in_button' onClick={() => validate_Function()} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Sign up'}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Moderator









// import React, { useEffect, useState } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";
// import { NavLink } from 'react-router-dom'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { getDialcode, moderatorSignUp, signUp } from "../redux/actions/action-creator";
// import { useDispatch, useSelector } from 'react-redux';
// import { EMAIL_CHECK } from "../constants";
// import { Assets } from "../assets";
// import { FaEyeSlash, FaEye } from "react-icons/fa";
// import { useNavigate, } from 'react-router-dom';
// import { FaAngleDown, FaAngleUp } from "react-icons/fa";
// import { useParams } from 'react-router-dom';

// const Moderator = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { userData = null, } = useSelector(state => state.auth);
//     const { cityList = [], dialcodelist = [] } = useSelector(state => state.common)
//     const [isLoading, setIsLoading] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);
//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//     };


//     // Gneder Select =====>
//     const [genderselect, setGenderselect] = useState(false);
//     const [genderoption, setGenderoption] = useState(null);
//     const GenderToggle = () => {
//         setGenderselect(!genderselect);
//     };
//     const handleTypUrl = (selectedGender) => {
//         setGenderoption(selectedGender);
//         setGenderselect(false);
//     };
//     const genderOptions = [
//         { value: 'male', label: 'Male' },
//         { value: 'female', label: 'Female' },
//     ];

//     useEffect(() => {
//         API_Function();
//     }, []);

//     const API_Function = () => {
//         dispatch(getDialcode())
//             .then(response => {
//                 if (response?.success) {
//                     setIsLoading(false);
//                 } else {
//                     setIsLoading(false);
//                 }
//             })
//             .catch(error => {
//                 setIsLoading(false);
//             });
//     };
//     // Nationalityselect Select =====>
//     const [Nationalityselect, setNationalityselect] = useState(false);
//     const [Nationalityselectoption, setNationalityselectoption] = useState(null);
//     const [searchQuery, setSearchQuery] = useState('');

//     const NationalilToggle = () => {
//         setNationalityselect(!Nationalityselect);
//     };

//     const NationalilName = (nationality) => {
//         setNationalityselectoption(nationality);
//         setNationalityselect(false);
//         console.log('Selected Nationality:', Nationalityselectoption);
//     };

//     const { name, email } = useParams();

//     const [signupForm, setSignForm] = useState({
//         name: name || '',
//         email: email || '',
//         password: '',
//         gender: '',
//         age: '',
//         nationality: '',
//         phone: '',
//         agree_terms_condition: '',
//     });
//     useEffect(() => {
//         setSignForm(prevState => ({
//             ...prevState,
//             name: name || prevState.name,
//             email: email || prevState.email
//         }));
//     }, [name, email]);

//     const validate_Function = () => {
//         const { name = '', email = '', gender = '', age = '', Nationality = '', Number = '', password = '', phone = '', agree_terms_condition = '', } = signupForm

//         if (name) {
//             if (EMAIL_CHECK.test(email)) {
//                 if (password) {
//                     if (phone) {
//                         if (agree_terms_condition) {
//                             signup_Function();
//                         } else {
//                             toast.error('Accept Terms & Condition')
//                         }
//                     } else {
//                         toast.error('enter Phome N.')
//                     }
//                 } else {
//                     toast.error('Enter Password')
//                 }
//             } else {
//                 toast.error('Enter Email')
//             }
//         } else {
//             toast.error('Enter Name')
//         }
//     }

//     const signup_Function = () => {
//         setIsLoading(true);
//         const { name = '', email = '', password = '', phone = '', age = '', } = signupForm

//         let formData = new FormData();
//         formData.append('name', name || "");
//         formData.append('email', email || "");
//         formData.append('password', password || "");
//         formData.append('gender', genderoption?.value || "");
//         formData.append('age', age || "");
//         formData.append('nationality', Nationalityselectoption || "");
//         formData.append('phone', phone || "");
//         formData.append('dial_code', dailcodeoption?.code || "");

//         dispatch(moderatorSignUp(formData)).then(response => {
//             if (response?.success) {
//                 response.data.password = password;

//                 setSignForm({
//                     name: '', email: '', password: '', phone: '', age: '', agree_terms_condition: '',
//                 });
//                 toast.success(response?.message || 'success')
//                 setIsLoading(false);
//                 // navigate('/Interest');
//             } else {
//                 setIsLoading(false);
//                 toast.error(response?.message || 'Retry again')
//             }
//         }).catch(error => {
//             console.log("error signUp  ===>", error);
//             setIsLoading(false);
//             toast.info(error?.message || 'Signup failed')
//         })

//     }

//     // dailcode select  =====>
//     const [dailcodeselect, setDailcodeselect] = useState(false);
//     const [dailcodeoption, setDailcodeoption] = useState(null);
//     const [searchDailcode, setSearchDailcode] = useState('');
//     const DialcodeToggle = () => {
//         setDailcodeselect(!dailcodeselect);
//     };
//     const handleDailcode = (dialCode) => {
//         setDailcodeoption(dialCode);
//         setDailcodeselect(false);
//         console.log('Selected Dial Code:', dialCode);
//     };


//     return (
//         <div className='Login_screen'>



//             <style>
//                 {`
//                  .sitebar_main {
//                      display: none;
//                  }
//                 `}
//             </style>
//             <div className="Login_screen_con">
//                 <div className='login_fron_comaq'>
//                     <ToastContainer />
//                     <h1>Complete Sign up as Moderator</h1>
//                     <div className='login_fron'>
//                         <div className="logn_input">
//                             <span>Name</span>
//                             <input
//                                 type="text"
//                                 placeholder='Enter your name'
//                                 value={signupForm?.name}
//                                 onChange={({ target }) => setSignForm({ ...signupForm, name: target.value })}
//                             // readOnly
//                             />
//                         </div>
//                         <div className="logn_input">
//                             <span>Email</span>
//                             <input
//                                 type="email"
//                                 value={signupForm?.email}
//                                 onChange={({ target }) => setSignForm({ ...signupForm, email: target.value })}
//                                 placeholder='Enter your email'
//                                 error={'Enter first name'}
//                             />
//                         </div>

//                         <div className="logn_input logn_input_box">
//                             <div className="logn_input">
//                                 <span>Gender</span>
//                                 <div className="custom-dropdown">
//                                     <div className="selected-option" onClick={GenderToggle}>
//                                         <span>{genderoption ? genderoption.label : 'Select gender'}</span>
//                                         <span>{genderselect ? <FaAngleUp /> : <FaAngleDown />}</span>
//                                     </div>
//                                     {genderselect && (
//                                         <ul className="options-list">
//                                             {genderOptions.map((item, typIndex) => (
//                                                 <li key={typIndex} onClick={() => handleTypUrl(item)}>{item.label}</li>
//                                             ))}
//                                         </ul>
//                                     )}
//                                 </div>
//                             </div>
//                             <div className="logn_input">
//                                 <span>Age</span>
//                                 <input
//                                     type="number"
//                                     placeholder='Enter your age'
//                                     value={signupForm?.age}
//                                     onChange={({ target }) => setSignForm({ ...signupForm, age: target.value })}
//                                 />
//                             </div>
//                         </div>

//                         <div className="logn_input logn_input_box">
//                             <div className="logn_input">
//                                 <span>Nationality</span>
//                                 <div className="custom-dropdown">
//                                     <div className="selected-option" onClick={NationalilToggle}>
//                                         <span>{Nationalityselectoption ? Nationalityselectoption : 'Select nationality'}</span>
//                                         <span>{Nationalityselect ? <FaAngleUp /> : <FaAngleDown />}</span>
//                                     </div>
//                                     {Nationalityselect && (
//                                         <div className="Nationalityselect_man_box">
//                                             <input
//                                                 type="search"
//                                                 placeholder="Search nationality"
//                                                 value={searchQuery}
//                                                 onChange={(e) => setSearchQuery(e.target.value)}
//                                             />
//                                             <ul className="Nationalityselect">
//                                                 {dialcodelist?.nationalities && dialcodelist.nationalities
//                                                     .filter(item => item.toLowerCase().includes(searchQuery.toLowerCase()))
//                                                     .map((item, typIndex) => (
//                                                         <li key={typIndex} onClick={() => NationalilName(item)}>
//                                                             {item}
//                                                         </li>
//                                                     ))}
//                                             </ul>
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                             <div className="logn_input">
//                                 <span>Mobile Number</span>
//                                 <div className="cund_dial">
//                                     <div className="custom-dropdown">
//                                         <div className="selected-option" onClick={DialcodeToggle}>
//                                             <span>{dailcodeoption ? `${dailcodeoption.code}` : '+91'}</span>
//                                             <span>{dailcodeselect ? <FaAngleUp /> : <FaAngleDown />}</span>
//                                         </div>
//                                         {dailcodeselect && (
//                                             <div className="Nationalityselect_man_box">
//                                                 <input
//                                                     type="search"
//                                                     placeholder="Search code"
//                                                     value={searchDailcode}
//                                                     onChange={(e) => setSearchDailcode(e.target.value)}
//                                                 />
//                                                 <ul className="Nationalityselect">
//                                                     {dialcodelist?.dial_codes
//                                                         .filter(item => item.code.toLowerCase().includes(searchDailcode.toLowerCase()))
//                                                         .map((item, index) => (
//                                                             <li key={index} onClick={() => handleDailcode(item)}>
//                                                                 <span>{item.code}</span>
//                                                             </li>
//                                                         ))}
//                                                 </ul>
//                                             </div>
//                                         )}
//                                     </div>
//                                     <input
//                                         type="number"
//                                         placeholder='Enter mobile number'
//                                         value={signupForm?.phone}
//                                         onChange={({ target }) => {
//                                             const newValue = target.value.slice(0, 10);
//                                             setSignForm({ ...signupForm, phone: newValue });
//                                         }}
//                                         maxLength={10}
//                                     />
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="logn_input ">
//                             <span>Password</span>
//                             <div style={{ position: 'relative' }} >
//                                 <input
//                                     type={showPassword ? 'text' : 'password'}
//                                     placeholder='Enter your password'
//                                     value={signupForm.password}
//                                     onChange={({ target }) => setSignForm({ ...signupForm, password: target.value })}
//                                 />
//                                 <span className="eye-icon" onClick={togglePasswordVisibility}>
//                                     {signupForm.password ? (
//                                         showPassword ? (
//                                             < FaEye />
//                                         ) : (
//                                             <FaEyeSlash />
//                                         )
//                                     ) : null}
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="singUp_check">
//                             <label>
//                                 <input
//                                     type="checkbox"
//                                     checked={signupForm?.agree_terms_condition}
//                                     onChange={({ target }) => setSignForm({ ...signupForm, agree_terms_condition: target.checked })}
//                                 />
//                                 Agree terms and conditions
//                             </label>
//                         </div>
//                     </div>
//                     <button className='logi_in_button' onClick={() => validate_Function()} disabled={isLoading}>
//                         {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Sign up'}
//                     </button>
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default Moderator
