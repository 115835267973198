import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as DARK_LIGHT } from '../assets/svg/Setting_Icons/Mode.svg';
import { ReactComponent as GENERAL } from '../assets/svg/Setting_Icons/Help.svg';
import { ReactComponent as SECURITY } from '../assets/svg/Setting_Icons/Security.svg';
import { ReactComponent as PREFERECE } from '../assets/svg/Setting_Icons/Preference.svg';
import { ReactComponent as NOTIFICATIONS } from '../assets/svg/Setting_Icons/Notifications.svg';
import { ReactComponent as LANGUAGE } from '../assets/svg/Setting_Icons/Change Language.svg';
import { ReactComponent as HELP } from '../assets/svg/Setting_Icons/Help.svg';
import { ReactComponent as PENCEL } from '../assets/svg/Setting_Icons/pencel.svg';
import { Assets } from '../assets';
import SecurityComponent from './Security';
import PreferenceComponent from './Preference';
import General from './General';
import NotificationsSettimg from './NotificationsSettimg';
import Language from './Language';
import SocialMedia from './SocialMedia';
import Help from './Help';
import { NavLink } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';
import { getUserProfile, toggleTheme, getUpdateProfile } from '../redux/actions/action-creator';
import { connect, useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Setting = ({ theme, toggleTheme, googleData }) => {
    const dispatch = useDispatch();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const { userData = null, profileData = null, loginToken = '' } = useSelector(state => state.auth);

    const [tabs, setTabs] = useState(2);
    const [imageSrc, setImageSrc] = useState(Assets.USER_site);
    const [showUpload, setShowUpload] = useState(false);
    const inputFileRef = useRef(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [profileForm, setProfileForm] = useState({
        photo: '',
    });
    const handlePencilClick = () => {
        inputFileRef.current.click(); // Programmatically trigger the file input click event
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                let formData = new FormData();
                formData.append('photo', reader.result || "");
                dispatch(getUpdateProfile(formData)).then(response => {
                    if (response?.success) {
                        setPreviewImage(response?.data?.photo);
                        toast.success(response?.message || 'success')
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        toast.error(response?.response?.data?.message || 'Retry again')
                    }
                }).catch(error => {
                    console.log("error profile  ===>", error);
                    setIsLoading(false);
                    toast.error(error?.response?.data?.message || 'Profile failed')
                })
            };
            reader.readAsDataURL(file);
        }
    };
    // const handleImageUpload = (event) => {
    //     const file = event.target.files[0];
    //     const reader = new FileReader();

    //     reader.onloadend = () => {
    //         setImageSrc(reader.result);
    //         setShowUpload(false); // Hide the upload UI after image is uploaded
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    // const handlePencilClick = () => {
    //     setShowUpload(true); // Show the upload UI when the pencil icon is clicked
    // };

    const toggleTabs = (index) => {
        setTabs(index);
    }

    const themtogglebutton = () => {
        toggleTheme();
        setIsDarkMode(!isDarkMode);
        const body = document.body;
        body.classList.toggle('dark-theme');
        body.classList.toggle('light-theme');
        localStorage.setItem('theme', isDarkMode ? 'light' : 'dark');
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme === 'dark') {
            setIsDarkMode(true);
            document.body.classList.add('dark-theme');
        }
    }, [])

    useEffect(() => {
        const userId = userData?.id;
        if (userId) {
            UserID_Function(userId);
            console.log('profileData', profileData);
        }
    }, [userData]);

    const UserID_Function = (userId) => {
        dispatch(getUserProfile(userId))
            .then(response => {
                // console.log("getUserProfile Success Response:", response);
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error("getUserProfile Error:", error);
                setIsLoading(false);
            });
    };



    return (
        <div className='setting_grid_colum'>
            <div className='srtting_sitbar'>
                <div className='setting_navLinks'>
                    <div className='edit_profile_img'>
                        <div className='edit_profile_img_pencel'>
                            {/* <img src={Assets.USER_site} alt=''/>
                            <span><PENCEL/></span> */}
                            {profileData?.photo ? (
                                <img src={profileData?.photo} alt='Uploaded Image' />
                            ) : (
                                <img src={Assets.USER_site} alt='User Image' />
                            )}
                            <span onClick={handlePencilClick}><PENCEL /></span>
                            <input
                                ref={inputFileRef}
                                type='file'
                                accept='image/*'
                                style={{ display: 'none' }} // Hide the file input visually
                                onChange={handleImageUpload}
                            />
                        </div>
                        <div>
                            <h2>{profileData?.name || 'Guest User'}</h2>
                            {/* <p>{profileData?.email || 'user@gmail.com'}</p> */}
                            <p>{profileData?.email || ''}</p>

                        </div>
                    </div>
                    <div className='setting_menu'>
                        <div className='setting_menu_item' >
                            <p><DARK_LIGHT /> <span>Dark mode</span></p>
                            <div className='Notifications_setting_option' >
                                <label class="switch">
                                    <input type="checkbox" checked={isDarkMode} onChange={themtogglebutton} />
                                    <span class="Noti_Toggle "></span>
                                </label>
                            </div>
                        </div>
                        <div className={`setting_menu_item ${tabs === 2 ? 'active' : ''}`} onClick={() => toggleTabs(2)}>
                            <p><GENERAL /> <span>General</span></p>
                        </div>
                        {!googleData && (
                            <div className={`setting_menu_item ${tabs === 3 ? 'active' : ''}`} onClick={() => toggleTabs(3)}>
                                <p><SECURITY /> <span>Security </span></p>
                            </div>
                        )}


                        <div className={`setting_menu_item ${tabs === 4 ? 'active' : ''}`} onClick={() => toggleTabs(4)}>
                            <p><PREFERECE /> <span>Preference</span></p>
                        </div>

                        <div className={`setting_menu_item ${tabs === 5 ? 'active' : ''}`} onClick={() => toggleTabs(5)}>
                            <p><NOTIFICATIONS /> <span>Notifications</span></p>
                        </div>
                        <div className={`setting_menu_item ${tabs === 6 ? 'active' : ''}`} onClick={() => toggleTabs(6)}>
                            <p><LANGUAGE /> <span>Change Language</span></p>
                        </div>
                        <div className={`setting_menu_item ${tabs === 7 ? 'active' : ''}`} onClick={() => toggleTabs(7)}>
                            <p><GENERAL /> <span>Social Media</span></p>
                        </div>
                        <div className={`setting_menu_item ${tabs === 8 ? 'active' : ''}`} onClick={() => toggleTabs(8)}>
                            <p><HELP /> <span>Help</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="SettingTabs">
                {tabs === 2 && (
                    <General />
                )}
                {tabs === 3 && (
                    <div className='settingtabscolim'>
                        <SecurityComponent />
                    </div>
                )}
                {tabs === 4 && (
                    <div className='settingtabscolim'>
                        <PreferenceComponent />
                    </div>
                )}
                {tabs === 5 && (
                    <div className='settingtabscolim'>
                        <NotificationsSettimg />
                    </div>
                )}
                {tabs === 6 && (
                    <div className='settingtabscolim'>
                        <Language />
                    </div>
                )}
                {tabs === 7 && (
                    <div className='settingtabscolim'>
                        <SocialMedia />
                    </div>
                )}
                {tabs === 8 && (
                    <div className='settingtabscolim'>
                        <Help />
                    </div>
                )}
            </div>
        </div>
    );
};


const mapStateToProps = (state) => ({
    theme: state.common.theme // Accessing theme state from common reducer
});

const mapDispatchToProps = {
    toggleTheme // Dispatching toggleTheme action
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);