export const Noticoll = [
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
]
export const Users = [
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), date: '18 Nov, 2023', time: '3:36 Pm', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), date: '18 Nov, 2023', time: '3:36 Pm', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 1, Notiimg: require('../assets/images/Sitebar/Notifications_1.png'), date: '18 Nov, 2023', time: '3:36 Pm', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
]
export const Post = [
    { id: 1, Notiimg: require('../assets/images/post_1.png'), name: 'William M. Jenkins', time: '1day ago', view: '1.5M views', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 2, Notiimg: require('../assets/images/post_1.png'), name: 'William M. Jenkins', time: '1day ago', view: '1.5M views', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
    { id: 3, Notiimg: require('../assets/images/post_1.png'), name: 'William M. Jenkins', time: '1day ago', view: '1.5M views', sms: 'Sed ut perspiciatis unde omnis iste natus error' },
]
export const Featured = [
    { id: 1, images: require('../assets/images/Featured.png'), postImg: require('../assets/images/post_IMG.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 2, images: require('../assets/images/Featured2.png'), postImg: require('../assets/images/post_IMG2.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 3, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
]
export const Tranding = [
    { id: 1, images: require('../assets/images/Featured.png'), postImg: require('../assets/images/post_IMG.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 2, images: require('../assets/images/Featured2.png'), postImg: require('../assets/images/post_IMG2.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 3, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
]
export const LibraryData = [
    { id: 1, images: require('../assets/images/Featured.png'), postImg: require('../assets/images/post_IMG.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 2, images: require('../assets/images/Featured2.png'), postImg: require('../assets/images/post_IMG2.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 3, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 1, images: require('../assets/images/Featured.png'), postImg: require('../assets/images/post_IMG.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 2, images: require('../assets/images/Featured2.png'), postImg: require('../assets/images/post_IMG2.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 3, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 1, images: require('../assets/images/Featured.png'), postImg: require('../assets/images/post_IMG.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 2, images: require('../assets/images/Featured2.png'), postImg: require('../assets/images/post_IMG2.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 3, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
    { id: 4, images: require('../assets/images/Featured3.png'), postImg: require('../assets/images/post_IMG3.png'), cartName: 'Lorem ipsum dolor sit amet consectetur', view_name: 'William M. Jenkins', views: 'views', Podcast: 'Podcast', Sport: 'Sport' },
]
export const People = [
    { id: 1, imgPeople: require('../assets/images/post_IMG.png'), name: 'James S. Welch', by: 'Demo Bio', link: 'Link' },
    { id: 1, imgPeople: require('../assets/images/post_IMG.png'), name: 'James S. Welch', by: 'Demo Bio', link: 'Link' },
    { id: 1, imgPeople: require('../assets/images/post_IMG.png'), name: 'James S. Welch', by: 'Demo Bio', link: 'Link' },
]
export const NOTIFIHEADER = [
    { id: 1, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 2, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
    { id: 3, img: require('../assets/images/Sitebar/Notifications_1.png'), time: 'Just now', heading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.' },
]