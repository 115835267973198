import { combineReducers } from 'redux';
import { authReducer } from './reducers/auth';
// import { common } from "./reducers/common";
import { common } from './reducers/common'
// import { allProducts } from "./reducers/products/products";

export default combineReducers({
    auth: authReducer,      //Auth Reducer
    common: common,         //common Reducer
    // allProducts: allProducts
});

