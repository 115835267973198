import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changePassword, } from '../redux/actions/action-creator';
import { Assets } from '../assets';
import { useLocation, useNavigate, } from 'react-router-dom';


const CreatePssword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { state = '' } = useLocation();
    const [forgetPasswordForm, setForgetPasswordForm] = useState({
        otp: state?.otp || '', password: '', cpassword: '',
    });
    const ChangePassword_Function = (otpValue) => {
        const { newPassword = '', confirmPassword = '' } = forgetPasswordForm;

        let formData = new FormData();
        formData.append('password', newPassword || '');
        formData.append('cpassword', confirmPassword || '');
        formData.append('otp', state?.otp || '');

        if (newPassword?.length > 6) {
            if (newPassword === confirmPassword) {
                setIsLoading(true);
                dispatch(changePassword(formData))
                    .then((response) => {
                        if (response.success) {
                            setIsLoading(false);
                            setForgetPasswordForm({ otp: '', newPassword: '', confirmPassword: '' });
                            toast.success(response?.message || 'Password changed successfully!');
                            // navigate('/Login');
                            setTimeout(() => {
                                // Navigate to the login page after a delay (e.g., 2000 milliseconds)
                                navigate('/Login');
                            }, 2000);
                        } else {
                            setIsLoading(false);
                            toast.error(response?.message || 'Failed to change password!');
                        }
                    }).catch(error => {
                        setIsLoading(false);
                        toast.error(error?.message || 'Failed to change password!');
                    });
            } else {
                toast.error('Password not matched!');
            }
        } else {
            toast.error('Enter a new password that is at least 6 characters long!');
        }
    };




    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Create new password</h1>
                    <div className='login_fron'>
                        <input type="hidden" name="otp" value={forgetPasswordForm?.otp} /> {/* Hidden input for OTP */}
                        <div className="logn_input">
                            <span>New Password</span>
                            <input
                                type="password"
                                placeholder='Enter password'
                                value={forgetPasswordForm?.newPassword}
                                onChange={({ target }) => setForgetPasswordForm({ ...forgetPasswordForm, newPassword: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Confirm Password</span>
                            <input
                                type="password"
                                placeholder='Confirm password'
                                value={forgetPasswordForm?.confirmPassword}
                                onChange={({ target }) => setForgetPasswordForm({ ...forgetPasswordForm, confirmPassword: target.value })}
                            />
                        </div>
                    </div>
                    <button className='logi_in_button' onClick={ChangePassword_Function} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Submit'}
                    </button>


                </div>

            </div>
        </div>
    )
}

export default CreatePssword
