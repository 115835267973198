import React, { useState } from 'react';
import SearchTab from './SearchTab';
import SearchHeader from './SearchHeader';  

const Search = () => {
    // Assuming you have searchQuery and handleSearchInputChange defined here
    const [searchQuery, setSearchQuery] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value); 
      
    };
 
    return (
        <div> 
            {/* <SearchHeader searchQuery={searchQuery} handleSearchInputChange={handleSearchInputChange} /> */}
            <SearchTab searchQuery={searchQuery} handleSearchInputChange={handleSearchInputChange} />
        </div>
    );
};




export default Search;



