import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { geMaintCategorylist, getpostlist } from '../redux/actions/action-creator';
import { toast } from 'react-toastify';
import { Assets } from '../assets';
import { ReactComponent as ALL_DARK } from '../assets/svg/all-dark.svg';
import { ReactComponent as ALL_LIGHT } from '../assets/svg/all-light.svg';

const Categories = () => {
    const dispatch = useDispatch();
    const { maincategorylist = [] } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [activeCategoryId, setActiveCategoryId] = useState('all');


    useEffect(() => {
        API_Function();
    }, []);

    const API_Function = () => {
        dispatch(geMaintCategorylist())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const PostCategory_Function = (categoryId) => {
        setIsLoading(true);
        setActiveCategoryId(categoryId); // Set the active category ID
        dispatch(getpostlist(categoryId))
            .then(response => {
                setIsLoading(false);
                if (!response?.success) {
                    toast.error(response?.response?.data?.message || 'Retry again');
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Failed');
            });
    };




    const Catagry = {
        dots: true,
        nav: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            }
        ]
    };

    return (
        <div class="category_grid_container">
        <div className='categry_grid navslider'>
            <Slider {...Catagry}>
                <NavLink to='' key="all" onClick={() => PostCategory_Function('all')}
                    className={activeCategoryId === 'all' ? 'categry_grid_item_null' : 'active'}>
                    <div className='catgery_icon_svg'>
                        <ALL_DARK className='light_mood_icons_cate' />
                        <ALL_LIGHT className='dark_mood_icons_cate' />
                    </div>
                    <p>All</p>
                </NavLink>  
                {maincategorylist?.category_data?.map((item, index) => (
                    <NavLink to='' key={index} onClick={() => PostCategory_Function(item.category_id)}
                        className={activeCategoryId === item.category_id ? 'categry_grid_item_null' : ''}>
                        <div className='catgery_icon'>
                            {/* <div dangerouslySetInnerHTML={{ __html: item.category_icon }} /> */}
                            {/* {item.iconW ? <img src={item.iconW} alt="icon" /> : <Skeleton circle height={50} width={50} />} */}
                            {/* {item.iconB ? <img src={item.iconB} alt="icon" /> : <Skeleton circle height={50} width={50} />} */}
                            {/* <img src={Assets.POST_IMG} alt="icon" /> */}
                            <img className='light_mood_icons_cate' src={item.category_image || Assets.POST_IMG} />
                            <img className='dark_mood_icons_cate' src={item.category_dark_image || Assets.POST_IMG} />
                        </div>
                        <p>{item.category_name ? item.category_name : <Skeleton />}</p>
                    </NavLink>
                ))}
            </Slider>

        </div>
            </div>
    );
};

export default Categories;
