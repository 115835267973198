// import { Platform } from 'react-native';
import {
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    GUEST_ID,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
    GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE, GET_FROFILE_UPDATE_SUCCESS,
    GET_INTREST_PASS_REQUEST, GET_INTREST_PASS_SUCCESS, GET_INTREST_PASS_FAILURE,
    GET_LIBRARY_REQUEST, GET_LIBRARY_SUCCESS, GET_LIBRARY_FAILURE,
    GET_POST_UPDATE_FAILURE, GET_POST_UPDATE_SUCCESS, GET_POST_UPDATE_REQUEST,
    GET_BLOG_LIST_FAILURE, GET_BLOG_LIST_SUCCESS, GET_BLOG_LIST_REQUEST,
    MODERATOR_SIGN_UP_SUCCESS, GET_BLOG_CATEGORY_SUCCESS, GET_CATEGORY_DETAILS_SUCCESS,
    GET_INTREST_ADD_FAILURE, GET_INTREST_ADD_SUCCESS, GET_INTREST_ADD_REQUEST, FOLLOW_USER_SUCCESS, UNFOLLOW_USER_SUCCESS,
    GET_ALL_USERS_REQUEST,GET_ALL_USERS_SUCCESS,GET_ALL_USERS_FAILURE,
    CANCEL_GET_ALL_USER_PROFILE,
    SET_PLAYER_ID,

} from '../actions/action-types';


const initialState = {
    loginFirstTime: null,
    deviceToken: '', deviceType: '', loginToken: '',
    userData: null, profileData: null, SignIn: false, loginType: '',
    signin_Loader: false,
    GuestId: null,
    librarydata: [],
    blogpostlist: [],
    updatepost: null,
    intrestpass: null,
    followedUsers: [],
    analyticsData: [],
    googleLogin: null,
    alluserpostdatalist: [],
    playerId: '',

}
export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case SET_PLAYER_ID:
            return {
              ...state,
              playerId: payload,
            };

        //Guest
        case GUEST_ID:
            return {
                ...state, GuestId: payload || null, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: [], userDefaultAddress: null, userPaymentCardList: [], userOrderList: [],
                userCartList: [], cartCount: 0, loginType: 'guest', userCouponList: [], latestCoupon: null,
                showHomeCouponPopup: false
            }


        // For Logout
        case LOGOUT_REQUEST:
            return {
                ...state, Logout_Loader: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, GuestId: null, userAddressList: [], userCartList: [], cartCount: 0,
                userDefaultAddress: null, userPaymentCardList: [], userOrderList: [], loginType: '',
                loginFirstTime: null, userCouponList: [], latestCoupon: null, showHomeCouponPopup: false,
                userCurrency: null
            }
        case LOGOUT_FAILURE:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, GuestId: null, userAddressList: [], userCartList: [], cartCount: 0,
                userDefaultAddress: null, userPaymentCardList: [], userOrderList: [], loginType: '',
                loginFirstTime: null, userCouponList: [], latestCoupon: null, showHomeCouponPopup: false,
                userCurrency: null
            }

        // For SIGN_IN
        case SIGN_IN_REQUEST:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: []
            };
        case SIGN_IN_SUCCESS:
            return {
                ...state, userData: payload?.data || null,
                profileData: payload?.data || null,
                SignIn: true, signin_Loader: false,
                loginToken: payload?.data?.api_token || '', GuestId: null,
                loginFirstTime: payload?.responseData?.user_data?.is_logged || null,
                loginType: payload?.responseData?.user_data?.loginType || '',
                userCurrency: payload?.responseData?.user_data?.currency_data || null
            };
        case SIGN_IN_FAILURE:
            return {
                ...state, loginToken: '', userData: null, profileData: null, SignIn: false,
                signin_Loader: false, userAddressList: []
            };

        // For SIGN_UP
        case SIGN_UP_REQUEST:
            return {
                ...state,
            };
        case SIGN_UP_SUCCESS:
            return {
                ...state, userData: payload?.data || null,
                profileData: payload?.data || null,
                SignIn: true, signin_Loader: false,
                loginToken: payload?.data?.api_token || '', GuestId: null,
                loginFirstTime: payload?.responseData?.user_data?.is_logged || null,
                loginType: payload?.responseData?.user_data?.loginType || '',
                userCurrency: payload?.responseData?.user_data?.currency_data || null
            };
        // return {
        //     ...state,
        // };
        case SIGN_UP_FAILURE:
            return {
                ...state,
            };



        // For GET_USER_PROFILE
        case GET_USER_PROFILE_REQUEST:
            return {
                ...state,
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state, profileData: payload?.data || null,
            };
        case GET_USER_PROFILE_FAILURE:
            return {
                ...state, profileData: null
            };
        case GET_FROFILE_UPDATE_SUCCESS:
            return {
                ...state, userData: payload?.data || null,
                profileData: payload?.data || null,
                SignIn: true, signin_Loader: false,
                loginToken: payload?.data?.api_token || '', GuestId: null,
                loginFirstTime: payload?.responseData?.user_data?.is_logged || null,
                loginType: payload?.responseData?.user_data?.loginType || '',
                userCurrency: payload?.responseData?.user_data?.currency_data || null
            };



        // GET_LIBRARY
        case GET_LIBRARY_REQUEST:
            return {
                ...state,
            };
        case GET_LIBRARY_SUCCESS:
            return {
                ...state, librarydata: payload?.data || null,
            };
        case GET_LIBRARY_FAILURE:
            return {
                ...state,
            };

        // GET_LIBRARY
        case GET_POST_UPDATE_REQUEST:
            return {
                ...state,
            };
        case GET_POST_UPDATE_SUCCESS:
            return {
                ...state, updatepost: payload?.data || null,
            };
        case GET_POST_UPDATE_FAILURE:
            return {
                ...state,
            };

        // GET_BLOG_LIST
        case GET_BLOG_LIST_REQUEST:
            return {
                ...state,
            };
        case GET_BLOG_LIST_SUCCESS:
            return {
                ...state, blogpostlist: payload?.data || null,
            };
        case GET_BLOG_CATEGORY_SUCCESS:
            return {
                ...state, blogpostlist: payload?.data || null,
            };
        case GET_CATEGORY_DETAILS_SUCCESS:
            return {
                ...state, categoryDetailData: payload?.data || null,
            };
        case GET_BLOG_LIST_FAILURE:
            return {
                ...state,
            };



        // For GET_INTREST
        case GET_INTREST_ADD_REQUEST:
            return {
                ...state,
            };
        case GET_INTREST_ADD_SUCCESS:
            return {
                ...state, intrestpass: payload?.data || null,
            };
        case GET_INTREST_ADD_FAILURE:
            return {
                ...state, intrestpass: null
            };

        // For OTHER_USER_PROFILE
        case GET_ALL_USERS_REQUEST:
            return {
                ...state,
                signin_Loader: true
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                alluserpostdatalist: payload?.data || [],
                signin_Loader: false
            };
        case GET_ALL_USERS_FAILURE:
            return {
                ...state,
                signin_Loader: false
            };

       



        // Redux reducer
        // case FOLLOW_USER_SUCCESS:
        //     return {
        //         ...state,
        //         followedUsers: [...state.followedUsers, payload.userId],
        //         userCount: state.userCount + 1 // Increment user count after follow
        //     };

        // case UNFOLLOW_USER_SUCCESS:
        //     return {
        //         ...state,
        //         followedUsers: state.followedUsers.filter(userId => userId !== payload.userId),
        //         userCount: state.userCount - 1 // Decrement user count after unfollow
        //     };

        // For following a user

        // case FOLLOW_USER_SUCCESS:
        //     return {
        //         ...state,
        //         followedUsers: [...state.followedUsers, payload.userId]
        //     };

        // case UNFOLLOW_USER_SUCCESS:
        //     return {
        //         ...state,
        //         followedUsers: state.followedUsers.filter(id => id !== payload.userId)
        //     };

        case 'UPDATE_ANALYTICS':
            return {
                ...state,
                analyticsData: payload
            };

        case MODERATOR_SIGN_UP_SUCCESS:
            return {
                ...state, userData: payload?.data || null,
                profileData: payload?.data || null,
                SignIn: true, signin_Loader: false,
                loginToken: payload?.data?.api_token || '', GuestId: null,
                loginFirstTime: payload?.responseData?.user_data?.is_logged || null,
                loginType: payload?.responseData?.user_data?.loginType || '',
                userCurrency: payload?.responseData?.user_data?.currency_data || null
            };

        default:
            return state
    }
}