import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa6";
import Tabs from './customTabs ';
import { MdLegendToggle } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaSun, FaMoon } from 'react-icons/fa';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getNotification, setSearchQuery, toggleTheme } from '../redux/actions/action-creator';
import Search from '../pages/Search/Search';
import Searchoption from '../pages/Search/Search';

const Header = ({ theme, toggleTheme, toggleSidebar, searchQuery, handleSearchInputChange, }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { userData = null, } = useSelector(state => state.auth);
  const { notificationlist = [], } = useSelector(state => state.common);
  const [notification, setnotification] = useState(false);
  const toggleCollapse = () => { setnotification(!notification); };
  const [notioppo, setnotioppo] = useState(false);
  const togglenoti = () => { setnotioppo(!notioppo); };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);
  const [isClassVisible, setClassVisibility] = useState(false);


  const toggleClass = () => {
    setClassVisibility(!isClassVisible);
  };
  const handleClick = () => {
    togglenoti();
    toggleCollapse();
  };

  const themtogglebutton = () => {
    toggleTheme();
    const body = document.body;
    body.classList.toggle('dark-theme');
    body.classList.toggle('light-theme');
  };

  useEffect(() => {
    // console.log(userData);
    API_Function();
  }, []);
  const API_Function = () => {
    dispatch(getNotification())
      .then(response => {
        if (response?.success) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };


  return (
    <>
      <header>
        {/* <OwlSlider /> */}
        <div className='Logo_header'>
          <NavLink to='/'>
            <svg width="38" height="46" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9136 26.4984H16.2192L37.2093 0.331299H0.816284V45.6944L11.888 31.8804V26.4984H11.9136ZM11.9136 11.9412H27.906L25.2918 15.196H11.9136V11.9412Z" fill="#0D0D0D" />
            </svg>
          </NavLink>
        </div>

        <Searchoption searchQuery={searchQuery} handleSearchInputChange={handleSearchInputChange} />
         

        

        <div className='user_header'>
          <ul>

            {/* <li className='header_scroll'>
              <NavLink onClick={themtogglebutton}> {theme === 'dark' ? <FaSun /> : <FaMoon />}</NavLink>
            </li> */}

            {userData?.type === 'moderator' ?
              <li className='header_scroll' onClick={openPopup}>
                <NavLink >
                  {
                    theme === "dark" ? (
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.870968 13.9634C1.10196 13.9634 1.3235 14.0518 1.48684 14.2093C1.65017 14.3667 1.74194 14.5802 1.74194 14.8028V17.0413C1.74194 17.1958 1.872 17.3211 2.03226 17.3211H15.9677C16.0447 17.3211 16.1186 17.2917 16.173 17.2392C16.2275 17.1867 16.2581 17.1155 16.2581 17.0413V14.8028C16.2581 14.5802 16.3498 14.3667 16.5132 14.2093C16.6765 14.0518 16.898 13.9634 17.129 13.9634C17.36 13.9634 17.5816 14.0518 17.7449 14.2093C17.9082 14.3667 18 14.5802 18 14.8028V17.0413C18 17.5608 17.7859 18.059 17.4048 18.4263C17.0236 18.7936 16.5067 19 15.9677 19H2.03226C1.49327 19 0.976357 18.7936 0.595235 18.4263C0.214112 18.059 0 17.5608 0 17.0413V14.8028C0 14.5802 0.0917623 14.3667 0.2551 14.2093C0.418439 14.0518 0.639973 13.9634 0.870968 13.9634ZM7.53445 13.8257C7.24625 13.826 6.96842 13.7221 6.7558 13.5346C6.54318 13.347 6.41121 13.0895 6.38593 12.8128C6.208 10.8514 6.17696 8.88014 6.29303 6.9144C6.00603 6.89892 5.71919 6.88102 5.43252 6.86068L3.70219 6.73868C3.5498 6.72792 3.40292 6.67921 3.27593 6.59732C3.14893 6.51543 3.04617 6.40317 2.97769 6.27152C2.90922 6.13987 2.87738 5.99334 2.8853 5.84627C2.89321 5.6992 2.9406 5.55664 3.02284 5.43252C4.256 3.57219 5.845 1.95488 7.70516 0.666776L8.39845 0.18662C8.57376 0.0652725 8.78416 0 9 0C9.21584 0 9.42624 0.0652725 9.60155 0.18662L10.2948 0.667895C12.1549 1.95569 13.7439 3.57261 14.9772 5.43252C15.0594 5.55664 15.1068 5.6992 15.1147 5.84627C15.1226 5.99334 15.0908 6.13987 15.0223 6.27152C14.9538 6.40317 14.8511 6.51543 14.7241 6.59732C14.5971 6.67921 14.4502 6.72792 14.2978 6.73868L12.5675 6.86068C12.2818 6.88082 11.995 6.89873 11.7081 6.91328C11.8243 8.87979 11.7917 10.8508 11.6129 12.8117C11.5879 13.0884 11.4562 13.346 11.2438 13.5338C11.0314 13.7215 10.7537 13.8257 10.4655 13.8257H7.53445Z" fill="white" />
                      </svg>
                    ) : (
                      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.870968 13.9634C1.10196 13.9634 1.3235 14.0518 1.48684 14.2093C1.65017 14.3667 1.74194 14.5802 1.74194 14.8028V17.0413C1.74194 17.1958 1.872 17.3211 2.03226 17.3211H15.9677C16.0447 17.3211 16.1186 17.2917 16.173 17.2392C16.2275 17.1867 16.2581 17.1155 16.2581 17.0413V14.8028C16.2581 14.5802 16.3498 14.3667 16.5132 14.2093C16.6765 14.0518 16.898 13.9634 17.129 13.9634C17.36 13.9634 17.5816 14.0518 17.7449 14.2093C17.9082 14.3667 18 14.5802 18 14.8028V17.0413C18 17.5608 17.7859 18.059 17.4048 18.4263C17.0236 18.7936 16.5067 19 15.9677 19H2.03226C1.49327 19 0.976357 18.7936 0.595235 18.4263C0.214112 18.059 0 17.5608 0 17.0413V14.8028C0 14.5802 0.0917623 14.3667 0.2551 14.2093C0.418439 14.0518 0.639973 13.9634 0.870968 13.9634ZM7.53445 13.8257C7.24625 13.826 6.96842 13.7221 6.7558 13.5346C6.54318 13.347 6.41121 13.0895 6.38593 12.8128C6.208 10.8514 6.17696 8.88014 6.29303 6.9144C6.00603 6.89892 5.71919 6.88102 5.43252 6.86068L3.70219 6.73868C3.5498 6.72792 3.40292 6.67921 3.27593 6.59732C3.14893 6.51543 3.04617 6.40317 2.97769 6.27152C2.90922 6.13987 2.87738 5.99334 2.8853 5.84627C2.89321 5.6992 2.9406 5.55664 3.02284 5.43252C4.256 3.57219 5.845 1.95488 7.70516 0.666776L8.39845 0.18662C8.57376 0.0652725 8.78416 0 9 0C9.21584 0 9.42624 0.0652725 9.60155 0.18662L10.2948 0.667895C12.1549 1.95569 13.7439 3.57261 14.9772 5.43252C15.0594 5.55664 15.1068 5.6992 15.1147 5.84627C15.1226 5.99334 15.0908 6.13987 15.0223 6.27152C14.9538 6.40317 14.8511 6.51543 14.7241 6.59732C14.5971 6.67921 14.4502 6.72792 14.2978 6.73868L12.5675 6.86068C12.2818 6.88082 11.995 6.89873 11.7081 6.91328C11.8243 8.87979 11.7917 10.8508 11.6129 12.8117C11.5879 13.0884 11.4562 13.346 11.2438 13.5338C11.0314 13.7215 10.7537 13.8257 10.4655 13.8257H7.53445Z" fill="#000" />
                      </svg>
                    )
                  }
                </NavLink>
              </li> : null
            }

            {userData ?
              <li className='header_scroll' onClick={togglenoti} >
                <NavLink >
                  {
                    theme === "dark" ? (
                      <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.09998 0.25C4.70442 0.25 2.07724 3.22603 2.49841 6.59536L2.57776 7.23021C2.69388 8.15916 2.35455 9.08706 1.66654 9.72195C0.240863 11.0375 -0.155232 13.1315 0.691418 14.8769L0.794769 15.09C1.54092 16.6282 3.05598 17.6356 4.74728 17.7409L4.93556 18.0636C5.94835 19.7998 7.52636 20.75 9.18339 20.75C10.8404 20.75 12.4184 19.7998 13.4312 18.0636L13.6225 17.7358C15.0431 17.6235 16.3385 16.8513 17.1111 15.6373C18.2932 13.7799 17.9801 11.3432 16.3668 9.84496L16.3229 9.8042C15.9565 9.46395 15.6824 9.04657 15.5145 8.59023C15.0699 8.85069 14.5524 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C14.2417 3 14.4768 3.02859 14.702 3.08258C13.7317 1.40121 11.9159 0.25 9.80289 0.25H8.09998ZM9.18339 19.25C8.26226 19.25 7.27948 18.7877 6.52013 17.75H11.8467C11.0873 18.7877 10.1045 19.25 9.18339 19.25Z" fill="white" />
                        <path className='noti_red_ssa' d="M16.1831 6C16.1831 7.10457 15.2877 8 14.1831 8C13.0785 8 12.1831 7.10457 12.1831 6C12.1831 4.89543 13.0785 4 14.1831 4C15.2877 4 16.1831 4.89543 16.1831 6Z" fill="#FF0000" />
                      </svg>
                    ) : (
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M11.1 1.25C7.70442 1.25 5.07724 4.22603 5.49841 7.59536L5.57776 8.23021C5.69388 9.15916 5.35455 10.0871 4.66654 10.7219C3.24086 12.0375 2.84477 14.1315 3.69142 15.8769L3.79477 16.09C4.54092 17.6282 6.05598 18.6356 7.74728 18.7409L7.93556 19.0636C8.94835 20.7998 10.5264 21.75 12.1834 21.75C13.8404 21.75 15.4184 20.7998 16.4312 19.0636L16.6225 18.7358C18.0431 18.6235 19.3385 17.8513 20.1111 16.6373C21.2932 14.7799 20.9801 12.3432 19.3668 10.845L19.3229 10.8042C18.9565 10.4639 18.6824 10.0466 18.5145 9.59023C18.0699 9.85069 17.5524 10 17 10C15.3431 10 14 8.65685 14 7C14 5.34315 15.3431 4 17 4C17.2417 4 17.4768 4.02859 17.702 4.08258C16.7317 2.40121 14.9159 1.25 12.8029 1.25H11.1ZM12.1834 20.25C11.2623 20.25 10.2795 19.7877 9.52013 18.75H14.8467C14.0873 19.7877 13.1045 20.25 12.1834 20.25Z"
                          fill="#000000" />
                        <path className='noti_alart_dod'
                          d="M19.1836 7C19.1836 8.10457 18.2882 9 17.1836 9C16.079 9 15.1836 8.10457 15.1836 7C15.1836 5.89543 16.079 5 17.1836 5C18.2882 5 19.1836 5.89543 19.1836 7Z"
                          fill="#FF0000" />
                      </svg>
                    )
                  }
                </NavLink>
              </li> : null
            }
            {/* <li className='user_img_header'>
              <NavLink to='/Profile'><img src={Assets.USER_site} alt='upload' /></NavLink>
            </li> */}
          </ul>
        </div>
        <div className='header_toggle' style={{ display: 'none' }} >
          <div className='responsive_notification'>
            <NavLink to='/Search'>
              {
                theme === 'dark' ?
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.1665 0.207031C4.67919 0.207031 1.0415 3.84472 1.0415 8.33203C1.0415 12.8193 4.67919 16.457 9.1665 16.457C13.6538 16.457 17.2915 12.8193 17.2915 8.33203C17.2915 3.84472 13.6538 0.207031 9.1665 0.207031ZM2.2915 8.33203C2.2915 4.53507 5.36955 1.45703 9.1665 1.45703C12.9635 1.45703 16.0415 4.53507 16.0415 8.33203C16.0415 12.129 12.9635 15.207 9.1665 15.207C5.36955 15.207 2.2915 12.129 2.2915 8.33203Z" fill="white" />
                    <path d="M16.2752 14.5568C16.0311 14.3128 15.6354 14.3128 15.3913 14.5568C15.1472 14.8009 15.1472 15.1966 15.3913 15.4407L18.7245 18.774C18.9686 19.0181 19.3643 19.0181 19.6084 18.774C19.8525 18.5299 19.8525 18.1342 19.6084 17.8901L16.2752 14.5568Z" fill="white" />
                  </svg>
                  :
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.1665 0.207031C4.67919 0.207031 1.0415 3.84472 1.0415 8.33203C1.0415 12.8193 4.67919 16.457 9.1665 16.457C13.6538 16.457 17.2915 12.8193 17.2915 8.33203C17.2915 3.84472 13.6538 0.207031 9.1665 0.207031ZM2.2915 8.33203C2.2915 4.53507 5.36955 1.45703 9.1665 1.45703C12.9635 1.45703 16.0415 4.53507 16.0415 8.33203C16.0415 12.129 12.9635 15.207 9.1665 15.207C5.36955 15.207 2.2915 12.129 2.2915 8.33203Z"
                      fill="#000000" />
                    <path
                      d="M16.2752 14.5568C16.0311 14.3128 15.6354 14.3128 15.3913 14.5568C15.1472 14.8009 15.1472 15.1966 15.3913 15.4407L18.7245 18.774C18.9686 19.0181 19.3643 19.0181 19.6084 18.774C19.8525 18.5299 19.8525 18.1342 19.6084 17.8901L16.2752 14.5568Z"
                      fill="#000000" />
                  </svg>
              }
            </NavLink>
          </div>

          <div className='responsive_notification'  >
            <NavLink onClick={openPopup}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.870968 13.9634C1.10196 13.9634 1.3235 14.0518 1.48684 14.2093C1.65017 14.3667 1.74194 14.5802 1.74194 14.8028V17.0413C1.74194 17.1958 1.872 17.3211 2.03226 17.3211H15.9677C16.0447 17.3211 16.1186 17.2917 16.173 17.2392C16.2275 17.1867 16.2581 17.1155 16.2581 17.0413V14.8028C16.2581 14.5802 16.3498 14.3667 16.5132 14.2093C16.6765 14.0518 16.898 13.9634 17.129 13.9634C17.36 13.9634 17.5816 14.0518 17.7449 14.2093C17.9082 14.3667 18 14.5802 18 14.8028V17.0413C18 17.5608 17.7859 18.059 17.4048 18.4263C17.0236 18.7936 16.5067 19 15.9677 19H2.03226C1.49327 19 0.976357 18.7936 0.595235 18.4263C0.214112 18.059 0 17.5608 0 17.0413V14.8028C0 14.5802 0.0917623 14.3667 0.2551 14.2093C0.418439 14.0518 0.639973 13.9634 0.870968 13.9634ZM7.53445 13.8257C7.24625 13.826 6.96842 13.7221 6.7558 13.5346C6.54318 13.347 6.41121 13.0895 6.38593 12.8128C6.208 10.8514 6.17696 8.88014 6.29303 6.9144C6.00603 6.89892 5.71919 6.88102 5.43252 6.86068L3.70219 6.73868C3.5498 6.72792 3.40292 6.67921 3.27593 6.59732C3.14893 6.51543 3.04617 6.40317 2.97769 6.27152C2.90922 6.13987 2.87738 5.99334 2.8853 5.84627C2.89321 5.6992 2.9406 5.55664 3.02284 5.43252C4.256 3.57219 5.845 1.95488 7.70516 0.666776L8.39845 0.18662C8.57376 0.0652725 8.78416 0 9 0C9.21584 0 9.42624 0.0652725 9.60155 0.18662L10.2948 0.667895C12.1549 1.95569 13.7439 3.57261 14.9772 5.43252C15.0594 5.55664 15.1068 5.6992 15.1147 5.84627C15.1226 5.99334 15.0908 6.13987 15.0223 6.27152C14.9538 6.40317 14.8511 6.51543 14.7241 6.59732C14.5971 6.67921 14.4502 6.72792 14.2978 6.73868L12.5675 6.86068C12.2818 6.88082 11.995 6.89873 11.7081 6.91328C11.8243 8.87979 11.7917 10.8508 11.6129 12.8117C11.5879 13.0884 11.4562 13.346 11.2438 13.5338C11.0314 13.7215 10.7537 13.8257 10.4655 13.8257H7.53445Z" fill="white" />
              </svg>

            </NavLink>
          </div>
          {userData?.type === 'moderator' ? (
            <>
              <div className='responsive_notification'>
                <NavLink onClick={togglenoti}>
                  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.09998 0.25C4.70442 0.25 2.07724 3.22603 2.49841 6.59536L2.57776 7.23021C2.69388 8.15916 2.35455 9.08706 1.66654 9.72195C0.240863 11.0375 -0.155232 13.1315 0.691418 14.8769L0.794769 15.09C1.54092 16.6282 3.05598 17.6356 4.74728 17.7409L4.93556 18.0636C5.94835 19.7998 7.52636 20.75 9.18339 20.75C10.8404 20.75 12.4184 19.7998 13.4312 18.0636L13.6225 17.7358C15.0431 17.6235 16.3385 16.8513 17.1111 15.6373C18.2932 13.7799 17.9801 11.3432 16.3668 9.84496L16.3229 9.8042C15.9565 9.46395 15.6824 9.04657 15.5145 8.59023C15.0699 8.85069 14.5524 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C14.2417 3 14.4768 3.02859 14.702 3.08258C13.7317 1.40121 11.9159 0.25 9.80289 0.25H8.09998ZM9.18339 19.25C8.26226 19.25 7.27948 18.7877 6.52013 17.75H11.8467C11.0873 18.7877 10.1045 19.25 9.18339 19.25Z" fill="white" />
                    <path className='noti_red_ssa' d="M16.1831 6C16.1831 7.10457 15.2877 8 14.1831 8C13.0785 8 12.1831 7.10457 12.1831 6C12.1831 4.89543 13.0785 4 14.1831 4C15.2877 4 16.1831 4.89543 16.1831 6Z" fill="#FF0000" />
                  </svg>
                </NavLink>
              </div>
              <div className='responsive_bar_toggle'>
                <MdLegendToggle onClick={toggleSidebar} />
              </div>
            </>
          ) : null}
        </div>
        <div className={`Notifications_header ${notioppo ? 'Notiopen' : 'Noticloce'}`}>
          <div className='header_noti_flex'>
            <h3>Notifications</h3>
            < IoCloseCircleOutline onClick={handleClick} />
          </div>
          <div className={`noti_widhr ${notification ? 'open' : 'cloce'}`}>
            {
              Array.isArray(notificationlist) && notificationlist.length > 0 ? (
                notificationlist.map((item, index) => (
                  <li className='heade_noti' key={index}>
                    <div className='heade_not_img'>
                      <img src={item.linked_user_profile} alt="" />
                    </div>
                    <div className='header_noti_cont'>
                      <span className='time_noti_header'>{item.updated_at_formatted}</span>
                      <p>{item.message}</p>
                    </div>
                  </li>
                ))
              ) : (
                <p>No notifications found</p>
              )
            }
            <div onClick={toggleCollapse} className='dowon_arrow_not'>View all <FaAngleDown /></div>
          </div>

          {/* <div className={`noti_widhr ${notification ? 'open' : 'cloce'}`}>
            {
              notificationlist?.map((item, index) => {
                return (
                  <li className='heade_noti' key={index}>
                    <div className='heade_not_img'>
                      <img src={item.img} alt="" />
                    </div>
                    <div className='header_noti_cont'>
                      <span className='time_noti_header'>{item.created_at}</span>
                      <p>{item.message}</p>
                    </div>
                  </li>
                )
              })
            }
            <div onClick={toggleCollapse} className='dowon_arrow_not'>View all <FaAngleDown /></div>
          </div> */}
        </div>
      </header >

      {isPopupOpen && (
        <div className="custom-popup">
          <div className="custom-popup-content">
            <Tabs closePopup={closePopup} />
            {/* <div className='close_button' onClick={closePopup}><IoIosClose /></div> */}
          </div>
        </div>
      )
      }

    </>
  )
}

const mapStateToProps = (state) => ({
  theme: state.common.theme // Accessing theme state from common reducer
});

const mapDispatchToProps = {
  toggleTheme // Dispatching toggleTheme action
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
