// AppContainer.js
import React, { useState } from 'react';
import '../assets/css/style.css';
import '../assets/css/sitebar.css';
import '../assets/css/responsive.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from '../view/customHeader';
import Sitebar from '../components/Sitebar';
import Home from '../pages/Home';
import FeaturedDetails from '../pages/FeaturedDetails';
import Login from '../pages/Login';
import Forgotpassword from '../pages/Forgotpassword';
import OtpScreen from '../pages/OtpScreen';
import CreatePssword from '../pages/CreatePssword';
import Signup from '../pages/Signup';
import SingUpSuccessfully from '../pages/SingUpSuccessfully';
import Interest from '../pages/Interest';
import Library from '../pages/Library';
import Profile from '../pages/Profile';
import Comment from '../pages/DescriptionDetails/Comment';
import Moderator from '../pages/Moderator';
import Setting from '../pages/Setting';
import CategoryPost from '../pages/CategoryPost';
import SearchResult from '../pages/Search/SearchResult';
import Search from '../pages/Search';
import UserLink from '../pages/Userlink';
import NotFound from '../pages/NotFound ';

const AppContainer = ({ notificationToken }) => {
    const [theme, setTheme] = useState("dark");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [googleData, setGoogleData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
         
    };

    return (
        <BrowserRouter>
            <Header theme={theme} toggleSidebar={toggleSidebar} searchQuery={searchQuery} handleSearchInputChange={handleSearchInputChange} />
            <Sitebar googleData={googleData} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} >
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='/feeds' element={<FeaturedDetails />} />
                    <Route path='/Login' element={<Login setGoogleData={setGoogleData} notificationToken={notificationToken} />} />
                    <Route path='/Signup' element={<Signup />} />
                    <Route path='/Forgotpassword' element={<Forgotpassword />} />
                    <Route path='/OtpScreen' element={<OtpScreen />} />
                    <Route path='/CreatePssword' element={<CreatePssword />} />
                    <Route path='/SingUpSuccessfully' element={<SingUpSuccessfully />} />
                    <Route path='/Interest' element={<Interest />} />
                    <Route path='/Library' element={<Library />} />
                    <Route path='/Profile/:id' element={<Profile googleData={googleData} />} />
                    <Route path="/post-detail/:id" element={<Comment />} />
                    <Route path='/Search' element={<Search />} />
                    <Route path='/Moderator' element={<Moderator />} />
                    <Route path='/Setting' element={<Setting googleData={googleData} />} />
                    <Route path='/category/:id' element={<CategoryPost />} />
                    <Route path='/UserLink' element={<UserLink />} />
                    {/* Catch-all route for 404 errors */}
                    {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
            </Sitebar>
        </BrowserRouter>
    );
};

export default AppContainer;
