import React, { useEffect, useState } from 'react'
import { Colors } from '../color'
import Categories from '../components/Categories';
import { Link, NavLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from 'react-redux';
import { getBlogDetails, getallUserProfile, getpostlist, likeViewsBookmark } from '../redux/actions/action-creator';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Spinner4 from '../view/customSpinner/Spinner4';
import Spinner3 from '../view/customSpinner/Spinner3';
import errorImg from '../assets/images/Featured.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const Home = ({  }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { homeCategryList = [] } = useSelector(state => state.common);
    const homeSlider1 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                }
            }
        ]
    };

    const homeSlider2 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                }
            }
        ]
    };
    const homeSlider3 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1.5,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.5,
                }
            }
        ]
    };

    

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //API_Function();
        getPostsAPI()
    }, []);

    const API_Function = (id) => {
        dispatch(getBlogDetails(id))
            .catch(error => {
                console.error("Error fetching blog details:", error);
            })
            .then(blogResponse => {
                const formData = new FormData();
                if (id) {
                    formData.append('type', 'view');
                    formData.append('blog_ids[]', id || '');
                }
                setIsLoading(true);
                return dispatch(likeViewsBookmark(formData))
                    .then(likeViewsResponse => {
                        setIsLoading(false);
                        console.log(likeViewsResponse);
                        const updatedBlogData = { ...blogResponse.data, is_liked: true }; // Assuming the response structure and adding a 'liked' flag
                        // Update state with the updated blog data
                        // setBlogData(updatedBlogData);
                        navigate('/post-detail/' + id, { state: { blogs_data: updatedBlogData } });
                    })
                    .catch(error => {
                        setIsLoading(false);
                        toast.error(error?.response?.data?.message || 'Failed');
                    });
            });
        // const formData = new FormData();
        // if (id) {
        //     formData.append('type', 'view');
        //     formData.append('blog_ids[]', id || '');
        // }
        // setIsLoading(true);
        //     dispatch(likeViewsBookmark(formData))
        //         .then(response => {
        //             setIsLoading(false);
        //             console.log(response);
        //             navigate('/comment');
        //             // if (response?.success) {
        //             //     // toast.success(response?.message || 'Success');
        //             // } else {
        //             //     toast.error(response?.response?.data?.message || 'Retry again');
        //             // }
        //         })
        //         .catch(error => {
        //             setIsLoading(false);
        //             toast.error(error?.response?.data?.message || 'Failed');
        //         });
    };
    const getPostsAPI = () => {
        dispatch(getpostlist())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    // console.log('homeCategryList', homeCategryList)

    const AllUSER_Function = (item) => {
        let formData = new FormData();
        formData.append('user_id', item.user_id || "");

        dispatch(getallUserProfile(formData))
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                    toast.success(response.message || 'Success!');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Failed!');
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Failed!');
            });
    };

    return (
        <>
            <Categories />

            <ToastContainer />
            <style>{`
               .main_container.responsiveClose main {
                padding: 0 50px;
            }
            `}</style>

            {/* <Spinner3 /> */}
            <div className='Featured_Category' style={{ display: 'none' }} >
                {homeCategryList?.cat_data?.map((category, categoryIndex) => (
                    <div key={categoryIndex}>
                        <div className='Featured_Category_heading_flex'>
                            <div>
                                <h2>{category.name ? category.name : <Skeleton width={200} />} {/* Skeleton for category name */}
                                    <span className='resp_buton_wer' style={{ display: 'none' }}>
                                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER }}>
                                            <NavLink to='/feeds'>
                                                <span>View all </span>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                                    <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </NavLink>
                                        </button>
                                    </span>
                                </h2>
                                <p>{category?.description || ''}</p>
                            </div>
                            <div className='testop_buton_wer'>
                                <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER }}>
                                    <NavLink to={`/category/${category.id}`}>
                                        <span>View all</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                            <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                        <div className='Featured_Category_grid'>
                            {isLoading ? (
                                <Spinner3 />
                            ) : (category?.blogs_data ? (
                                <Slider {...homeSlider2} className='homeslider  responsive_home_slider_2'>
                                    {category.blogs_data.map((item, index) => (
                                        <Link className='Category_card' >
                                            <Link 
                                            // to={{
                                            //     pathname: `/comment`,
                                            //     state: { blogs_data: item }
                                            // }}
                                                

                                                key={index}>
                                                <img
                                                    src={item?.thumbnail_image || errorImg}
                                                    onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                    alt={item.title}
                                                    onClick={() => API_Function(item.id)}
                                                />
                                            </Link>

                                            <div className='Category_card_post_flex'>
                                                <div className='Category_img_grid'>
                                                    <Link to={{
                                                        pathname: `/Profile/${item.user_id}`,
                                                        state: { blogs_data: item }
                                                    }}
                                                        onClick={() => AllUSER_Function(item)}>
                                                        <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                    </Link>
                                                </div>
                                                <div>
                                                    <h2>{item.title}</h2>
                                                    <p>
                                                        <span>{item.username}</span>
                                                        <span>{item.view_count} views</span>
                                                    </p>
                                                </div>
                                                <ul>
                                                    {item.tags.length > 0 ? (
                                                        item.tags.map((tag, tagIndex) => (
                                                            <li key={tagIndex}>{tag.tag_name}</li>
                                                        ))
                                                    ) : (
                                                        null
                                                    )}
                                                </ul>
                                            </div>
                                        </Link>
                                    ))}

                                </Slider>
                            ) : null
                            )}
                            {/* {categoryIndex === 0 ? (
                                isLoading ? (
                                    <Spinner4 />
                                ) : (
                                    
                                )
                            ) : categoryIndex === 1 ? (
                                isLoading ? (
                                    <Spinner3 />
                                ) : (
                                    category?.blogs_data ? (
                                        <Slider {...homeSlider1} className=' homeslider tred_slider_home responsive_home_slider'>
                                            {category.blogs_data.map((item, index) => (
                                                <Link
                                                    to={{ pathname: `/comment` }} // Pass the blog ID as part of the pathname
                                                    onClick={() => API_Function(item.id)}
                                                    className='Category_card'
                                                    key={index}
                                                >
                                                    <img
                                                        src={item?.thumbnail_image || errorImg}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                        alt={item.title}
                                                    />
                                                    <div className='Category_card_post_flex'>
                                                        <div className='Category_img_grid'>
                                                            <img src={item?.userprofile} alt={`${item.username}' profile`} />
                                                        </div>
                                                        <div>
                                                            <h2>{item.title}</h2>
                                                            <p>
                                                                <span>{item.username}</span>
                                                                <span>{item.view_count} views</span>
                                                            </p>
                                                        </div>
                                                        <ul>
                                                            {item.tags.length > 0 ? (
                                                                item.tags.map((tag, tagIndex) => (
                                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                                ))
                                                            ) : (
                                                                <li>No tags available</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    ) : null
                                )
                            ) : (
                                isLoading ? (
                                    <Spinner3 />
                                ) : (
                                    category?.blogs_data ? (
                                        <Slider {...homeSlider1} className='homeslider responsive_home_slider'>
                                            {category.blogs_data.map((item, index) => (
                                                <Link
                                                    to={{
                                                        pathname: `/comment`,
                                                        state: { blogs_data: item }
                                                    }}
                                                    onClick={() => API_Function(item.id)}
                                                    className='Category_card'
                                                    key={index}
                                                >
                                                    <img
                                                        src={item?.thumbnail_image || errorImg}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                        alt={item.title}
                                                    />
                                                    <div className='Category_card_post_flex'>
                                                        <div className='Category_img_grid'>
                                                            <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                        </div>
                                                        <div>
                                                            <h2>{item.title}</h2>
                                                            <p>
                                                                <span>{item.username}</span>
                                                                <span>{item.view_count} views</span>
                                                            </p>
                                                        </div>
                                                        <ul>
                                                            {item.tags.length > 0 ? (
                                                                item.tags.map((tag, tagIndex) => (
                                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                                ))
                                                            ) : (
                                                                <li>No tags available</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    ) : null

                                )
                            )} */}
                        </div>
                    </div>
                ))}
            </div >

            <div className='Featured_Category' >
                {homeCategryList?.cat_data?.map((category, categoryIndex) => (
                    <div key={categoryIndex} className='list'>
                        <div className='Featured_Category_heading_flex ss'>
                            <div>
                                <h2>{category.name ? category.name : <Skeleton width={200} />} {/* Skeleton for category name */}
                                    <span className='resp_buton_wer' style={{ display: 'none' }}>
                                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER }}>
                                            <NavLink to='/feeds'>
                                                <span>View all </span>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                                    <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </NavLink>
                                        </button>
                                    </span>
                                </h2>
                                <p>{category?.description || ''}</p>
                            </div>
                            <div className='testop_buton_wer'>
                                <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER }}>
                                    <NavLink to={`/category/${category.id}`}>
                                        <span>View all</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                            <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </NavLink>
                                </button>
                            </div>
                        </div>
                        <div className='Featured_Category_grid'>
                            {categoryIndex === 1 ? (
                                isLoading ? (
                                    <Spinner4 />
                                ) : (
                                    category?.blogs_data ? (
                                        <Slider {...homeSlider2} className='homeslider second_home_sleder responsive_home_slider_2'>
                                            {category.blogs_data.map((item, index) => (
                                                <Link className='Category_card s2' >
                                                    <Link
                                                        // to={{
                                                        //     pathname: `/comment`,
                                                        //     state: { blogs_data: item }
                                                        // }}
                                                        

                                                        key={index}
                                                    >
                                                        <img
                                                            src={item?.thumbnail_image || errorImg}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                            alt={item.title}
                                                            onClick={() => API_Function(item.id)}
                                                        />
                                                    </Link>
                                                    <div className='Category_card_post_flex'>
                                                        <div className='Category_img_grid'>
                                                            <Link to={{
                                                                pathname: `/Profile/${item.user_id}`,
                                                                state: { blogs_data: item }
                                                            }}
                                                                onClick={() => AllUSER_Function(item)}>
                                                                <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <h2>{item.title}</h2>
                                                            <p>
                                                                <span>{item.username}</span>
                                                                <span>{item.view_count} views</span>
                                                            </p>
                                                        </div>
                                                        <ul>
                                                            {item.tags.length > 0 ? (
                                                                item.tags.map((tag, tagIndex) => (
                                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    ) : null
                                )
                            ) : categoryIndex === 2 ? (
                                isLoading ? (
                                    <Spinner3 />
                                ) : (
                                    category?.blogs_data ? (
                                        <Slider {...homeSlider3} className='homeslider pp 456 tred_slider_home responsive_home_slider'>
                                            {category.blogs_data.map((item, index) => (
                                                <Link className='Category_card s3' >
                                                    <Link
                                                        // to={{
                                                        //     pathname: `/comment`,
                                                        //     state: { blogs_data: item }
                                                        // }}
                                                        

                                                        key={index} >
                                                        <img
                                                            src={item?.thumbnail_image || errorImg}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                            alt={item.title}
                                                            onClick={() => API_Function(item.id)}
                                                        />
                                                    </Link>

                                                    <div className='Category_card_post_flex'>
                                                        <div className='Category_img_grid'>
                                                            <Link to={{
                                                                pathname: `/Profile/${item.user_id}`,
                                                                state: { blogs_data: item }
                                                            }}
                                                                onClick={() => AllUSER_Function(item)}>
                                                                <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <h2>{item.title}</h2>
                                                            <p>
                                                                <span>{item.username}</span>
                                                                <span>{item.view_count} views</span>
                                                            </p>
                                                        </div>
                                                        <ul>
                                                            {item.tags.length > 0 ? (
                                                                item.tags.map((tag, tagIndex) => (
                                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    ) : null

                                )
                            ) : (
                                isLoading ? (
                                    <Spinner3 />
                                ) : (
                                    category?.blogs_data ? (
                                        <Slider {...homeSlider1} className='homeslider 789 responsive_home_slider'>
                                            {category.blogs_data.map((item, index) => (
                                                <Link className='Category_card s1 '
                                                >
                                                    <Link
                                                        // to={{
                                                        //     pathname: `/comment`,
                                                        //     state: { blogs_data: item }
                                                        // }}
                                                        

                                                        key={index} >
                                                        <img
                                                            src={item?.thumbnail_image || errorImg}
                                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                            alt={item.title}
                                                            onClick={() => API_Function(item.id)}
                                                        />
                                                    </Link>

                                                    <div className='Category_card_post_flex'>
                                                        <div className='Category_img_grid'>
                                                            <Link to={{
                                                                pathname: `/Profile/${item.user_id}`,
                                                                state: { blogs_data: item }
                                                            }}
                                                                onClick={() => AllUSER_Function(item)}>
                                                                <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <h2>{item.title}</h2>
                                                            <p>
                                                                <span>{item.username}</span>
                                                                <span>{item.view_count} views</span>
                                                            </p>
                                                        </div>
                                                        <ul>
                                                            {item.tags.length > 0 ? (
                                                                item.tags.map((tag, tagIndex) => (
                                                                    <li key={tagIndex}>{tag.tag_name}</li>
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </ul>
                                                    </div>
                                                </Link>
                                            ))}
                                        </Slider>
                                    ) : null

                                )
                            )}
                        </div>
                    </div>
                ))}
            </div >


        </>
    )
}

export default Home
