import React, { useState } from 'react'
import { EMAIL_CHECK } from '../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { forgetPassword } from '../redux/actions/action-creator';
import { Assets } from '../assets';
import { useNavigate } from 'react-router-dom';

const Forgotpassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData = null, } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [forgetPasswordForm, setForgetPasswordForm] = useState({
        email: userData?.email || '',
    });

    const SendOTP_Function = () => {
        const { email = '' } = forgetPasswordForm;
        let formData = new FormData();
        formData.append('email', email || '');

        if (EMAIL_CHECK.test(email)) {
            setIsLoading(true);
            dispatch(forgetPassword(formData)).then((response) => {
                if (response.success) {
                    setIsLoading(false);
                    toast.error(response?.message || 'Password reset email sent!');
                    console.log(response?.data?.email);
                    navigate('/OtpScreen', { state: { email: response?.data?.email } });
                    // navigate('/OtpScreen${encodeURIComponent(email)}');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Failed to send password reset email.');
                }
            }).catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Failed to send password reset email.');
            });
        } else {
            toast.error('Enter email');
        }
    };

    // console.log('-----123_', forgetPasswordForm)

    return (
        <div className='Login_screen'>
            <div className="Login_screen_con">
                <div className='login_fron_comaq'>
                    <ToastContainer />
                    <h1>Forgot password</h1>
                    <div className='login_fron'>
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="email"
                                placeholder='Enter your email'
                                value={forgetPasswordForm.email}
                                onChange={(e) => setForgetPasswordForm({ ...forgetPasswordForm, email: e.target.value })}
                            />
                        </div>
                    </div>
                    <button className='logi_in_button' onClick={SendOTP_Function} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Submit'}
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Forgotpassword
