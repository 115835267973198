import React, { useEffect, useState } from 'react';
import AppContainer from './navigations';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { messaging } from "./Firebase";
import { getToken } from "firebase/messaging";

const App = () => {
    const [notificationToken, setNotificationToken] = useState(null);

    async function requestPermission() {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                const token = await getToken(messaging, { vapidKey: 'BMIry8AlAfx1bgrirZwT-JXdJbsyyAb0IygH0_IqNZdqYXxD3KOpOdbTLBYmZH-QZjsy73d_I43_J7uieptb1rs' });
                setNotificationToken(token); // Set the token in the state
            } else if (permission === 'denied') {
                alert('You denied for the notification');
            }
        } catch (error) {
            console.error('Error requesting permission:', error);
        }
    }

    useEffect(() => {
        requestPermission();
    }, []);

    return (
        <Provider store={store}>
            <GoogleOAuthProvider clientId="167208473885-7qs4nkvjogtvjaipe9j1nnpa9k7c20ge.apps.googleusercontent.com">
                <AppContainer notificationToken={notificationToken} /> {/* Pass notificationToken as a prop */}
            </GoogleOAuthProvider>
        </Provider>
    );
};

export default App;
