import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLibrary, getBlogCategory } from '../redux/actions/action-creator';
import Libraryspinner from '../view/customSpinner/Libraryspinner';
import { Assets } from '../assets';

const Library = () => {
    const dispatch = useDispatch();
    const { librarydata = [] } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(true);
    const { blogpostlist = [] } = useSelector(state => state.auth);

    useEffect(() => {
        API_Function();
    }, []);

    const API_Function = () => {
        dispatch(getLibrary())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const POST_Function = (id) => {
        dispatch(getBlogCategory(id))
          .then(response => {
            if (response?.success) {
              console.log(blogpostlist);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(error => {
            setIsLoading(false);
          });
      }

    // console.log('Library API check screen ', librarydata);

    return (
        <div className='Featured_Category library' style={{ border: 'none' }}>
            <h1 className='libreat_heading'>Library</h1>
            <div className='librery_grid'>
                {isLoading ? (
                    <Libraryspinner librarydata={librarydata} />
                ) : (
                    librarydata.map((item, index) => (
                        <div className='librery_cart' key={index}>
                            <Link
                                to={{
                                pathname: `/post-detail/${item.id}`,
                                state: { blogs_data: item }
                                }}
                                onClick={() => POST_Function(item.id)}
                            >
                                {item.thumbnail_image!=='' ? (
                                        <img src={item.thumbnail_image} alt={`Thumbnailh ${index}`} />
                                    ) : (
                                        <img src={Assets.NOT_FOUND_IMG} alt={`Thumbnailn ${index}`} />
                                    )}
                            </Link>
                            
                            <div className='Category_card_post_flex'>
                                {item.userprofile && (
                                    <div className='Category_img_grid'>
                                        <img src={item.userprofile} alt={`Profile ${index}`} />
                                    </div>
                                )}
                                <div>
                                    <h2>{item.title}</h2>
                                    <p>
                                        <span>{item.username}</span>
                                        <span>{item.view_count} views</span>
                                    </p>
                                </div>
                            </div>
                            <ul className='cart_blog'>
                                {item.tags.map((tag, tagIndex) => (
                                    <li key={tagIndex}>{tag.tag_name}</li>
                                ))}
                            </ul>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Library;
