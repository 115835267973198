export const Colors = {

    // Theme Text Colors
    TEXT_BLECK: '#000',
    TEXT_WHITE: '#fff',
    TEXT_DARK: '#0D0D0D',

    // Theme Text Colors
    BACKGROUND_PRIMER: '#FFA200',
    BACKGROUND_DARK: '#2F3040',




}
