import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { verifyOTP, resendOtp } from '../redux/actions/action-creator';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Assets } from '../assets';
import { EMAIL_CHECK } from '../constants';

const OTPPage = () => {
    const location = useLocation();
    const email = location?.state?.email;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { otpValue } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const [isFilled, setIsFilled] = useState(false);

    const otpInputs = useRef([]);

    useEffect(() => {
        console.log(email);
        // Check if all OTP input fields are filled
        setIsFilled(otp.every(digit => digit !== ''));
    }, [otp]);

    const handleChange = (index, event) => {
        const newOTP = [...otp];
        newOTP[index] = event.target.value;
        setOTP(newOTP);

        // Move to the next input field automatically if a digit is entered
        if (event.target.value && index < 5) {
            otpInputs.current[index + 1].focus();
        }
    };

    const reSendOtp = () => {
        let formData = new FormData();
        formData.append('email', email || '');

        if (EMAIL_CHECK.test(email)) {
            setIsLoading(true);
            dispatch(resendOtp(formData)).then((response) => {
                if (response.success) {
                    setIsLoading(false);
                    toast.error(response?.message || 'Password reset email sent!');
                    console.log(response?.data?.email);
                    // navigate('/OtpScreen', { state: { email: response?.data?.email } });
                    // navigate('/OtpScreen${encodeURIComponent(email)}');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Failed to send password reset email.');
                }
            }).catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Failed to send password reset email.');
            });
        } else {
            toast.error('Enter email');
        }
      };

    // console.log('otpValue', otp)
    const onOTPSubmit = () => {
        const otpValue = otp.join('');
        let formData = new FormData();
        formData.append('otp', otpValue || '');

        if (otpValue.length === 6) { // Check if OTP is exactly 6 digits long
            setIsLoading(true);
            dispatch(verifyOTP(formData))
                .then(response => {
                    if (response?.success) {
                        setIsLoading(false);
                        setOTP(['', '', '', '', '', '']); // Clear the OTP fields upon successful verification
                        toast.success(response?.message || 'OTP verified successfully');
                        navigate('/CreatePssword', {
                            state: { otp: otpValue || '' }
                        });

                        // Redirect or perform any other action upon successful verification
                    } else {
                        setIsLoading(false);
                        toast.error(response?.message || 'Verification failed');
                    }
                })
                .catch(error => {
                    console.error('Error verifying OTP:', error);
                    setIsLoading(false);
                    toast.error('OTP is wrong');
                });
        } else {
            setIsLoading(false);
            toast.error('OTP should be exactly 6 digits');
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className='Login_screen'>
                <div className="Login_screen_con">
                    <div className='login_fron_comaq'>
                        <h1>OTP</h1>
                        <p className='otp_desc'>Please enter 6-digit verification code that was sent to your email{email}</p>
                        <div className='login_fron'>
                            <div className="logn_input  ">
                                <div className='OTP_screen'>
                                    {otp.map((digit, index) => (
                                        <input
                                            className='otp_input_box active'
                                            key={index}
                                            maxLength="1"
                                            type="text"
                                            pattern="[0-9]*"
                                            value={digit}
                                            onChange={e => handleChange(index, e)}
                                            ref={el => (otpInputs.current[index] = el)}
                                        />
                                    ))}
                                </div>
                                <p className='re_SEND_otp'>Didn’t receive an email? <span onClick={reSendOtp}>Resend</span> </p>
                            </div>
                        </div>

                        <button className='logi_in_button' onClick={onOTPSubmit} disabled={!isFilled || isLoading} style={{ opacity: isFilled ? 1 : 0.5 }}>
                            {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Continue'}
                        </button>
                    </div>
                </div>
            </div>
            {/* <button onClick={onOTPSubmit} disabled={!isFilled}>Submit OTP</button> */}
        </div >
    );
};

export default OTPPage;
