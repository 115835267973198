export const Assets = {
    MAin_img_site: require('../assets/images/Sitebar/main_site.png'),
    USER_site: require('../assets/images/Sitebar/user.png'),
    tOGGLE_2: require('../assets/images/Sitebar/toggle_Right_1.png'),
    SINGIP_SUCCS: require('../assets/images/SingUpSuccessfully.png'),
    POST_IMG: require('../assets/images/post_IMG2.png'),
    FEATUURED: require('../assets/images/Featured.png'),
    FEATUURED3: require('../assets/images/Featured3.png'),
    LINE_BAR: require('../assets/images/line_doded.png'),
    PROFILE_BANNER: require('../assets/images/profile_banner.png'),
    SHARE_SVG: require('../assets/images/comment_share.png'),
    BUTTON_LOAD: require('../assets/images/button_loading.gif'),
    COMMENT_BANEER: require('../assets/images/commenet.png'),
    FEEDODEMO: require('../assets/images/woman.jpg'),
    LOCAL_IMG: require('../assets/images/Local_img.png'),
    NOT_FOUND_IMG: require('../assets/images/not_found.jpg'),

}