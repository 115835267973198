import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Assets } from '../assets';
import { ReactComponent as PSEARCH } from '../assets/svg/Search.svg';
import { getLanguages, getUpdateProfile } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Language = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const { userData = null, profileData = null, loginToken = '' } = useSelector(state => state.auth);

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [languages, setLanguages] = useState([]);
    const [formData, setFormData] = useState({
        language_id: '' // Initial state for selected category IDs
      });
    const [searchTerm, setSearchTerm] = useState('');

    const filteredLanguages = languages.filter(language =>
        language.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        API_Function();
        console.log(profileData.language_id);
    }, []);

    useEffect(() => {
        if (profileData.language_id) {
            setFormData(prevFormData => ({
                ...prevFormData,
                language_id: profileData.language_id
            }));
        }
    }, [profileData]);

    const API_Function = () => {
        dispatch(getLanguages())
            .then(response => {
                if (response?.success) {
                    setLanguages(response.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    // const handleLanguageChange = (event) => {
    //     const { value } = event.target;
    //     console.log(value);
    //     setFormData({ language_id: value });
    //     console.log(formData.language_id);
    //     if (formData.language_id == '') {
    //         toast.error('Please select at least one language');
    //         return;
    //     }
    //     // const formData = new FormData();
        
    //     // formData.append('category_id[]', selectedSubcategories || '');
    //     setIsLoading(true);
    //     dispatch(getUpdateProfile(formData))
    //         .then(response => {
    //             if (response?.success) {
    //                 setIsLoading(false);
    //                 toast.success(response?.message || 'Success');
    //                 // navigate('/');
    //             } else {
    //                 setIsLoading(false);
    //                 toast.error(response?.response?.data?.message || 'Retry again');
    //             }
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //             toast.error(error?.response?.data?.message || 'Fail');
    //         });
    // };
    const handleLanguageChange = (event) => {
        const { value } = event.target;
        console.log(value);
    
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, language_id: value };
            console.log(updatedFormData.language_id);
    
            if (updatedFormData.language_id === '') {
                toast.error('Please select at least one language');
                return prevFormData; // Return previous state without updating
            }
    
            setIsLoading(true);
            dispatch(getUpdateProfile(updatedFormData))
                .then(response => {
                    setIsLoading(false);
                    if (response?.success) {
                        toast.success(response?.message || 'Success');
                        // navigate('/');
                    } else {
                        toast.error(response?.response?.data?.message || 'Retry again');
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error?.response?.data?.message || 'Fail');
                });
    
            return updatedFormData; // Return updated state
        });
    };  

    return (
            <div className='General_Settings_main_class'>
                <h2>Change Language</h2>
                <div className="General_setting_form">
                    <div className='language_search'>
                        <PSEARCH />
                        <input placeholder='Search language...' value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                    <div className='Language_row'>
                        {filteredLanguages.map((language, index) => (
                            <div key={index} className="radioContainer">
                                <label htmlFor={language.name}>{language.name}</label>
                                <input
                                    type="radio"
                                    id={language.name}
                                    name="language" 
                                    value={language.id}
                                    checked={formData.language_id==language.id} 
                                    onChange={handleLanguageChange}
                                />
                                <label htmlFor={language.name}></label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
    )
}

export default Language



