import React, { useEffect, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Tranding } from '../../constants/LocalData';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { Colors } from '../../color';
import { useDispatch, useSelector } from 'react-redux';
import { getAllpostdate, getallUserProfile, getBlogCategory} from '../../redux/actions/action-creator';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Libraryspinner from '../../view/customSpinner/Libraryspinner';
import errorImg from '../../assets/images/not_found.jpg';
import ProfileMyPost from './ProfileMyPost';
import { toast } from 'react-toastify';

const posSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};
const SavesSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};
const Taggedslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                infinite: true,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};


const ProfilePost = ({user_id}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData = null, } = useSelector(state => state.auth);
    const { allpostdate = [] } = useSelector(state => state.common)
    const [isLoading, setIsLoading] = useState(false);
    const [showPost, setShowPost] = useState(0);
    const [showPhotoData, setshowPhotoData] = useState([]);
    const [showTaggedPostData, setshowTaggedPostData] = useState([]);
    console.log('---------->',allpostdate)


    const post_type = 'tagged_post'; 
    const type = 'all'; 
   // const user_id = '66'; 

    useEffect(() => {
        API_Function(post_type, type, user_id);
    }, [post_type, type, user_id]);

    const API_Function = (post_type, type, user_id) => {
        dispatch(getAllpostdate(post_type, type, user_id))
            .then(response => {
                if (response?.success) {
                    setshowPhotoData(response?.data?.blog_data?.self_post);
                    setshowTaggedPostData(response?.data?.blog_data?.tagged_post_post);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };
    const profileMyPostData = (post) => {
        setShowPost(1);
        setshowPhotoData(post);
        console.log(showPhotoData);
        navigate('/my-post', { state: { blogs_data: post } });
        dispatch(getAllpostdate())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const profileTaggedPostData = (post) => {
        setShowPost(2);
         setshowTaggedPostData(post);
        navigate('/my-post', { state: { blogs_data: post } });
        dispatch(getAllpostdate())
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const profileSavedPostData = () => {
        setShowPost(0);
        navigate('/Library');
    };

    const backToPost = () => {
        setShowPost(0);
    };
    // console.log('allpostdate =============', allpostdate?.blog_data?.saved_post)

    const AllUSER_Function = (item) => {    
        let formData = new FormData();
        formData.append('user_id', item.user_id || "");

        dispatch(getallUserProfile(formData))
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                    toast.success(response.message || 'Success!');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Failed!');
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Failed!');
            });
    };


    const POST_Function = (id) => {
        dispatch(getBlogCategory(id))
          .then(response => {
            if (response?.success) {
              
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(error => {
            setIsLoading(false);
          });
      }


    return (
        <>
            {showPost === 0 ? (
                <>
                    <div className='post_Category '>
                        <div className='Featured_Category_heading_flex' style={{ marginTop: '0' }}>
                            <div>
                                <h2>Post</h2>
                            </div>
                            {/* {allpostdate?.blog_data?.self_post.length>3?
                    <div>
                        <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                            <span>View all</span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>:null} */}
                            {allpostdate?.blog_data?.self_post.length > 3 ?
                                <div>
                                    <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }} onClick={() => profileMyPostData(allpostdate?.blog_data?.self_post)}>
                                        <span>View all</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                            <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                : null}
                        </div>

                        <div className='posted_slider_grid'>
                            {allpostdate?.blog_data?.self_post.length > 0 ? isLoading ? (
                                <Slider {...posSlider}>
                                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                                        {Array.isArray(allpostdate?.blog_data?.self_post) && allpostdate?.blog_data?.self_post.map((item, index) => (
                                            <div className='Category_card' key={index}>
                                                <div className='Category_card_post_flex'>
                                                    <Skeleton width='100%' height={160} />
                                                    <div className='Category_img_grid'>
                                                        <Skeleton circle={true} width={40} height={40} />
                                                    </div>
                                                    <div>
                                                        <h2><Skeleton width={200} /></h2>
                                                        <p><Skeleton width={100} /></p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </SkeletonTheme>
                                </Slider>
                            ) : (
                                <Slider {...posSlider}>
                                    {allpostdate?.blog_data?.self_post.map((item, index) => (
                                        <div className='Category_card' key={index}>

                                 <Link
                                to={{
                                pathname: `/post-detail/${item.id}`,
                                state: { blogs_data: item }
                                }}
                                onClick={() => POST_Function(item.id)}
                                    >

                                            <img className='thumbnail_image'
                                                src={item?.thumbnail_image || errorImg}
                                                onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                alt={item.title}
                                            />
                                            </Link>
                                            <div className='Category_card_post_flex'>
                                                <div className='Category_img_grid'>
                                                    <Link to={{
                                                        pathname: `/Profile/${item.id}`,
                                                        state: { self_post: item }
                                                    }}
                                                        onClick={() => AllUSER_Function(item)}>
                                                        <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                    </Link>
                                                </div>

                                                <div>
                                                    <h2>{item.title}</h2>
                                                    <p>
                                                        <span>{item.username}</span>
                                                        <span>{item.view_count} views</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            ) : <div>
                                <p className='tagged_post_not_found'>Post not found</p>
                            </div>}
                        </div>
                    </div>
                    <div className='post_Category '>
                        <div className='Featured_Category_heading_flex'>
                            <div>
                                <h2>Saves</h2>
                            </div>
                            {allpostdate?.blog_data?.saved_post.length > 3 ?
                                <div>
                                    <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }} onClick={() => profileSavedPostData()}>
                                        <span>View all</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                            <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div> : null}
                        </div>
                        {allpostdate?.blog_data?.saved_post.length > 0 ? <div className='posted_slider_grid'>
                            <Slider {...SavesSlider}>
                                {allpostdate?.blog_data?.saved_post.map((item, index) => (
                                    <div className='Category_card' key={index}>

                                  <Link
                                to={{
                                pathname: `/post-detail/${item.id}`,
                                state: { blogs_data: item }
                                }}
                                onClick={() => POST_Function(item.id)}
                                    >
                                        <img className='thumbnail_image'
                                            src={item?.thumbnail_image || errorImg}
                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                            alt={item.title}
                                        />

                                        </Link>
                                        <div className='Category_card_post_flex'>
                                            <div className='Category_img_grid'>
                                                <Link to={{
                                                    pathname: `/Profile/${item.id}`,
                                                    state: { saved_post: item }
                                                }}
                                                    onClick={() => AllUSER_Function(item)}>
                                                    <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                </Link>
                                            </div>
                                            <div>
                                                <h2>{item.title}</h2>
                                                <p>
                                                    <span>{item.username}</span>
                                                    <span>{item.view_count} views</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div> :
                            <div>
                                <p className='tagged_post_not_found'>Saved post not found</p>
                            </div>}
                    </div>

                    <div className='post_Category ' style={{ border: 'none' }}>
                        <div className='Featured_Category_heading_flex'>
                            <div>
                                <h2>Tagged</h2>
                            </div>
                            {allpostdate?.blog_data?.tagged_post.length > 3 ?
                                <div>
                                    <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }} onClick={() => profileTaggedPostData(allpostdate?.blog_data?.tagged_post)}>
                                        <span>View all</span>
                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                            <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div> : null}
                        </div>
                        {allpostdate?.blog_data?.tagged_post.length > 0 ? (
                            <div className='posted_slider_grid'>
                                <Slider {...Taggedslider}>
                                    {allpostdate?.blog_data?.tagged_post.map((item, index) => (
                                        <div className='Category_card' key={index}>
                                            <img className='thumbnail_image'
                                                src={item?.thumbnail_image || errorImg}
                                                onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                                alt={item.title}
                                            />
                                            <div className='Category_card_post_flex'>
                                                <div className='Category_img_grid'>
                                                    <Link to={{
                                                        pathname: `/Profile/${item.id}`,
                                                        state: { tagged_post: item }
                                                    }}
                                                        onClick={() => AllUSER_Function(item)}>
                                                        <img src={item?.userprofile} alt={`${item.username}'s profile`} />
                                                    </Link>
                                                </div>
                                                <div>
                                                    <h2>{item.title || 'title name not available'}</h2>
                                                    <p>
                                                        <span>{item.username || 'User Name'}</span>
                                                        <span> {item.view_count} views</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) : (
                            <div>
                                <p className='tagged_post_not_found'>Tagged post not found</p>
                            </div>
                        )
                        }

                    </div >
                </>
            ) : showPost === 1 ? (
                <div className='post_Category  '>
                    <div className='Featured_Category_heading_flex' style={{ marginTop: '0' }}>
                        <div>
                            <h2>Post</h2>
                        </div>
                        <div>
                            <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }} onClick={() => backToPost()}>
                                <span>Back</span>
                                {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> */}
                            </button>
                        </div>
                    </div>
                    <div className='profile_tab_viedo'>
                        {(allpostdate?.blog_data?.self_post && allpostdate?.blog_data?.self_post.length > 0 ? (
                            allpostdate?.blog_data?.self_post?.map((item, index) => {

                                return (
                                    <div className='Category_card' key={index}>
                                        <img className='thumbnail_image'
                                            src={item?.thumbnail_image || errorImg}
                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                            alt={item.title}
                                        />
                                        <div className='Category_card_post_flex'>
                                            <div>
                                                <h2>{item.title || 'title name not available'}</h2>
                                                <p>
                                                    <span>{item.username || 'User Name'}</span>
                                                    <span> {item.view_count} views</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>
                                <p className='tagged_post_not_found'>Post not found</p>
                            </div>
                        )

                        )}

                    </div>
                </div >
            ) : showPost === 2 ? (
                <div className='post_Category  '>
                    <div className='Featured_Category_heading_flex' style={{ marginTop: '0' }}>
                        <div>
                            <h2>Tagged</h2>
                        </div>
                        <div>
                            <button className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }} onClick={() => backToPost()}>
                                <span>Back</span>
                                {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 11.75C3.80109 11.75 3.61032 11.829 3.46967 11.9697C3.32902 12.1103 3.25 12.3011 3.25 12.5C3.25 12.6989 3.32902 12.8897 3.46967 13.0303C3.61032 13.171 3.80109 13.25 4 13.25V11.75ZM4 13.25H20V11.75H4V13.25Z" fill="#0D0D0D" />
                                <path d="M14 6.5L20 12.5L14 18.5" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> */}
                            </button>
                        </div>
                    </div>
                    <div className='profile_tab_viedo'>
                        {allpostdate?.blog_data?.tagged_post.length > 0 ? (
                            allpostdate?.blog_data?.tagged_post?.map((item, index) => {

                                return (
                                    <div className='Category_card' key={index}>
                                        <img className='thumbnail_image'
                                            src={item?.thumbnail_image || errorImg}
                                            onError={(e) => { e.target.onerror = null; e.target.src = errorImg; }}
                                            alt={item.title}
                                        />
                                        <div className='Category_card_post_flex'>
                                            <div>
                                                <h2>{item.title || 'title name not available'}</h2>
                                                <p>
                                                    <span>{item.username || 'User Name'}</span>
                                                    <span> {item.view_count} views</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div>
                                <p className='tagged_post_not_found'>Post not found</p>
                            </div>
                        )

                        }

                    </div>
                </div >) : (
                null
            )
            }
        </>
    )
}

export default ProfilePost