import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ImFacebook } from "react-icons/im";
import { RiInstagramFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
import { Users } from '../../constants/LocalData';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../redux/actions/action-creator';

const ProfileSitebar = () => {
    const dispatch = useDispatch();
    const { userData = null, profileData = null, alluserpostdatalist = '' } = useSelector(state => state.auth);
    const { isRTL = false, recentlylinked = [], recentlyviewed = [], } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const userId = userData?.id;
        if (userId) {
            UserID_Function(userId);
            // console.log('profileData', profileData);
        }
    }, [userData]);

    const UserID_Function = (userId) => {
        dispatch(getUserProfile(userId))
            .then(response => {
                // console.log("getUserProfile Success Response:", response);
                if (response?.success) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error("getUserProfile Error:", error);
                setIsLoading(false);
            });
    };


    return (
        <>
            <div className='profile_site_content_user'>
                <div className='site_Intro_bar'>
                    <h2>Intro</h2>
                    <div className='profile_info_n'>
                        <h3>Description bio</h3>
                        <p>{alluserpostdatalist?.bio || 'No Bio'}</p>
                    </div>
                    <div className='profile_info_n'>
                        <h3>Email</h3>
                        <p>{alluserpostdatalist?.email || ''}</p>
                    </div>
                    {profileData?.facebook !== null || profileData?.instagram !== null || profileData?.twitter !== null ?
                        <div className='profile_info_n'>
                            <h3>Social</h3>
                            <ul>
                                {profileData?.facebook !== null ?
                                    <li>
                                        <Link to={profileData?.facebook} target="_blank"><ImFacebook /></Link>
                                    </li> : null}
                                {profileData?.instagram ?
                                    <li>
                                        <Link to={profileData?.instagram} target="_blank"><RiInstagramFill /></Link>
                                    </li> : null}
                                {profileData?.twitter ?
                                    <li>
                                        <Link to={profileData?.twitter} target="_blank"><FaTwitter /></Link>
                                    </li> : null}
                            </ul>
                        </div> : null}
                </div>
                {/* <div className='site_Intro_bar'>
                    <h2>Notifications</h2>
                    <div className='profile_info_n'>
                        {
                            Users.map((item, index) => {
                                return (
                                    <div className='Notifications_item' key={index} >
                                        <div className='Notifications_usr_img'>
                                            <img src={item.Notiimg} alt="" />
                                        </div>
                                        <div className='Notifications_con'>
                                            <h3>{item.sms}</h3>
                                            <div className='Noti_time'>
                                                <p>{item.time}</p>
                                                <p>{item.date}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default ProfileSitebar