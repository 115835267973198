
import axiosInstance from '../../axios/AxiosInstence';
import axios from 'axios';
import {
    GUEST_ID,
    LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE,
    GET_POST_CATEGRY_FAILURE, GET_POST_CATEGRY_REQUEST, GET_POST_CATEGRY_SUCCESS,
    SIGN_UP_REQUEST, SIGN_UP_SUCCESS, SIGN_UP_FAILURE,
    SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_IN_FAILURE,
    GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE,
    GET_INTREST_FAILURE, GET_INTREST_SUCCESS, GET_INTREST_REQUEST,
    GET_TEG_LIST_REQUEST, GET_TEG_LIST_SUCCESS, GET_TEG_LIST_FAILURE,
    TOGGLE_THEME, TOGGLE_THEME_SUCCESS, TOGGLE_THEME_FAILURE,
    OPEN_MODAL,
    CLOSE_MODAL,
    GET_LIBRARY_REQUEST, GET_LIBRARY_SUCCESS, GET_LIBRARY_FAILURE,
    GET_POST_UPDATE_FAILURE, GET_POST_UPDATE_SUCCESS, GET_POST_UPDATE_REQUEST,
    GET_SUB_CATEGRORY_REQUEST, GET_SUB_CATEGRORY_SUCCESS, GET_SUB_CATEGRORY_FAILURE,
    GET_BLOG_LIST_FAILURE, GET_BLOG_LIST_SUCCESS, GET_BLOG_LIST_REQUEST,
    GET_RECENTLY_LINKED_SUCCESS, GET_RECENTLY_LINKED_FAILURE, GET_RECENTLY_LINKED_REQUEST,
    GET_RECENTLY_VIEWED_FAILURE, GET_RECENTLY_VIEWED_SUCCESS, GET_RECENTLY_VIEWED_REQUEST,
    GET_BLOG_DETAILS_FAILURE, GET_BLOG_DETAILS_SUCCESS, GET_BLOG_DETAILS_REQUEST,
    MODERATOR_SIGN_UP_FAILURE, MODERATOR_SIGN_UP_REQUEST, MODERATOR_SIGN_UP_SUCCESS,
    GET_DIALCODE_FAILURE, GET_DIALCODE_SUCCESS, GET_DIALCODE_REQUEST,
    GET_POST_SAVE_TOGGED_REQUEST, GET_POST_SAVE_TOGGED_SUCCESS, GET_POST_SAVE_TOGGED_FAILURE,
    GET_ALL_POST_DATE_SUCCESS, GET_ALL_POST_DATE_FAILURE, GET_ALL_POST_DATE_REQUEST,
    GET_CATEGORY_MAIN_LIST_REQUEST, GET_CATEGORY_MAIN_LIST_SUCCESS, GET_CATEGORY_MAIN_LIST_FAILURE,
    GET_RECENTLY_LINKED_CATEGORY_REQUEST, GET_RECENTLY_LINKED_CATEGORY_SUCCESS, GET_RECENTLY_LINKED_CATEGORY_FAILURE,
    GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE, FOLLOW_USER_REQUEST, FOLLOW_USER_SUCCESS, FOLLOW_USER_FAILURE, UNFOLLOW_USER_FAILURE, UNFOLLOW_USER_SUCCESS, UNFOLLOW_USER_REQUEST, GET_FROFILE_UPDATE_REQUEST, GET_FROFILE_UPDATE_SUCCESS, GET_FROFILE_UPDATE_FAILURE,
    CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, CHANGE_PASSWORD_REQUEST,
    GET_LANGUAGE_REQUEST,
    GET_BLOG_COMMENT_LIST_REQUEST, GET_BLOG_COMMENT_LIST_SUCCESS,
    UPDATE_ANALYTICS, UPDATE_ANALYTICS_FALIURE,
    GET_BLOG_CATEGORY_REQUEST, GET_BLOG_CATEGORY_SUCCESS, GET_BLOG_CATEGORY_FAILURE,
    GET_CATEGORY_DETAILS_REQUEST, GET_CATEGORY_DETAILS_SUCCESS, GET_CATEGORY_DETAILS_FAILURE,
    OTP_VERIFICATION_SUCCESS, OTP_VERIFICATION_FAILURE, OTP_VERIFICATION_REQUEST, GET_BLOG_COMMENT_LIST_FAILURE, SEARCH_POSTS_REQUEST, SEARCH_POSTS_SUCCESS, SEARCH_POSTS_FAILURE,
    GET_BLOG_COMMENT_REPLY_LIST_SUCCESS,
    GET_BLOG_COMMENT_REPLY_LIST_FAILURE,
    GET_BLOG_COMMENT_REPLY_LIST_REQUEST,
    GET_SUB_CATEGRY_REQUEST,
    GET_SUB_CATEGRY_SUCCESS,
    GET_SUB_CATEGRY_FAILURE,
    GET_FOLLOWER_FOLLOWING_LIST_REQUEST,
    GET_FOLLOWER_FOLLOWING_LIST_SUCCESS,
    GET_FOLLOWER_FOLLOWING_LIST_FAILURE,
    GET_ALL_USERS_REQUEST,GET_ALL_USERS_SUCCESS,GET_ALL_USERS_FAILURE,
    CANCEL_GET_ALL_USER_PROFILE,
    SET_PLAYER_ID,
    GET_USER_POST_BY_USE_ID_REQUEST,
    GET_USER_POST_BY_USE_ID_SUCCESS,
    GET_USER_POST_BY_USE_ID_FAILURE,
} from './action-types';

// For Guest
export function setGuestID(data) {
    return async dispatch => {
        dispatch({ type: GUEST_ID, payload: data })
    }
}

export const setPlayerId = (playerId) => ({
    type: SET_PLAYER_ID,
    payload: playerId,
});

export function logOut() {
    return async dispatch => {
        dispatch({ type: LOGOUT_REQUEST, payload: 'Logout_Request' })
        try { dispatch({ type: LOGOUT_SUCCESS, payload: 'Logout_Success' }) }
        catch (e) {
            dispatch({ type: LOGOUT_FAILURE, payload: 'Logout_Failure' })
            console.log("catch error API Logout", e)
        }
    }
}

export function toggleTheme() {
    return async dispatch => {
        dispatch({ type: TOGGLE_THEME, payload: 'TOGGLE_THEME' });
        try {
            dispatch({ type: TOGGLE_THEME_SUCCESS, payload: 'TOGGLE_THEME_SUCCESS' });
        } catch (e) {
            dispatch({ type: TOGGLE_THEME_FAILURE, payload: 'TOGGLE_THEME_FAILURE' });
            console.log("catch error TOGGLE_THEME", e);
        }
    };
}

// OPEN_MODAL
export const openModal = () => {
    return {
        type: OPEN_MODAL
    };
};

export const closeModal = () => {
    return {
        type: CLOSE_MODAL
    };
};

// GET_CATEGORY_MAIN_LIST
export function geMaintCategorylist() {
    return async (dispatch) => {
        dispatch({ type: GET_CATEGORY_MAIN_LIST_REQUEST, payload: 'GET_POST_SAVE_TOGGED_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-category-list`)
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_CATEGORY_MAIN_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_CATEGORY_MAIN_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_CATEGORY_MAIN_LIST_FAILURE, payload: e });
            console.log("catch error API All post date", e)
            return Promise.reject(e)
        }
    }
};

// POST LIST
export function getpostlist(categoryId) {
    // console.log("Home_data Data ===>");
    return async (dispatch) => {
        dispatch({ type: GET_POST_CATEGRY_REQUEST, payload: 'GET_POST_CATEGRY_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-home-section?category_id=${categoryId}`);
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_POST_CATEGRY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            } else {
                dispatch({ type: GET_POST_CATEGRY_FAILURE, payload: responseJson });
                console.log('response_status_Else=========', response);
                return Promise.reject(responseJson);
            }
        } catch (e) {
            dispatch({ type: GET_POST_CATEGRY_FAILURE, payload: e });
            console.log("catch error API Post List Data", e);
            return Promise.reject(e);
        }
    };
}


// User Login
export function signIn(data) {
    return async (dispatch) => {
        dispatch({ type: SIGN_IN_REQUEST });
        try {
            var response = await axiosInstance.post(`login`, data);
            var responseJson = response.data;
            if (responseJson?.success) {
                dispatch({ type: SIGN_IN_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_IN_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_IN_FAILURE, payload: error });
            console.log("Catch Error signIn API ===> ", error);
            return Promise.reject(error)
        }
    };
}
// User Google Login
export function googleLogin(data) {
    return async (dispatch) => {
        dispatch({ type: SIGN_IN_REQUEST });
        try {
            var response = await axiosInstance.post(`social-media-signup`, data);
            var responseJson = response.data;
            console.log('check Google Login Api response', response)
            if (responseJson?.success) {
                dispatch({ type: SIGN_IN_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_IN_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_IN_FAILURE, payload: error });
            console.log("Catch Error Google  Login API ===> ", error);
            return Promise.reject(error)
        }
    };
}


// User signUp
export function signUp(data) {
    console.log("Signup Data ===>", data);
    return async (dispatch) => {
        dispatch({ type: SIGN_UP_REQUEST });
        try {
            var response = await axiosInstance.post(`signup`, data);
            var responseJson = response.data;
            // console.log('SIGNUP_ERROR', response)
            if (responseJson?.success) {
                dispatch({ type: SIGN_UP_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SIGN_UP_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SIGN_UP_FAILURE, payload: error });
            console.log("Catch Error signUp API ===> ", error);
            return Promise.reject(error)
        }
    };
}
// moderator signUp
export function moderatorSignUp(data) {
    console.log("Signup Data ===>", data);
    return async (dispatch) => {
        dispatch({ type: MODERATOR_SIGN_UP_REQUEST, payload: 'MODERATOR_SIGN_UP_REQUEST' });
        try {
            var response = await axiosInstance.post(`moderator-signup`, data);
            var responseJson = response.data;
            console.log('moderator SIGNUP_ERROR', response)
            if (responseJson?.success) {
                dispatch({ type: MODERATOR_SIGN_UP_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: MODERATOR_SIGN_UP_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: MODERATOR_SIGN_UP_FAILURE, payload: error });
            console.log("Catch Error signUp API ===> ", error);
            return Promise.reject(error)
        }
    };
}

// FORGOT_PASSWORD
export function forgetPassword(data) {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/forget-password', data)
            const responseJson = response.data;
            if (responseJson.success) { return Promise.resolve(responseJson) }
            else { return Promise.reject(responseJson) }
        } catch (e) {
            console.log('catch error API forgetPassword', e)
            return Promise.reject(e)
        }
    }
}

export function verifyOTP(formData) {
    console.log("verifyOTP data===> ", formData);
    return async dispatch => {
        dispatch({ type: OTP_VERIFICATION_REQUEST, payload: 'SIGN_UP_REQUEST' });
        try {
            var response = await axiosInstance.post('/do-verify-otp', formData)
            var responseJson = response.data;
            console.log("verifyOTP Response===> ", responseJson);
            if (responseJson?.success) {
                dispatch({ type: OTP_VERIFICATION_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: OTP_VERIFICATION_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: OTP_VERIFICATION_FAILURE, payload: e });
            console.log("catch error API verifyOTP", e)
            return Promise.reject(e)
        }
    }
}

// Resend OTP
export function resendOtp(data) {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/resent-otp', data)
            const responseJson = response.data;
            if (responseJson.success) { return Promise.resolve(responseJson) }
            else { return Promise.reject(responseJson) }
        } catch (e) {
            console.log('catch error API resent otp', e)
            return Promise.reject(e)
        }
    }
}

// export const verifyOTP = (formData) => {
//     // Assuming you're using axios for HTTP requests
//     return axios.post('/api/verify-otp', formData)
//         .then(response => response.data)
//         .catch(error => {
//             throw error.response.data;
//         });
// };

export function changePassword(data) {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/reset-password', data)
            const responseJson = response.data;
            if (responseJson.success) { return Promise.resolve(responseJson) }
            else { return Promise.reject(responseJson) }
        } catch (e) {
            console.log('catch error API changePassword', e)
            return Promise.reject(e)
        }
    }
}

export function getUserProfile(userId) {
    return async (dispatch) => {
        dispatch({ type: GET_USER_PROFILE_REQUEST, payload: 'GET_USER_PROFILE_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-profile?user_id=${userId}`);
            var responseJson = response?.data;
            // console.log('===profile response =====================>:', userId);
            if (responseJson?.success) {
                dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_USER_PROFILE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson);
            }
        }
        catch (e) {
            console.log("catch error API getUserProfile", e);
            dispatch({ type: GET_USER_PROFILE_FAILURE, payload: e });
            return Promise.reject(e);
        }
    };
}

export function getallUserProfile() {
    return async (dispatch) => {
        dispatch({ type: GET_ALL_USERS_REQUEST, payload: 'GET_ALL_USERS_REQUEST' });
        try {
            const response = await axiosInstance.get(`/get-all-users`);
            const responseJson = response?.data;
            //console.log('===all user profiles response =======', responseJson);
            if (responseJson?.success) {
                dispatch({ type: GET_ALL_USERS_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            } else {
                dispatch({ type: GET_ALL_USERS_FAILURE, payload: responseJson });
                return Promise.reject(responseJson);
            }
        } catch (e) {
            console.log("catch error API getallUserProfile", e);
            dispatch({ type: GET_ALL_USERS_FAILURE, payload: e });
            return Promise.reject(e);
        }
    };
}




// POST TAG LIST
export function getTeglist() {
    // console.log("Home_data Data ===>");
    return async (dispatch) => {
        dispatch({ type: GET_TEG_LIST_REQUEST, payload: 'GET_TEG_LIST_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-tag-list`)
            var responseJson = response?.data;
            // console.log('== get teg list response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_TEG_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_TEG_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
                // console.log('respons_status_ Else=========', response)
            }
        }
        catch (e) {
            dispatch({ type: GET_TEG_LIST_FAILURE, payload: e });
            console.log("catch error API tag list Data", e)
            return Promise.reject(e)
        }
    }
};

// SUB CSTEGRY
export function getSubCategeory() {
    // console.log("subcategory ===>");
    return async (dispatch) => {
        dispatch({ type: GET_SUB_CATEGRORY_REQUEST, payload: 'GET_SUB_CATEGRORY_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-category-with-subcategory`)
            var responseJson = response?.data;
            // console.log('== get subcategory response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_SUB_CATEGRORY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_SUB_CATEGRORY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
                // console.log('respons_status_ Else=========', response)
            }
        }
        catch (e) {
            dispatch({ type: GET_SUB_CATEGRORY_FAILURE, payload: e });
            console.log("catch error API subcategory", e)
            return Promise.reject(e)
        }
    }
};


// GET_LIBRARY
export function getLibrary() {
    return async (dispatch) => {
        dispatch({ type: GET_LIBRARY_REQUEST, payload: 'GET_LIBRARY_REQUEST' });
        try {
            var response = await axiosInstance.get(`get-bookmarks`)
            var responseJson = response?.data;
            // console.log('== get Library Api  response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_LIBRARY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_LIBRARY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
                // console.log('respons_status_ Else=========', response)
            }
        }
        catch (e) {
            dispatch({ type: GET_LIBRARY_FAILURE, payload: e });
            console.log("catch error API Library ", e)
            return Promise.reject(e)
        }
    }
};

// GET_BLOG_LIST
export function getBlogpost() {
    return async (dispatch) => {
        dispatch({ type: GET_BLOG_LIST_REQUEST, payload: 'GET_BLOG_LIST_REQUEST' });
        try {
            var response = await axiosInstance.get(`/blog-list`)
            var responseJson = response?.data;
            // console.log('== get Blog list post response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_BLOG_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_BLOG_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_BLOG_LIST_FAILURE, payload: e });
            console.log("catch error API  Blog list post ", e)
            return Promise.reject(e)
        }
    }
};

// GET_BLOG_LIST
export function getBlogDetails(id) {
    // const id = 63; // Fixed ID
    return async (dispatch) => {
        dispatch({ type: GET_BLOG_DETAILS_REQUEST, payload: id });
        try {
            var response = await axiosInstance.get(`/blog-detail/${id}`);
            var responseJson = response?.data;
            // console.log('== get   Blog Details response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_BLOG_DETAILS_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_BLOG_DETAILS_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_BLOG_DETAILS_FAILURE, payload: e });
            console.log("catch error API  Blog Details ", e)
            return Promise.reject(e)
        }
    }
};

// GET_BLOG_LIST
export function getCategoryDetail(id) {
    // const id = 63; // Fixed ID
    return async (dispatch) => {
        dispatch({ type: GET_CATEGORY_DETAILS_REQUEST, payload: id });
        try {
            var response = await axiosInstance.get(`/category-detail/${id}`);
            var responseJson = response?.data;
            // console.log('== get   Blog Details response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_CATEGORY_DETAILS_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_CATEGORY_DETAILS_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_BLOG_DETAILS_FAILURE, payload: e });
            console.log("catch error API  Blog Details ", e)
            return Promise.reject(e)
        }
    }
};

// GET_RECENTLY_LINKED
export function getRecentlylinked() {
    return async (dispatch) => {
        dispatch({ type: GET_RECENTLY_LINKED_REQUEST, payload: 'GET_RECENTLY_LINKED_REQUEST' });
        try {
            var response = await axiosInstance.get(`/recently-linked`)
            var responseJson = response?.data;
            // console.log('== get recently linked response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_RECENTLY_LINKED_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_RECENTLY_LINKED_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_RECENTLY_LINKED_FAILURE, payload: e });
            console.log("catch error API recently linked ", e)
            return Promise.reject(e)
        }
    }
};

// GET_RECENTLY_VIEWED
export function getRecentlyviewed() {
    return async (dispatch) => {
        dispatch({ type: GET_RECENTLY_VIEWED_REQUEST, payload: 'GET_RECENTLY_LINKED_REQUEST' });
        try {
            var response = await axiosInstance.get(`/recently-viewed-blogs`)
            var responseJson = response?.data;
            // console.log('== get recently viewed response ==', response)
            if (responseJson?.success) {
                dispatch({ type: GET_RECENTLY_VIEWED_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_RECENTLY_VIEWED_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_RECENTLY_VIEWED_FAILURE, payload: e });
            console.log("catch error API recently viewed ", e)
            return Promise.reject(e)
        }
    }
};

// GET_RECENTLY_LINKED_CATEGORY
export function getRecentlyLinkedCategory() {
    return async (dispatch) => {
        dispatch({ type: GET_RECENTLY_LINKED_CATEGORY_REQUEST, payload: 'GET_RECENTLY_LINKED_CATEGORY_REQUEST' });
        try {
            var response = await axiosInstance.get(`/recently-linked-category`)
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_RECENTLY_LINKED_CATEGORY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_RECENTLY_LINKED_CATEGORY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_RECENTLY_VIEWED_FAILURE, payload: e });
            console.log("catch error API recently LInk category ", e)
            return Promise.reject(e)
        }
    }
};


//  FEED INTREST
export function getInterest() {
    return async (dispatch) => {
        dispatch({ type: GET_INTREST_REQUEST, payload: 'GET_INTREST_REQUEST' });
        try {
            var response = await axiosInstance.get(`/get-category-with-subcategory`)
            var responseJson = response?.data;
            // console.log('Interest API RESPONSE', response)
            if (responseJson?.success) {
                dispatch({ type: GET_INTREST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_INTREST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_INTREST_FAILURE, payload: e });
            console.log("catch error API Interest", e)
            return Promise.reject(e)
        }
    }
};


export function interestadd(data) {
    return async dispatch => {
        try {
            var response = await axiosInstance.post('add-feed', data);
            var responseJson = response?.data;
            if (responseJson?.success) {
                return Promise.resolve(responseJson);
            } else {
                return Promise.reject(responseJson);
            }
        } catch (e) {
            console.log("catch error interest add API ", e);
            return Promise.reject(e);
        }
    }
}
export function addCommentData(data) {
    return async dispatch => {
        try {
            var response = await axiosInstance.post('/add-blog-comment', data);
            var responseJson = response?.data;
            if (responseJson?.success) {
                return Promise.resolve(responseJson);
            } else {
                return Promise.reject(responseJson);
            }
        } catch (e) {
            console.error("Error occurred in addCommentData:", e);
            return Promise.reject(e);
        }
    }
}

export function addReplyComment(data) {
    return async dispatch => {
        try {
            var response = await axiosInstance.post('/add-blog-comment-reply', data);
            var responseJson = response?.data;
            if (responseJson?.success) {
                return Promise.resolve(responseJson);
            } else {
                return Promise.reject(responseJson);
            }
        } catch (e) {
            console.error("Error Comment reply", e);
            return Promise.reject(e);
        }
    }
}



// Add or Update Blog Post data
export function getUpdatedData(data) {
    return async (dispatch) => {
        dispatch({ type: GET_POST_UPDATE_REQUEST, payload: 'GET_POST_UPDATE_REQUEST' });
        try {
            var response = await axiosInstance.post(`/add-update-blog`, data);
            var responseJson = response.data;
            // console.log('Add Update data Response', response)
            if (responseJson?.success) {
                dispatch({ type: GET_POST_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_POST_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_POST_UPDATE_FAILURE, payload: error });
            console.log("Catch Error Add Update data ===> ", error);
            return Promise.reject(error)
        }
    };
}

export function updatepost(data) {
    return async dispatch => {
        try {
            const response = await axiosInstance.post('add-update-blog', data);
            const responseJson = response?.data;
            if (responseJson?.success) {
                return Promise.resolve(responseJson);
            } else {
                return Promise.reject(responseJson);
            }
        } catch (e) {
            console.log("Error in API Update post", e);
            return Promise.reject(e);
        }
    };
}


// GET_DIALCODE
export function getDialcode() {
    return async (dispatch) => {
        dispatch({ type: GET_DIALCODE_REQUEST, payload: 'GET_DIALCODE_REQUEST' });
        try {
            var response = await axiosInstance.get(`/codes-nationalities-list`)
            var responseJson = response?.data;
            // console.log('Dial code API RESPONSE', response)
            if (responseJson?.success) {
                dispatch({ type: GET_DIALCODE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_DIALCODE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_DIALCODE_FAILURE, payload: e });
            console.log("catch error API Dial Code", e)
            return Promise.reject(e)
        }
    }
};

// GET_POST_SAVE_TOGGED
export function getPostSaveTagged(type) {
    return async (dispatch) => {
        dispatch({ type: GET_POST_SAVE_TOGGED_REQUEST, payload: type });
        try {
            console.log(type);
            var response = await axiosInstance.post(`/get-post-by-userid?type=${type}`)
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_POST_SAVE_TOGGED_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_POST_SAVE_TOGGED_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_POST_SAVE_TOGGED_FAILURE, payload: e });
            console.log("catch error API get-post-by", e)
            return Promise.reject(e)
        }
    }
};

// GET_POST_SAVE_TOGGED
export function getAllpostdate(post_type, type, user_id) {
    console.log('post=',post_type);
    console.log('type=',type);
    console.log('userid=',user_id);

    return async (dispatch) => {
        dispatch({ type: GET_ALL_POST_DATE_REQUEST, payload: 'GET_ALL_POST_DATE_REQUEST' });
        try {
            // Add your parameters to the POST request body
            var response = await axiosInstance.post(`/get-all-post-by-userid`, {
                post_type: post_type,
                type: type,
                user_id: user_id
            });
            var responseJson = response?.data;
            // console.log('userdatapost------>',responseJson);
            if (responseJson?.success) {
                dispatch({ type: GET_ALL_POST_DATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            } else {
                dispatch({ type: GET_ALL_POST_DATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson);
            }
        } catch (e) {
            dispatch({ type: GET_ALL_POST_DATE_FAILURE, payload: e });
            console.log("catch error API All post date", e);
            return Promise.reject(e);
        }
    };
}

// GET_NOTIFICATION
export function getNotification() {
    return async (dispatch) => {
        dispatch({ type: GET_NOTIFICATION_REQUEST, payload: 'GET_NOTIFICATION_REQUEST' });
        try {
            var response = await axiosInstance.get(`/notification-list`)
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_NOTIFICATION_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);

            }
            else {
                dispatch({ type: GET_NOTIFICATION_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_NOTIFICATION_FAILURE, payload: e });
            console.log("catch error API Notication", e)
            return Promise.reject(e)
        }
    }
};

export function getUpdateProfile(data) {
    return async (dispatch) => {
        dispatch({ type: GET_FROFILE_UPDATE_REQUEST, payload: 'GET_FROFILE_UPDATE_REQUEST' });
        try {
            var response = await axiosInstance.post(`/update-profile`, data);
            var responseJson = response.data;
            console.log('Add Update data Response', response)
            if (responseJson?.success) {
                dispatch({ type: GET_FROFILE_UPDATE_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_FROFILE_UPDATE_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_FROFILE_UPDATE_FAILURE, payload: error });
            console.log("Catch ErrorProfile Update ===> ", error);
            return Promise.reject(error)
        }
    };
}

// GET_FOLLOW_USER
// export const followUser = (userId) => {
//     return async (dispatch) => {
//         dispatch({ type: FOLLOW_USER_REQUEST });
//         try {
//             const response = await axiosInstance.post('/follow-user', { follow_id: userId });
//             dispatch({ type: FOLLOW_USER_SUCCESS, payload: response.data });
//             console.log('User followed successfully!');
//         } catch (error) {
//             dispatch({ type: FOLLOW_USER_FAILURE, payload: error });
//             console.error('Error following user:', error);
//         }
//     };
// };
// Redux action


// export function likeViewsBookmark(data) {
//     return async dispatch => {
//         try {
//             const response = await axiosInstance.post('/add-analytics', data);
//             const responseJson = response?.data;
//             if (responseJson?.success) {
//                 return Promise.resolve(responseJson);
//             } else {
//                 return Promise.reject(responseJson);
//             }
//         } catch (e) {
//             console.log("Error in API Like view and bookmark", e);
//             return Promise.reject(e);
//         }
//     };
// }
export const likeViewsBookmark = (data) => {
    return async (dispatch) => {
        try {
            // let formData = new FormData();

            // data.forEach(([key, value]) => {
            //     formData.append(key, value);
            // });
            // const response = await axiosInstance.post('/add-analytics', data);
            // dispatch({
            //     type: 'UPDATE_ANALYTICS',
            //     payload: response.data // Assuming API returns updated analytics data
            // });
            var response = await axiosInstance.post(`/add-analytics`, data);
            var responseJson = response.data;
            console.log('Add Update data Response', response)
            if (responseJson?.success) {
                dispatch({ type: UPDATE_ANALYTICS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: UPDATE_ANALYTICS_FALIURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            // Handle error
            console.error('Error updating analytics:', error);
        }
    };
};

export const followOrUnfollowUser = (data) => {
    return async (dispatch) => {
        try {
            var response = await axiosInstance.post(`/follow-user`, data);
            var responseJson = response.data;
            console.log('Add Update data Response', responseJson)
            if (responseJson?.success) {
                dispatch({ type: FOLLOW_USER_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: FOLLOW_USER_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            // Handle error
            dispatch({ type: FOLLOW_USER_FAILURE, payload: error });
            console.log("Catch ErrorProfile Update ===> ", error);
            return Promise.reject(error)
        }
    };
};

// export const followOrUnfollowUser = (data) => {
//     return async (dispatch) => {        
//         try {
//             var response = await axiosInstance.post('/follow-user', data);
//             var responseJson = response.data;
//             if (responseJson?.success) {
//                 dispatch({ type: FOLLOW_USER_SUCCESS, payload: data });
//                 return Promise.resolve(responseJson)
//             } else {
//                 dispatch({ type: FOLLOW_USER_FAILURE, payload: responseJson });
//                 return Promise.reject(responseJson)
//             }
//         } catch (error) {
//             console.error('Error in following user:', error);
//         }
//     };
// };

export function changeUserPassword(data) {
    return async (dispatch) => {
        dispatch({ type: CHANGE_PASSWORD_REQUEST, payload: 'CHANGE_PASSWORD_REQUEST' });
        try {
            var response = await axiosInstance.post(`/change-password`, data);
            var responseJson = response.data;
            console.log('Add Update data Response', response)
            if (responseJson?.success) {
                dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error });
            console.log("Catch ErrorProfile Update ===> ", error);
            return Promise.reject(error)
        }
    };
}

export function getLanguages() {
    return async (dispatch) => {
        dispatch({ type: GET_LANGUAGE_REQUEST, payload: 'GET_LANGUAGE_REQUEST' });
        try {
            var response = await axiosInstance.get(`/language-list`)
            var responseJson = response?.data;
            if (responseJson?.success) {
                dispatch({ type: GET_INTREST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_INTREST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_INTREST_FAILURE, payload: e });
            console.log("catch error API Interest", e)
            return Promise.reject(e)
        }
    }
};

// GET_BLOG_COMMENT_LIST
export function getBlogCommentList(id) {
    // const id = 63; // Fixed ID
    return async (dispatch) => {
        dispatch({ type: GET_BLOG_COMMENT_LIST_REQUEST, payload: id });
        try {
            var response = await axiosInstance.get(`/get-blog-comments/${id}`);
            var responseJson = response.data;
            // console.log('== get comment list response ==', response?.data)
            if (responseJson?.success) {
                dispatch({ type: GET_BLOG_COMMENT_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_BLOG_COMMENT_LIST_SUCCESS, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_BLOG_COMMENT_LIST_FAILURE, payload: e });
            console.log("catch error API  comment List ", e)
            return Promise.reject(e)
        }
    }
};
// GET_BLOG_COMMENT_LIST
export function getBlogCommentReplyList(blogId, commentId) {
    console.log("blogId----->", blogId);
    console.log("commentId----->", commentId);

    return async (dispatch) => {
        dispatch({ type: GET_BLOG_COMMENT_REPLY_LIST_REQUEST, payload: commentId });

         try {
            console.log("Sending request to API with payload:", { blogId, commentId });

            
            const formData = new FormData();
            formData.append('blog_id', blogId);
            formData.append('comment_id', commentId);

           
            const response = await axiosInstance.post(`/get-blog-comments-reply`, formData);



            const responseJson = response.data;
            console.log('==comment Reply list response ==', responseJson);

            if (responseJson?.success) {
                dispatch({ type: GET_BLOG_COMMENT_REPLY_LIST_SUCCESS, payload: responseJson.data });
                return Promise.resolve(responseJson.data);
            } else {
                dispatch({ type: GET_BLOG_COMMENT_REPLY_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson);
            }
        } catch (error) {
            dispatch({ type: GET_BLOG_COMMENT_REPLY_LIST_FAILURE, payload: error });
            console.log("Catch error API comment List ", error);
            return Promise.reject(error);
        }
    };
};



// GET_BLOG_LIST
export function getBlogCategory(id) {
    // const id = 63; // Fixed ID
    return async (dispatch) => {
        dispatch({ type: GET_BLOG_CATEGORY_REQUEST, payload: id });
        try {
            var response = await axiosInstance.get(`/get-post-by-categoryid/${id}`);
            var responseJson = response?.data;
            console.log('adsssss----->',responseJson)
            if (responseJson?.success) {
                dispatch({ type: GET_BLOG_CATEGORY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson);
            }
            else {
                dispatch({ type: GET_BLOG_CATEGORY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        }
        catch (e) {
            dispatch({ type: GET_BLOG_DETAILS_FAILURE, payload: e });
            console.log("catch error API Blog Details ", e)
            return Promise.reject(e)
        }
    }
};

// Search APi 
export function searchPosts(formData) {
    return async (dispatch) => {
        dispatch({ type: SEARCH_POSTS_REQUEST, payload: 'CHANGE_PASSWORD_REQUEST' });
        try {
            var response = await axiosInstance.post(`/blog-search`, formData);
            var responseJson = response.data;
            // console.log('Search list data Response', response)
            if (responseJson?.success) {
                dispatch({ type: SEARCH_POSTS_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: SEARCH_POSTS_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: SEARCH_POSTS_FAILURE, payload: error });
            console.log("Catch Error in Blog Search Api ===> ", error);
            return Promise.reject(error)
        }
    };
}

// SUB_CATEGRY
export function getcategoryToSubcatrgory(Data) {
    return async (dispatch) => {
        dispatch({ type: GET_SUB_CATEGRY_REQUEST, payload: 'CHANGE_PASSWORD_REQUEST' });
        try {
            var response = await axiosInstance.post(`get-subcategory-by-category`, Data);
            var responseJson = response.data;
            // console.log('subcategory-by-category Response', response)
            if (responseJson?.success) {
                dispatch({ type: GET_SUB_CATEGRY_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_SUB_CATEGRY_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_SUB_CATEGRY_FAILURE, payload: error });
            console.log("Catch Error subcategory by category Api ===> ", error);
            return Promise.reject(error)
        }
    };
}

// SUB_CATEGRY
export function getfollowerfollowinglist(Data) {
    return async (dispatch) => {
        dispatch({ type: GET_FOLLOWER_FOLLOWING_LIST_REQUEST, payload: 'CHANGE_PASSWORD_REQUEST' });
        try {
            var response = await axiosInstance.post(`follower-following-list`, Data);
            var responseJson = response.data;
            console.log('follower-following-list Response', response)
            if (responseJson?.success) {
                dispatch({ type: GET_FOLLOWER_FOLLOWING_LIST_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_FOLLOWER_FOLLOWING_LIST_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_FOLLOWER_FOLLOWING_LIST_FAILURE, payload: error });
            console.log("Catch Error follower-following-list Api ===> ", error);
            return Promise.reject(error)
        }
    };
}


// USER_POST_BY_USE_ID
export function getPostusertyp(data) {
    return async (dispatch) => {
        dispatch({ type: GET_USER_POST_BY_USE_ID_REQUEST, payload: 'GET_POST_UPDATE_REQUEST' });
        try {
            var response = await axiosInstance.post(`/get-post-by-userid`, data);
            var responseJson = response.data;
            // console.log('user- dta post data id data Response', response)
            if (responseJson?.success) {
                dispatch({ type: GET_USER_POST_BY_USE_ID_SUCCESS, payload: responseJson });
                return Promise.resolve(responseJson)
            } else {
                dispatch({ type: GET_USER_POST_BY_USE_ID_FAILURE, payload: responseJson });
                return Promise.reject(responseJson)
            }
        } catch (error) {
            dispatch({ type: GET_USER_POST_BY_USE_ID_FAILURE, payload: error });
            console.log("Catch Error Add Update data ===> ", error);
            return Promise.reject(error)
        }
    };
}