import React, { useEffect, useState } from 'react'
import { Assets } from '../../assets'
import ProfileTab from './ProfileTabs'
import ProfileSitebar from './ProfileSitebar'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, getallUserProfile,getRecentlylinked } from '../../redux/actions/action-creator'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
import { Colors } from '../../color'
import { useParams } from "react-router-dom";

const Profile = ({googleData}) => {
    const dispatch = useDispatch();
    // Get ID from URL
    const params = useParams();
    let { alluserpostdatalist = '' } = useSelector(state => state.auth);
    //let { blogs_data = [] } = useSelector(state => state.blogs_data);
    const { isRTL = false, recentlylinked = [], recentlyviewed = [], } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(true);
    const [isFollowing, setIsFollowing] = useState(false);  
    
    
    const [profileData, setProfileData] = useState(null);
    useEffect(() => {
        const userId = params?.id;
        console.log('userid-------->',userId);
        // console.log('blogs_data-------->',blogs_data);
        if (userId) {
            UserID_Function(userId);
            console.log('profileData', profileData);
            //profileData = profileData2;
        }
    }, []);

    const UserID_Function = (userId) => {   
        
        dispatch(getUserProfile(userId))
        
            .then(response => {
                // console.log("getUserProfile Success Response:", response);
                if (response?.success) {
                    setIsLoading(false);
                    setIsFollowing(response.data.linked_user);
                    setProfileData(response.data)
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error("getUserProfile Error:", error);
                setIsLoading(false);
            });
    };


    const handleFollowClick = () => {
        if (isFollowing) {
            dispatch(getRecentlylinked(profileData.id))
                .then(response => {
                    if (response.success) {
                        setIsFollowing(false);
                        toast.success('Unfollowed successfully');
                    }
                })
                .catch(error => {
                    toast.error('Failed to unfollow');
                });
        } else {
            dispatch(getRecentlylinked(profileData.id))
                .then(response => {
                    if (response.success) {
                        setIsFollowing(true);
                        toast.success('Followed successfully');
                    }
                })
                .catch(error => {
                    toast.error('Failed to follow');
                });
        }
    };

    // Log profileData to console
    // console.log("Profile Data:", alluserpostdatalist?.type);


    return (
        <>
            <div className='profle_baneer_img'>
                <img src={profileData?.cover_image || Assets.PROFILE_BANNER} />
            </div>
            <div className='user_profile_details'>
                <div className='user_profile_img'>
                    {googleData && profileData && (
                        <img src={alluserpostdatalist.photo || Assets.USER_site} alt='Profile Picture' />
                    )}
                    {!googleData && profileData && (
                        <img src={profileData.photo || Assets.USER_site} alt='Profile Picture' />
                    )}
                    {googleData && !profileData && (
                        <img src={profileData.picture} alt="Profile Picture" />
                    )}


                </div>

                <div className='user_profile_cont'>
                    <h2>{alluserpostdatalist?.name || profileData?.name || 'Loading...'}</h2>
                    <p>
                        {/* <span>@user name</span> */}
                        <span>{alluserpostdatalist?.linked_user || 0} Links</span>
                        <span>{alluserpostdatalist?.linking_user || 0} Links</span>
                    </p>
                    {/* <div className='detais_blog_link_buton'>
                    <button onClick={handleFollowClick} className='Button' style={{ color: Colors.TEXT_DARK, backgroundColor: Colors.BACKGROUND_PRIMER, }}>
                        {isFollowing ? 'Linked' : 'Link'}
                    </button>
                    </div> */}
                </div>
            </div>
            <ProfileTab user_id={params?.id}/>
        </>
    )
}

export default Profile
