import {

    GET_POST_CATEGRY_REQUEST, GET_POST_CATEGRY_SUCCESS, GET_POST_CATEGRY_FAILURE,
    GET_INTREST_REQUEST, GET_INTREST_SUCCESS, GET_INTREST_FAILURE,
    GET_TEG_LIST_REQUEST, GET_TEG_LIST_SUCCESS, GET_TEG_LIST_FAILURE,
    TOGGLE_THEME,
    GET_SUB_CATEGRORY_FAILURE, GET_SUB_CATEGRORY_SUCCESS, GET_SUB_CATEGRORY_REQUEST,
    GET_RECENTLY_LINKED_REQUEST, GET_RECENTLY_LINKED_SUCCESS, GET_RECENTLY_LINKED_FAILURE,
    GET_RECENTLY_VIEWED_FAILURE, GET_RECENTLY_VIEWED_SUCCESS, GET_RECENTLY_VIEWED_REQUEST,
    GET_BLOG_DETAILS_FAILURE, GET_BLOG_DETAILS_SUCCESS, GET_BLOG_DETAILS_REQUEST,
    GET_DIALCODE_REQUEST, GET_DIALCODE_SUCCESS, GET_DIALCODE_FAILURE,
    GET_POST_SAVE_TOGGED_REQUEST, GET_POST_SAVE_TOGGED_SUCCESS, GET_POST_SAVE_TOGGED_FAILURE,
    GET_ALL_POST_DATE_FAILURE, GET_ALL_POST_DATE_SUCCESS, GET_ALL_POST_DATE_REQUEST,
    GET_CATEGORY_MAIN_LIST_REQUEST, GET_CATEGORY_MAIN_LIST_SUCCESS, GET_CATEGORY_MAIN_LIST_FAILURE,
    GET_RECENTLY_LINKED_CATEGORY_REQUEST, GET_RECENTLY_LINKED_CATEGORY_FAILURE, GET_RECENTLY_LINKED_CATEGORY_SUCCESS,
    GET_NOTIFICATION_REQUEST, GET_NOTIFICATION_SUCCESS, GET_NOTIFICATION_FAILURE,
    GET_BLOG_COMMENT_LIST_FAILURE, GET_BLOG_COMMENT_LIST_SUCCESS, GET_BLOG_COMMENT_LIST_REQUEST,
    SEARCH_POSTS_REQUEST, SEARCH_POSTS_SUCCESS, SEARCH_POSTS_FAILURE,
    GET_BLOG_COMMENT_REPLY_LIST_FAILURE,
    GET_BLOG_COMMENT_REPLY_LIST_REQUEST,
    GET_BLOG_COMMENT_REPLY_LIST_SUCCESS,
    GET_FOLLOWER_FOLLOWING_LIST_REQUEST,
    GET_FOLLOWER_FOLLOWING_LIST_SUCCESS,
    GET_FOLLOWER_FOLLOWING_LIST_FAILURE,
    GET_USER_POST_BY_USE_ID_REQUEST,
    GET_USER_POST_BY_USE_ID_SUCCESS,
    GET_USER_POST_BY_USE_ID_FAILURE,

} from '../actions/action-types';


const initialState = {
    lang: "en", isRTL: false,
    homeSliderList: [],
    homeCategryList: [],
    intrest: [],
    tegListdata: [],
    theme: 'dark',
    subcategory: [],
    recentlylinked: [],
    recentlyviewed: [],
    blogposDetails: [],
    dialcodelist: [],
    postsavetogged: [],
    allpostdate: [],
    maincategorylist: [],
    recentlyLikedCatecory: [],
    notificationlist: [],
    commentlist: [],
    commentreplylist: [],
    searchResult: [],
    followinglistdata: [],
    userpostbydataList: [],
}

export const common = (state = initialState, { type, payload }) => {
    switch (type) {

        case TOGGLE_THEME:
            return {
                ...state,
                theme: state.theme === 'dark' ? 'light' : 'dark'
            };

        // GET_HOME_DATA
        case GET_CATEGORY_MAIN_LIST_REQUEST:
            return {
                ...state,
            }
        case GET_CATEGORY_MAIN_LIST_SUCCESS:
            return {
                ...state, maincategorylist: payload?.data || null,
            }
        case GET_CATEGORY_MAIN_LIST_FAILURE:
            return {
                ...state,
            }

        // GET_POST_LIST
        case GET_POST_CATEGRY_REQUEST:
            return {
                ...state,
            }
        case GET_POST_CATEGRY_SUCCESS:
            return {
                ...state, homeCategryList: payload?.data || [],
            }
        case GET_POST_CATEGRY_FAILURE:
            return {
                ...state, homeCategryList: []
            }

        // GET_INTERST
        case GET_INTREST_REQUEST:
            return {
                ...state,
            }
        case GET_INTREST_SUCCESS:
            return {
                ...state, intrest: payload?.data || [],
            }
        case GET_INTREST_FAILURE:
            return {
                ...state, intrest: []
            }

        // GET_INTERST
        case GET_TEG_LIST_REQUEST:
            return {
                ...state,
            }
        case GET_TEG_LIST_SUCCESS:
            return {
                ...state, tegListdata: payload?.data || [],
            }
        case GET_TEG_LIST_FAILURE:
            return {
                ...state,
            }

        // GET_SUB_CATEGRORY
        case GET_SUB_CATEGRORY_REQUEST:
            return {
                ...state,
            }
        case GET_SUB_CATEGRORY_SUCCESS:
            return {
                ...state, subcategory: payload?.data || [],
            }
        case GET_SUB_CATEGRORY_FAILURE:
            return {
                ...state,
            }

        // GET_RECENTLY_LINKED
        case GET_RECENTLY_LINKED_REQUEST:
            return {
                ...state,
            }
        case GET_RECENTLY_LINKED_SUCCESS:
            return {
                ...state, recentlylinked: payload?.data || [],
            }
        case GET_RECENTLY_LINKED_FAILURE:
            return {
                ...state,
            }

        // GET_RECENTLY_VIEWED
        case GET_RECENTLY_VIEWED_REQUEST:
            return {
                ...state,
            }
        case GET_RECENTLY_VIEWED_SUCCESS:
            return {
                ...state, recentlyviewed: payload?.data || [],
            }
        case GET_RECENTLY_VIEWED_FAILURE:
            return {
                ...state,
            }

        // GET_RECENTLY_LINKED_CATEGORY
        case GET_RECENTLY_LINKED_CATEGORY_REQUEST:
            return {
                ...state,
            }
        case GET_RECENTLY_LINKED_CATEGORY_SUCCESS:
            return {
                ...state, recentlyLikedCatecory: payload?.data || [],
            }
        case GET_RECENTLY_LINKED_CATEGORY_FAILURE:
            return {
                ...state,
            }

        // GET_BLOG_LIST
        case GET_BLOG_DETAILS_REQUEST:
            return {
                ...state,
            };
        case GET_BLOG_DETAILS_SUCCESS:
            return {
                ...state, blogposDetails: payload?.data || null,
            };
        case GET_BLOG_DETAILS_FAILURE:
            return {
                ...state,
            };

        // GET_DIALCODE
        case GET_DIALCODE_REQUEST:
            return {
                ...state,
            };
        case GET_DIALCODE_SUCCESS:
            return {
                ...state, dialcodelist: payload?.data || null,
            };
        case GET_DIALCODE_FAILURE:
            return {
                ...state,
            };

        // GET_POST_SAVE_TOGGED
        case GET_POST_SAVE_TOGGED_REQUEST:
            return {
                ...state, allpostdata: payload?.data || null,
            };
        case GET_POST_SAVE_TOGGED_SUCCESS:
            return {
                ...state, allpostdata: payload?.data || null,
            };
        case GET_POST_SAVE_TOGGED_FAILURE:
            return {
                ...state,
            };

        // GET_ALL_POST_DATE
        case GET_ALL_POST_DATE_REQUEST:
            return {
                ...state,
            };
        case GET_ALL_POST_DATE_SUCCESS:
            return {
                ...state, allpostdate: payload?.data || null,
            };
        case GET_ALL_POST_DATE_FAILURE:
            return {
                ...state,
            };

        // GET_NOTIFICATION
        case GET_NOTIFICATION_REQUEST:
            return {
                ...state,
            };
        case GET_NOTIFICATION_SUCCESS:
            return {
                ...state, notificationlist: payload?.data || null,
            };
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
            };

        // GET_COOMEN_LISt
        case GET_BLOG_COMMENT_LIST_REQUEST:
            return {
                ...state,
            };
        case GET_BLOG_COMMENT_LIST_SUCCESS:
            return {
                ...state, commentlist: payload?.data || null,
            };
        case GET_BLOG_COMMENT_LIST_FAILURE:
            return {
                ...state,
            };

        // GET_COOMEN_REPLY_LISt
        case GET_BLOG_COMMENT_REPLY_LIST_REQUEST:
            return {
                ...state,
            };
        case GET_BLOG_COMMENT_REPLY_LIST_SUCCESS:
            return {
                ...state, commentreplylist: payload?.data || null,
            };
        case GET_BLOG_COMMENT_REPLY_LIST_FAILURE:
            return {
                ...state,
            };

        // GET_SEARCH BLOG
        case SEARCH_POSTS_REQUEST:
            return {
                ...state,
            };
        case SEARCH_POSTS_SUCCESS:
            return {
                ...state, searchResult: payload?.data || null,
            };
        case SEARCH_POSTS_FAILURE:
            return {
                ...state,
            };

        // GET_FOLLOWER_FOLLOWING_LIST
        case GET_FOLLOWER_FOLLOWING_LIST_REQUEST:
            return {
                ...state,
            };
        case GET_FOLLOWER_FOLLOWING_LIST_SUCCESS:
            return {
                ...state, followinglistdata: payload?.data || null,
            };
        case GET_FOLLOWER_FOLLOWING_LIST_FAILURE:
            return {
                ...state,
            };

        // GET_FOLLOWER_FOLLOWING_LIST
        case GET_USER_POST_BY_USE_ID_REQUEST:
            return {
                ...state,
            };
        case GET_USER_POST_BY_USE_ID_SUCCESS:
            return {
                ...state, userpostbydataList: payload?.data || null,
            };
        case GET_USER_POST_BY_USE_ID_FAILURE:
            return {
                ...state,
            };



        default:
            return state
    }
}