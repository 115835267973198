import axios from 'axios';
import { store } from '../redux/store';
import { v4 as uuidv4 } from 'uuid';


export const API_BASE_URL = 'https://neta.fluttertop.com/Incite/api/';

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

// Function to generate a unique device ID
const generateDeviceId = () => {
    // Implement your logic to generate the device ID
    // For example, you can use browser fingerprinting or generate a random UUID
    return uuidv4(); // Replace this with your actual device ID logic
};

const requestHandler = request => {

    // const player_id = generateDeviceId();
    // request.headers['player_id'] = player_id;

    const { loginToken = '' } = store?.getState()?.auth || '';
    request.headers['api-token'] = loginToken;
    request.headers['Cache-Control'] = 'no-cache';
    request.headers['Pragma'] = 'no-cache';
    request.headers['Expires'] = '0';
    return request;
};

const responseHandler = response => {
    if (response.status === 401 || response.status === 403) {
        console.log("responseHandler AXIOS ------- ");
        // Handle 401 or 403 responses as needed
    }
    return response;
};

const errorHandler = error => {
    console.log("errorHandler AXIOS ------- ", error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        responseHandler(error?.response);
    }
    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(
    request => requestHandler(request),
    error => errorHandler(error)
);

axiosInstance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
);

export default axiosInstance;
