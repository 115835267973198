import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInterest, interestadd } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Assets } from '../assets';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
let initialState = {
    category_id: Array(),
  };
const Preference = () => {

    const [onNews, setOnNews] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { intrest = [] } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    // const [formData, setFormState] = useState(initialState);
    const [formData, setFormData] = useState({
        category_id: [] // Initial state for selected category IDs
      });
    
      const handleCheckboxChangeCategory = (event, categoryId) => {
        const { checked } = event.target;
        setFormData(prevState => ({
          ...prevState,
          category_id: checked
            ? [...prevState.category_id, categoryId] // Add ID if checked
            : prevState.category_id.filter(id => id !== categoryId) // Remove ID if unchecked
        }));
      };

    const [checkedItems, setCheckedItems] = useState({});

    // const handleCheckboxChangeCategory = (event, subcategoryId) => {
    //     const isChecked = event.target.checked;
    //     setCheckedItems({
    //         ...checkedItems,
    //         [subcategoryId]: isChecked,
    //     });
    // };
    useEffect(() => {
        API_Function();
    }, []);

    const API_Function = () => {
        dispatch(getInterest())
            .then(response => {
                if (response?.success) {
                    console.log(response.data.category_ids);
                    setFormData({
                        ...formData, // Spread the existing formData
                        category_id: response.data.category_ids // Update category_id with the new value
                    });
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const handleCheckboxChange = (event, subcategoryId) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            setSelectedSubcategories([...selectedSubcategories, subcategoryId]);
        } else {
            setSelectedSubcategories(selectedSubcategories.filter(id => id !== subcategoryId));
        }
    };

    const SaveAddress_Function = () => {
        console.log(formData);
        if (formData.length === 0) {
            toast.error('Please select at least one subcategory');
            return;
        }
        // const formData = new FormData();
        
        // formData.append('category_id[]', selectedSubcategories || '');
        setIsLoading(true);
        dispatch(interestadd(formData))
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                    toast.success(response?.message || 'Success');
                    // navigate('/');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Retry again');
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Fail');
            });
    };

    // console.log('subcategory_id',)

    return (
        <div className='General_Settings_main_class'>
            <ToastContainer />
            <h2>Select Interest</h2>
            <div className="General_setting_form">
                <div className='Setting_Interest '>
                    <div className='News_blog'>
                        {intrest?.category_data?.length > 0?
                            ( <ul>
                                    {intrest?.category_data?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <h2>{item.category_name}</h2>
                                            {item.subcategories && item.subcategories.length === 0 ? (
                                                <p>Data not found</p>
                                            ) : (
                                                <ul>
                                                    {item.subcategories && item.subcategories.map((subcategory, subIndex) => (
                                                        <li key={subIndex}>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={formData.category_id.includes(subcategory.subcategory_id)}
                                                                    onChange={(event) => handleCheckboxChangeCategory(event, subcategory.subcategory_id)}
                                                                />
                                                                <span>{subcategory.subcategory_name}</span>
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </ul>                               
                            ) : (<p>data not found</p>)}
                    </div>
                    <button className='logi_in_button' onClick={SaveAddress_Function} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Save Changes'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Preference
