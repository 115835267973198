import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Assets } from '../assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUpdateProfile } from '../redux/actions/action-creator';

const SocialMedia = () => {
    const dispatch = useDispatch();
    const { profileData = null, } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);


    const [profileForm, setProfileForm] = useState({
        facebook: profileData?.facebook || '',
        instagram: profileData?.instagram || '',
        twitter: profileData?.twitter || '',
    });

    

    const Profile_Function = () => {
        const { facebook = '', instagram = '', twitter = ''} = profileForm;

        if (facebook!=='' || instagram!=='' || twitter!=='') {
            setIsLoading(true);
            let formData = new FormData();
            formData.append('facebook', facebook || "");
            formData.append('instagram', instagram || "");
            formData.append('twitter', twitter || "");
            dispatch(getUpdateProfile(formData)).then(response => {
                if (response?.success) {
                    toast.success(response?.message || 'success')
                    setIsLoading(false);
                    // navigate('/');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Retry again')
                }
            }).catch(error => {
                console.log("error profile  ===>", error);
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Profile failed')
            })
        }

    }

    return (
        <div className='General_Settings_main_class'>
            <ToastContainer />
            <h2>Social Media Settings</h2>
            <div className="General_setting_form">
                <div className="General_input">
                    <span>Facebook Url</span>
                    <input
                        type="text"
                        placeholder="Enter facebook url"
                        value={profileForm?.facebook}
                        onChange={({ target }) => setProfileForm({ ...profileForm, facebook: target.value })}
                    />
                </div>
                <div className="General_input">
                    <span>Instagram Url</span>
                    <input
                        type="text"
                        placeholder="Enter instagram url"
                        value={profileForm?.instagram}
                        onChange={({ target }) => setProfileForm({ ...profileForm, instagram: target.value })}
                    />
                </div>
                <div className="General_input">
                    <span>Twitter Url</span>
                    <input
                        type="text"
                        placeholder='Enter twitter url'
                        value={profileForm?.twitter}
                        onChange={({ target }) => setProfileForm({ ...profileForm, twitter: target.value })}
                    />
                </div>
            </div>

            <button className='logi_in_button' onClick={() => Profile_Function()} disabled={isLoading}>
                {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Save Changes'}
            </button>
        </div>
    )
}

export default SocialMedia
