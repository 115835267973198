import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Assets } from '../assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeUserPassword } from '../redux/actions/action-creator';

const Security = () => {
    const dispatch = useDispatch();
    const { GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const [changePasswordForm, setChangePasswordForm] = useState({
        old_password: '',
        password: '',
        cpassword: '',
    });

    const changePassword = () => {
        const { old_password = '', password = '', cpassword = '' } = changePasswordForm;
        // console.log(name);
        if (old_password=='') {
            toast.error("Enter current password");
        }else if(password == ''){
            toast.error("Enter new password");
        }else if(cpassword == ''){
            toast.error("Enter confirm password");
        }else if(password != cpassword){
            toast.error("New password and confirm password should be same.");
        }else{
            setIsLoading(true);
            let formData = new FormData();
            formData.append('old_password', old_password || "");
            formData.append('password', password || "");
            formData.append('cpassword', cpassword || "");
            dispatch(changeUserPassword(formData)).then(response => {
                if (response?.success) {
                    response.data.password = password;
                    setChangePasswordForm({
                        old_password: '', password: '', cpassword: '',
                    });
                    toast.success(response?.message || 'success')
                    setIsLoading(false);
                    // navigate('/');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Retry again')
                }
            }).catch(error => {
                console.log("error profile  ===>", error);
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Profile failed')
            })
        }

    }

    return (
        <div className='General_Settings_main_class'>
            <ToastContainer />
            <h2>Change Password</h2>
            <div className="General_setting_form">
                <div className="General_input">
                    <span>Current password</span>
                    <input
                        type="text"
                        placeholder="Enter current password"
                        value={changePasswordForm?.old_password}
                        onChange={({ target }) => setChangePasswordForm({ ...changePasswordForm, old_password: target.value })}
                    />
                </div>
                <div className="General_input">
                    <span>New password</span>
                    <input
                        type="text"
                        placeholder="Enter new password"
                        value={changePasswordForm?.password}
                        onChange={({ target }) => setChangePasswordForm({ ...changePasswordForm, password: target.value })}
                    />
                </div>
                <div className="General_input">
                    <span>Confirm password</span>
                    <input
                        type="text"
                        placeholder="Enter confirm password"
                        value={changePasswordForm?.cpassword}
                        onChange={({ target }) => setChangePasswordForm({ ...changePasswordForm, cpassword: target.value })}
                    />
                </div>
            </div>
            <button className="logi_in_button" onClick={() => changePassword()} disabled={isLoading}>
                {isLoading ? <img src={Assets.BUTTON_LOAD} alt="" /> : 'Save Changes'}
            </button>
        </div>
    )
}

export default Security


