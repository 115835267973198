export const EMAIL_CHECK = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MOBILE_CHECK = /^\(?([1-9]{1})\)?[-. ]?([0-9]{6,9})$/;

export const formatedDate = (date) => {
    if (date !== '0000-00-00') {
        let newD = new Date(date)?.toISOString() || null;
        let newDate = newD ? newD?.split('T')[0] : '';
        let formatedDate = newDate?.split('-')?.reverse()?.join('-') || '00-00-0000';
        return formatedDate;
    }
    return '';
};

export const formatedDateInSrting = (date) => {
    if (date !== '0000-00-00') {
        let newD = new Date(date)?.toDateString() || null;
        return newD || '';
    }
    return '';
};

export const dateWithTime = (date) => {
    if (date !== '0000-00-00') {
        date = date?.replace(' ', 'T') || date;
        let newD = new Date(date);
        let hr = newD?.getHours() || '00';
        let min = newD?.getMinutes() || '00';
        let sec = newD?.getSeconds() || '00';
        let hour = hr > 12 ? hr - 12 : hr === 0 ? 12 : hr;
        let AP_PM = hr >= 12 ? 'PM' : hr === 0 ? 'AM' : 'AM';
        let time = String(hour).padStart(2, '0') + ":" + String(min).padStart(2, '0') + " " + AP_PM;
        let fullDateStrings = newD?.toDateString() || null;
        let datetime = fullDateStrings + " " + time;
        return datetime || '';
    }
    return '';
};

export const futureDate = (date, difference) => {
    var d = date ? new Date(date) : new Date();
    var ts = d.getTime();
    let differenceDate = ts + (difference ? difference * 24 * 60 * 60 * 1000 : 1 * 24 * 60 * 60 * 1000)
    let newD = new Date(differenceDate)?.toISOString();
    let newDate = newD ? newD?.split('T')[0] : '';
    let formatedDate = newDate?.split('-')?.reverse()?.join('-') || '00-00-0000';
    return newDate || '';
};

// Constants related to date formatting
export const NORMAL_ICONSIZE = 20;
export const HEADING_ICONSIZE = 30;
