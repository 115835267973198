import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import { thunk } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



const persistConfig = {
    key: 'netaverse',
    storage: storage

}

const persistedReducer = persistReducer(persistConfig, rootReducer)
// const store = legacy_createStore(rootReducer, applyMiddleware(thunk))
export const store = createStore(persistedReducer, compose(
    applyMiddleware(thunk),
    process.env.NODE_ENV === 'development' && window.devToolsExtension ? window.devToolsExtension() : f => f
))
export const persistor = persistStore(store);


export default store;
