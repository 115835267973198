
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Spinner4 = () => {
    const homeSlider2 = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2.5,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    speed: 300,
                    slidesToShow: 2.5,
                    variableWidth: true
                }
            }
        ]
    };

    return (
        <div className='Featured_Category'>
            <div className='Featured_Category_grid '>
                <Slider {...homeSlider2} className='homeslider  second_home_sleder responsive_home_slider_2'>
                    {
                        Array.from({ length: 10 }).map((el, i) => {
                            return (
                                <div key={i} to='/Comment' className='Category_card'>
                                    <Skeleton baseColor="#202020" highlightColor="#444" height={200} width={'100%'} /> {/* Skeleton for image */}
                                    <div className='Category_card_post_flex'>
                                        <div className='Category_img_grid'>
                                            <Skeleton baseColor="#202020" highlightColor="#444" circle height={50} width={50} /> {/* Skeleton for user profile image */}
                                        </div>
                                        <div>
                                            <h2><Skeleton width={200} baseColor="#202020" highlightColor="#444" /> </h2> {/* Skeleton for title */}

                                            <span><Skeleton width={200} baseColor="#202020" highlightColor="#444" /> </span> {/* Skeleton for username */}
                                            {/* <span><Skeleton width={100} baseColor="#202020" highlightColor="#444" /></span> Skeleton for view count */}

                                        </div>
                                        <ul>
                                            {/* Skeleton for tags */}
                                            <li><Skeleton width={100} baseColor="#202020" highlightColor="#444" /></li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    );
}

export default Spinner4;
