

import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { googleLoginSuccess, signIn, googleLogin } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { EMAIL_CHECK } from '../constants';
import { Assets } from '../assets';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";


const Login = ({ setGoogleData, notificationToken }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { GuestId = null, deviceToken = '', deviceType = '' } = useSelector(state => state.auth);
    const [loginForm, setLoginForm] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const Login_Function = () => {
        const { email = '', password = '' } = loginForm;
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('device_token', deviceToken || '');
        formData.append('deviceType', deviceType || '');
        formData.append('guest_id', GuestId || '');
        formData.append('device_key ', notificationToken || '');

        if (EMAIL_CHECK.test(email)) {
            if (password) {
                setIsLoading(true);
                dispatch(signIn(formData))
                    .then(response => {
                        if (response?.success) {
                            setIsLoading(false);
                            toast.success(response.message || 'Login success!');
                            navigate('/');
                        } else {
                            setIsLoading(false);
                            toast.error(response?.response?.data?.message || 'Login fail!');
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                        toast.error(error?.response?.data?.message || 'Login fail!');
                    });
            } else {
                toast.error('Enter password');
            }
        } else {
            toast.error('Enter email');
        }
    };


    const [googlelogin, setGooglelogin] = useState({ name: '', email: '' });
    // const [googleData, setGoogleData] = useState(null);
    const Google_Function = (data) => {
        const { name = '', email = '' } = data;
        let formData = new FormData();
        formData.append('name', name || "");
        formData.append('email', email);
        formData.append('login_from', 'Google Login');
        formData.append('device_token', deviceToken || '');
        formData.append('deviceType', deviceType || '');
        formData.append('guest_id', GuestId || '');
        formData.append('device_key ', notificationToken || '');

        dispatch(googleLogin(formData))
            .then(response => {
                if (response?.success) {
                    setIsLoading(false);
                    toast.success(response.message || 'Google success!');
                    navigate('/');
                } else {
                    setIsLoading(false);
                    toast.error(response?.response?.data?.message || 'Google fail!');
                }
            })
            .catch(error => {
                setIsLoading(false);
                toast.error(error?.response?.data?.message || 'Google fail!');
            });
    };

    const handleGoogleLoginSuccess = (credentialResponse) => {
        const decoded = jwtDecode(credentialResponse.credential);
        setGoogleData(decoded);
        // Call Google_Function with the received data
        Google_Function(decoded);
        console.log(decoded)
    };

    const handleGoogleLoginError = () => {
        console.log('Login Failed');
    };


    // console.log('Notification Token============:', notificationToken);


    return (
        <div className="Login_screen">
            <div className="Login_screen_con">
                <div className="login_fron_comaq">
                    <ToastContainer />
                    <h1>Log in</h1>
                    <div className="login_fron">
                        <div className="logn_input">
                            <span>Email</span>
                            <input
                                type="Email"
                                placeholder="Enter email"
                                value={loginForm?.email}
                                onChange={({ target }) => setLoginForm({ ...loginForm, email: target.value })}
                            />
                        </div>
                        <div className="logn_input">
                            <span>Password</span>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter password"
                                    value={loginForm.password}
                                    onChange={({ target }) => setLoginForm({ ...loginForm, password: target.value })}
                                />
                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                    {loginForm.password ? (
                                        showPassword ? <FaEye /> : <FaEyeSlash />
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className="login_Forgot">
                            <Link to="/Forgotpassword">Forgot Password?</Link>
                        </div>
                    </div>


                    {/* {googleData && (
                        <div>
                            <h2>Welcome, {googleData.name}</h2>
                            <h2>Welcome, {googleData.email}</h2>
                            <img src={googleData.picture} alt="Profile Picture" />
                        </div>
                    )} */}
                    {/* Your existing login button */}
                    <button className="logi_in_button" onClick={() => Login_Function()} disabled={isLoading}>
                        {isLoading ? <img src={Assets.BUTTON_LOAD} alt="" /> : 'Log in'}
                    </button>
                    <p className="Login_to_singUp_button">
                        Not a Member? <NavLink to="/Signup"> SignUp</NavLink>
                    </p>
                    <GoogleLogin
                        clientId="167208473885-7qs4nkvjogtvjaipe9j1nnpa9k7c20ge.apps.googleusercontent.com"
                        onSuccess={handleGoogleLoginSuccess}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                        )}
                        onFailure={handleGoogleLoginError}
                        cookiePolicy={'single_host_origin'}
                    // buttonText="Login with Google"
                    />
                </div>
            </div>
        </div>
    );
};

export default Login;
