import React, { useEffect, useState } from 'react'
import 'react-h5-audio-player/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSubCategeory, updatepost } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Assets } from '../assets';
import Select from 'react-select';
import { IoMdLink } from "react-icons/io";
import { formatedDate } from '../constants';

const Repost = ({ Respost_closePopup, getBlogpost }) => {
    const dispatch = useDispatch();
    const { userData = null, } = useSelector(state => state.auth);
    const { tegListdata = [], subcategory = [], blogposDetails = [] } = useSelector(state => state.common);
    const [isLoading, setIsLoading] = useState(false);

    const [selectOpne, setSelectOpne] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const toggleDropdown = () => {
        setSelectOpne(!selectOpne);
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectOpne(false);
        console.log('category_id Name:', option.category_id);
    };

    // === sub_category ===
    const [onSubcategory, setOnSubcategory] = useState(false);
    const [selectedSubOption, setSelectedSubOption] = useState(null);
    const toggleSubcategory = () => {
        setOnSubcategory(!onSubcategory);
    };
    const handleSubOptionClick = (option) => {
        setSelectedSubOption(option);
        setOnSubcategory(false);
        console.log('Subcategory Name:', option.subcategory_id);
    };

    useEffect(() => {
        SubCategory_Function();
    }, []);


    const SubCategory_Function = () => {
        dispatch(getSubCategeory())
            .then(response => {
                if (response?.success) {
                } else {
                }
            })
            .catch(error => {
            });
    };


    // === Select Tag option ID ===>
    const [selectedTagOptions, setSelectedTagOptions] = useState([]);
    const handleSelectTag = (selectedTagOptions) => {
        setSelectedTagOptions(selectedTagOptions);
    };
    const options = tegListdata.map(user => ({
        value: user.follow_id,
        label: user.name
    }));

    const customStyles = {
        option: (provided) => ({
            ...provided,
            backgroundColor: '#000',
            color: '#fff',
            cursor: 'pointer',
            // '&:hover': {
            //     backgroundColor: '#e0e0e0' 
            // }
        }),
        menu: (provided) => ({
            ...provided,
            border: 'none',
            boxShadow: 'none',
            cursor: 'pointer'
        })
    };

    const [postupdate, setPostUpadate] = useState({
        id: '',
        category_id: '',
        sub_category_id: '',
        title: '',
        description: '',
        tag_id: '',
        created_by: '',
    });
    const onValidate_Function = () => {
        const { title = '', description = '', } = postupdate

        if (title) {
            if (description) {
                SaveAddress_Function();
            } else {
                toast.error('Enter post description')
            }
        } else {
            toast.error('Enter post title')
        }
    }

    const SaveAddress_Function = () => {
        const { id = '', title = '', description = '', } = postupdate

        let formData = new FormData();
        formData.append('id', id || 0);
        formData.append('title', title || "");
        formData.append('description', description || "");
        formData.append('post_type', 'repost' || '');
        formData.append('media_type', '' || '');
        formData.append('url_type', '' || "");
        formData.append('video_url', '' || "");
        formData.append('thumbnail_image', '' || "");
        formData.append('post_id', blogposDetails?.id || "");
        formData.append('created_by', userData?.id || "");
        formData.append('tag_id[]', tegListdata?.user?.follow_id || "");

        // Append selected tags
        selectedTagOptions.forEach(tag => {
            formData.append('tag_id[]', tag.value);
        });

        if (selectedSubOption && selectedSubOption.subcategory_id) {
            formData.append('sub_category_id[]', selectedSubOption.subcategory_id);
        } else {
            console.error('Selected subcategory option is not valid');
        }

        // Append category_id if selected
        if (selectedOption && selectedOption.category_id) {
            formData.append('category_id[]', selectedOption.category_id);
        } else {
            console.error('Selected category option is not valid');
        }
        setIsLoading(true);
        dispatch(updatepost(formData)).then(response => {
            if (response?.success) {
                setPostUpadate({
                    category_id: '', sub_category_id: '', title: '', description: '', tag_id: '',
                    created_by: '',
                });
                setIsLoading(false);
                toast.success(response?.message || 'success')
                getBlogpost()
                Respost_closePopup();
            } else {
                setIsLoading(false);
                toast.error(response?.message || 'Retry_again')
            }
        }).catch(error => {
            setIsLoading(false);
            toast.error(error?.message || 'Fail')
        })
    }

    // console.log('check', blogposDetails)
    return (
        <>
            <div className='social_details_tabs search_hidden'>
                <div className='Re_post_main'>
                    <div className='fild_input_upload'>
                        <ToastContainer />
                        <h1 className='re_psot_heding'>Repost </h1>

                        <div className='repost_blog_data'>
                            <div className='respot_blog_data_show'>
                                <div className='detais_blog_link_name_img'>
                                    <img src={blogposDetails?.userprofile} alt="" />
                                    <div className=''>
                                        <h4>{blogposDetails.username}</h4>
                                        <span className='detais_blog_link_date'>{formatedDate(blogposDetails.date)}</span>
                                        <span className='detais_blog_link_time'>{blogposDetails.time}</span>
                                    </div>
                                </div>
                                <h2>{blogposDetails?.title}</h2>
                                <p>{blogposDetails?.description}</p>
                            </div>
                            <img className='repost_img_show' src={blogposDetails?.thumbnail_image || Assets.FEEDODEMO} alt="" />
                        </div>

                        <div className="custom-dropdown">
                            <div className="selected-option" onClick={toggleDropdown}>
                                <span>{selectedOption ? selectedOption.category_name : 'Select Category'}</span>
                                <span>{selectOpne ? <FaAngleUp /> : <FaAngleDown />}</span>
                            </div>
                            {selectOpne && (
                                <ul className="options-list">
                                    {subcategory?.category_data?.map((option, categoryIndex) => (
                                        // <li key={option.id} onClick={() => handleOptionClick(option)}>
                                        //     {option.category_name}
                                        // </li>
                                        <li key={categoryIndex} onClick={() => handleOptionClick(option)}>{option.category_name}</li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div className="custom-dropdown">
                            <div className="selected-option" onClick={toggleSubcategory}>
                                <span>{selectedSubOption ? selectedSubOption.subcategory_name : 'Select Subcategory'}</span>
                                <span>{onSubcategory ? <FaAngleUp /> : <FaAngleDown />}</span>
                            </div>
                            {onSubcategory && (
                                <ul className="options-list">
                                    {subcategory?.category_data.map((category, index) => (
                                        <React.Fragment key={index}>
                                            {category.subcategories.map((subcategory, subIndex) => (
                                                <li key={subIndex} onClick={() => handleSubOptionClick(subcategory)}>
                                                    {subcategory.subcategory_name}
                                                </li>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className='Add_Title_details'>
                            <input
                                placeholder='Add Title'
                                value={postupdate?.title}
                                onChange={({ target }) => setPostUpadate({ ...postupdate, title: target.value })}
                            />
                            <textarea
                                placeholder='Add Description'
                                value={postupdate?.description}
                                onChange={({ target }) => setPostUpadate({ ...postupdate, description: target.value })}></textarea>
                        </div>

                        <div className="custom-dropdown">
                            <Select
                                isMulti
                                options={options}
                                value={selectedTagOptions}
                                onChange={handleSelectTag}
                                styles={customStyles}
                                classNamePrefix="custom-select-upload-tag"
                            />
                        </div>

                        <div className='link_img_upload'>
                            <div className='button_url_imput'>
                                <button onClick={Respost_closePopup} className='logi_in_button cancel_popup_upolod'> Cancel</button>
                                <button className='logi_in_button' onClick={() => onValidate_Function()} disabled={isLoading}>
                                    {isLoading ? <img src={Assets.BUTTON_LOAD} alt='' /> : 'Publish'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Repost

