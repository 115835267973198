import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBOh3cy-P6yclSEzdigSawSX8oDRRiXSeU",
    authDomain: "netaverse-8ba61.firebaseapp.com",
    projectId: "netaverse-8ba61",
    storageBucket: "netaverse-8ba61.appspot.com",
    messagingSenderId: "1064565205256",
    appId: "1:1064565205256:web:50f93b8cb58524515d24bd"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);