// Libraryspinner.js
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Libraryspinner = ({ librarydata }) => {
    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            {Array.isArray(librarydata) && librarydata.map((item, index) => (
                <div className='librery_cart' key={index}>
                    <Skeleton width='100%' height={160} />
                    <div className='Category_card_post_flex'>
                        <div className='Category_img_grid'>
                            <Skeleton circle={true} width={40} height={40} />
                        </div>
                        <div>
                            <Skeleton width={200} />
                            <Skeleton width={100} />
                        </div>
                    </div>
                    <ul className='cart_blog'>
                        <Skeleton width={80} />
                    </ul>
                </div>
            ))}
        </SkeletonTheme>
    );
}

export default Libraryspinner;
