import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUpdateProfile } from '../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';

const NotificationsSettimg = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userData = null, profileData = null, loginToken = '' } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        link_unlink_notification: 0,
        comment_notification: 0,
        repost_notification: 0,
        like_notification: 0,
        desktop_push_notification: 0,
      });

      useEffect(() => {    
        console.log(profileData);   
        setFormData(prevFormData => ({
            ...prevFormData,
            link_unlink_notification: profileData.link_unlink_notification,
            comment_notification: profileData.comment_notification,
            repost_notification: profileData.repost_notification,
            like_notification: profileData.like_notification,
            desktop_push_notification: profileData.desktop_push_notification,
        }));       
    }, [profileData]);

    const handleLanguageChange = (event) => {
        const { value } = event.target;
        console.log(value);
    
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, language_id: value };
            console.log(updatedFormData.language_id);    
            setIsLoading(true);
            dispatch(getUpdateProfile(updatedFormData))
                .then(response => {
                    setIsLoading(false);
                    if (response?.success) {
                        toast.success(response?.message || 'Success');
                        // navigate('/');
                    } else {
                        toast.error(response?.response?.data?.message || 'Retry again');
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    toast.error(error?.response?.data?.message || 'Fail');
                });
    
            return updatedFormData; // Return updated state
        });
    };  

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: checked ? 1 : 0,
        }));
        setIsLoading(true); // Show loading spinner or indicator
    
        // Dispatch the getUpdateProfile action after updating formData
        dispatch(getUpdateProfile({
            ...formData, // Use the updated formData state
            [name]: checked ? 1 : 0, // Update the specific checkbox value in formData
        }))
        .then(response => {
            setIsLoading(false); // Hide loading spinner or indicator
            if (response?.success) {
                toast.success(response?.message || 'Success');
                // navigate('/');
            } else {
                toast.error(response?.response?.data?.message || 'Retry again');
            }
        })
        .catch(error => {
            setIsLoading(false); // Hide loading spinner or indicator
            toast.error(error?.response?.data?.message || 'Fail');
        });
    };
    return (
        <div className='General_Settings_main_class'>
            <ToastContainer />
            <h2>Notifications Settings</h2>
            <div className="General_setting_form">
                <div className='notification_box'>
                    <div className='Notifications_setting_row'>
                        <div className='Notifications_setting_option'>
                            <span>Link</span>
                            <label class="switch">
                                <input type="checkbox" name="link_unlink_notification" checked={formData.link_unlink_notification === 1} onChange={handleCheckboxChange}/>
                                <span class="Noti_Toggle"></span>
                            </label>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div className='Notifications_setting_row'>
                        <div className='Notifications_setting_option'>
                            <span>Comment</span>
                            <label class="switch">
                                <input type="checkbox" name="comment_notification" checked={formData.comment_notification === 1} onChange={handleCheckboxChange}/>
                                <span class="Noti_Toggle"></span>
                            </label>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div className='Notifications_setting_row'>
                        <div className='Notifications_setting_option'>
                            <span>Repost</span>
                            <label class="switch">
                                <input type="checkbox" name="repost_notification" checked={formData.repost_notification === 1} onChange={handleCheckboxChange}/>
                                <span class="Noti_Toggle "></span>
                            </label>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>

                    <div className='Notifications_setting_row'>
                        <div className='Notifications_setting_option'>
                            <span>Like</span>
                            <label class="switch">
                                <input type="checkbox" name="like_notification" checked={formData.like_notification === 1} onChange={handleCheckboxChange}/>
                                <span class="Noti_Toggle "></span>
                            </label>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                    <div className='Notifications_setting_row'>
                        <div className='Notifications_setting_option'>
                            <span>Push notification</span>
                            <label class="switch">
                                <input type="checkbox" name="desktop_push_notification" checked={formData.desktop_push_notification === 1} onChange={handleCheckboxChange}/>
                                <span class="Noti_Toggle "></span>
                            </label>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsSettimg



