import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Help = () => {


    return (
        <div className='General_Settings_main_class'>
            <h2>Help</h2>
            <div className="General_setting_form">
                <div className='Contact_us'>
                    <h3>Contact us</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <Link>support@gmail.com</Link>
                </div>
            </div>
        </div>
    )
}

export default Help


